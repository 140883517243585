import React, { createContext, useEffect, useState } from 'react';

import server, {endpoints} from '../utils/server';

//* StyleSheet
import './styles/dashboardP.css';

//* Components
import LinkToFileList from '../components/Dashboard/LinkToFileList/LinkToFileList';
import EditModal from '../components/Dashboard/EditModal/EditModal';
import MultipleSelect from '../components/Dashboard/MultipleSelect/MultipleSelect';
import DeleteModal from '../components/Dashboard/DeleteModal/DeleteModal';
import EditCategoriesModal from '../components/Dashboard/EditCategories/EditCategoriesModal';

/**
 * Request the dashboard information from the API and if there is an error
 * it will return empty strings. The return form is { teams: [], categories: [], files: []}
 * @param {string} id_cycle id of the cycle
 * @param {string} idUR id of the user
 * @param {Array<number>} team_filter teams to filter
 * @param {Array<number>} category_filter categories to filter
 * @returns an object with a list of teams, categoriess and files
 */
const getDashboardInfo = async (
    id_cycle,
    idUR,
    team_filter,
    category_filter
) => {
    const params = { idUR, id_cycle };
    const body = { team_filter, category_filter };
    const response = await server(endpoints.getDasboardInfo.route, endpoints.getDasboardInfo.method, params, body);

    const { error_message, data } = response;

    if (error_message !== '') {
        return { teams: [], categories: [], files: [] };
    }

    const categories = data.general_info.categories;
    const teams = data.teams;

    let files = [];

    teams.forEach(team => {
        const teamItems = team.items.map(item => {
            const user_can_edit = team.user_can_edit || data.general_info.user_can_edit
            return { ...item, team: team.id , user_can_edit};
        });
        files = [...files, ...teamItems];
    });
    return { teams, files, categories, user_can_edit: data.general_info.user_can_edit};
};

/**
 * Erase a dashboard file
 * @param {string} idUR id of the user
 * @param {string} id_dashboard_item id of the file to delete
 */
const deleteItem = async (idUR, id_dashboard_item) => {
    const params = { idUR, id_dashboard_item };
    const response = await server(endpoints.deleteItemFromCategory.route, endpoints.deleteItemFromCategory.method, params, {});

    const { error_message } = response;
    if (error_message !== '') {
        return error_message;
    }
    return null;
};

/**
 *
 * @param {string} idUR id of the user
 * @param {string} id_cycle id of the cycle
 * @param {Object} item id, id_team, id_dashboard_category, value, label
 */
const editItem = async (idUR, id_cycle, item) => {
    const response = await server(
        endpoints.editDashboardItem.route,
        endpoints.editDashboardItem.method,
        { idUR, id_cycle },
        { ...item }
    );
    const { error_message } = response;
    if (error_message !== '') {
        return error_message;
    }
    return null;
};
/**
 *
 * @param {string} id_cycle id of the cycle
 */

const getTeamsInCycle = async (id_cycle) => {
    const params = {id_cycle}
    const res = await server(endpoints.getTeamsInCycle.route, endpoints.getTeamsInCycle.method,params)
    if ( res.error_message){
        // message.error(res.error_message); 
    }else{
        return(res.data)

      }
}

export const DashboardContext = createContext(null);

//const DashboardInfoContext = createContext(null);
const Dashboard = ({otherTeams, idCycle, idUR }) => {
    const [teamsFilter, setTeamsFilter] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState([]);
    const [deleteData, setDeleteData] = useState({ show: false, id: 0 });
    const [editData, setEditData] = useState({ show: false, id: 0 });
    const [editCategories, setEditCategories] = useState({ show: false, idUR, idCycle});


    const [teams, setTeams] = useState([])

    const [dashboardInfo, setDashboardInfo] = useState({
        teams: [],
        categories: [],
        files: [],
        user_can_edit: false
    });

    const updateDashboarInfo = async () => {
        setDashboardInfo(
            await getDashboardInfo(idCycle, idUR, teamsFilter, categoryFilter)
        );
    };
    const getTeams = async () =>{
        setTeams(
            await getTeamsInCycle(idCycle)
        )

    }
    useEffect(()=>{
        (async () =>{
            await getTeams();
        })();
    },[])
    useEffect(() => {
        (async () => {
            await updateDashboarInfo();
        })();

        return () => {};
    }, [teamsFilter, categoryFilter]);

    const methods = {
        toggleDelete: (deleteID = 0) => {
            setDeleteData({ show: !deleteData.show, id: deleteID });
        },

        toggleEdit: (editID = 0) => {
            setEditData({ show: !editData.show, id: editID, id_dashboard_category: dashboardInfo.categories[0].id});
        },
        toggleEditCategorie: () => {
            setEditCategories({show: !editCategories.show, idUR, idCycle})
        } ,

        deleteFile: async fileID => {
            const res = await deleteItem(idUR, fileID);
            if (res === null) updateDashboarInfo();
            setDeleteData({ show: false, id: 0 });
        },

        editFile: async fileBody => {
            const { label, value, id_dashboard_category, team, id } = fileBody;
            const res = await editItem(idUR, idCycle, {
                label,
                value,
                id_dashboard_category,
                id_team: team,
                id,
            });
            if (res === null) updateDashboarInfo();
            setEditData({ show: false, id: 0, id_dashboard_category: dashboardInfo.categories[0].id});
        },
        updateDasboardCategories: async () => {
            updateDashboarInfo(); 
        }
    };

    const context = { data: dashboardInfo, methods };
    return (
        <>
            <DashboardContext.Provider value={context}>
                <div className="DashboardP">
                    <div className="DashboardP-topBar">
                        <div className="DashboardP-topBar-column"></div>
                        <h1 className="DashboardP-topBar-title">
                            Lista de Archivos
                        </h1>
                        <div className="DashboardP-topBar-column"></div>
                    </div>
                    <div className="DashboardP-content">
                        <div className="DashboardP-left-column">
                            <div className="DashboardP-filterCard">
                                <h3 className="DashboardP-filterCard-title">
                                    Filtros
                                </h3>
                                <MultipleSelect
                                    label="Equipo: "
                                    options={teams}
                                    stateChanger={setTeamsFilter}
                                />
                                <MultipleSelect
                                    label="Categoria: "
                                    options={dashboardInfo.categories}
                                    stateChanger={setCategoryFilter}
                                />
                            </div>
                            {dashboardInfo.user_can_edit && (
                                <button
                                    className="DashboardP-uploadCard"
                                    onClick={() => methods.toggleEdit(0)}
                                >
                            
                                    <h3 className="DashboardP-uploadCard-title">
                                        Agregar Archivo
                                    </h3>
                                
                                </button>
                                
                            )}
                            {dashboardInfo.user_can_edit && (
                                <button
                                    className="DashboardP-uploadCard"
                                    onClick={() => methods.toggleEditCategorie()}
                                >
                            
                                    <h3 className="DashboardP-uploadCard-title">
                                        Editar Categorias
                                    </h3>
                                
                                </button>
                            )}
                        </div>
                        <LinkToFileList />
                    </div>
                </div>
                <EditCategoriesModal props={editCategories}/>
                <EditModal props={editData} />
                <DeleteModal props={deleteData} />
            </DashboardContext.Provider>
        </>
    );
};

export default Dashboard;
