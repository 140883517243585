import React, { useState, Fragment, useCallback } from 'react';
import { Form, Col, Row, Button, Popconfirm, message } from "antd";
import SelectWritable from './selectWritable';
import axios from "axios";
import { rootEndpoint } from "./../utils/constants";
import { useEffect } from 'react';
import server, { endpoints } from '../utils/server';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const AddTeam = ({ onTeamInsert, idSelectedCycle }) => {

  const [otherCycleTeams, setOtherCycleTeams] = useState([]);
  const [possibleParentTeams, setPossibleParentTeams] = useState([]);
  const [otherCycleTeamsReceived, setOtherCycleTeamsReceived] = useState(false);
  const [possibleParentTeamsReceived, setPossibleParentTeamsReceived] = useState(false);

  const getOtherCycleTeams = useCallback(async () => {
    const res = await server(endpoints.getOtherCyclesTeams.route, endpoints.getOtherCyclesTeams.method, { id_cycle: idSelectedCycle });
    const { error_message, data: teamsNotInCycle } = res;
    if (error_message) {
      message.error(error_message);
      return;
    }
    setOtherCycleTeams(teamsNotInCycle);
    setOtherCycleTeamsReceived(true);
  }, [idSelectedCycle]);

  const getThisCycleTeams = useCallback(async () => {
    const res = await server(endpoints.getTeamsInCyle.route, endpoints.getTeamsInCyle.method, { id_cycle: idSelectedCycle });
    const { error_message, data: teamsInThisCycle } = res;
    if (error_message) {
      message.error(error_message);
      return;
    }
    setPossibleParentTeams(teamsInThisCycle);
    setPossibleParentTeamsReceived(true);
  }, [idSelectedCycle]);

  useEffect(() => {
    getOtherCycleTeams();
    getThisCycleTeams();
  }, [getOtherCycleTeams, getThisCycleTeams]);

  const [newTeam, setNewTeam] = useState(
    {
      id: 0,
      name: ""
    }
  );
  const [parentTeam, setParentTeam] = useState(
    {
      id: 0,
      name: ""
    }
  );
  const [addButtonDisabled, setAddButtonDisabled] = useState(true);

  const onTeamSelect = (team) => {
    setNewTeam(team);
    handleButton(team);
  }

  const onParentTeamSelect = (team) => {
    setParentTeam(team);
  }

  const handleButton = (e) => {
    if (e) {
      setAddButtonDisabled(false)
      return
    }
    else {
      setAddButtonDisabled(true);
    }
  };

  const onTeamAdd = () => {
    setAddButtonDisabled(false);
    const newTeamName = newTeam.team, idNewTeam = newTeam.id, idParentTeam = parentTeam.id;
    //selectedTeam, teamPapa
    onTeamInsert(newTeamName, idNewTeam, idParentTeam);
  }

  //Set the question in the popconfirm to add the team 
  let parentTitle = "";
  if (parentTeam) {
    if (parentTeam.name) {
      parentTitle = ` dentro de ${parentTeam.name}`;
    }
  }
  let newTeamTitle = "";
  if(newTeam){
    if(newTeam.team){
      newTeamTitle = newTeam.team;
    }
  }
  const popConfirmTitle = `Estás seguro de agregar a ${newTeamTitle}${parentTitle}?`;

  return (
    <Fragment>
      {
        otherCycleTeamsReceived && possibleParentTeamsReceived &&
        <Col
          span={22}
          style={{
            borderStyle: "solid",
            borderWidth: "0.5px",
            borderRadius: "10px",
            borderColor: "#e6e6e6",
          }}
        >
          <Form {...layout} preserve={false} layout={"vertical"}>
            <Row justify="center">
              <h2>Añadir un nuevo equipo:</h2>
            </Row>
            <Row justify="center">
              <Form.Item
                name="teamName"
                label="Equipo a Agregar"
                rules={[
                  {
                    required: true,
                    message: 'Porfavor Introduce un nombre de equipo!',
                  },
                ]}
              >
                <SelectWritable
                  items={otherCycleTeams}
                  onItemSelect={onTeamSelect}
                  label={"Equipo a agregar:"}
                  placeholder={"Escribe el nombre del nuevo equipo"}
                  canWriteNewItem={true}
                  valueProp={"id"}
                  labelProp={"team"}
                />
              </Form.Item>
            </Row>
            <Row justify="center">
              <Form.Item
                name="teamName"
                label="Equipo Principal:"
              >
                <SelectWritable
                  items={possibleParentTeams}
                  onItemSelect={onParentTeamSelect}
                  label={"Si no tiene equipo principal, dejarlo vacío"}
                  //placeholder={"Escribe el nombre del nuevo equipo"}
                  canWriteNewItem={false}
                  valueProp={"id"}
                  labelProp={"name"}
                />
              </Form.Item>
            </Row>
            <Row justify="center">
              <Popconfirm
                title={popConfirmTitle}
                onConfirm={onTeamAdd}
                okText={"Agregar equipo"}
                cancelText={"Cancelar"}
                disabled={addButtonDisabled}
              >
                <Button
                  type="primary"
                  disabled={addButtonDisabled}
                >
                  Añadir Equipo
                  </Button>
              </Popconfirm>
            </Row>

          </Form>
        </Col>
      }
    </Fragment>
  );
}

export default AddTeam;