import React from "react";
import { Row, Progress } from "antd";

const WorkViewMobile = ({ teams = [] }) => teams.map((team) => (
    <div style={{ padding: "1.5rem" }}>
        <h4 style={{ marginBottom: "2rem" }}>{`${team.role}: ${team.team}`}</h4>
        <Row align="top" justify="space-between">
            <div style={{ width: "50%" }}>
                <div>Cumplimiento de horas:</div>
                <Row justify="center">
                    <Progress width={50} type="circle" percent={team.percentage}/>
                </Row>
            </div>
            <div>
                <div>Tareas pendientes:</div>
                <Row justify="center" align="middle" style={{ height: "50px" }}>
                    <h2>{team.pendingTasks}</h2>
                </Row>

            </div>
        </Row>
    </div>
));

export default WorkViewMobile;
