import React, { Component } from "react";
import axios from "axios";
import { rootEndpoint } from "../utils/constants";
import { Table, Checkbox, Row, PageHeader, Input, Button, Space, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import server, { endpoints } from "../utils/server";


const defaultColumns = [];
const admin = "ADMIN";
let flag = false;

class AdminUsers extends Component {
  state = {
    users: [],
    columns: defaultColumns,
    loaded: false,
    searchText: '',
    searchedColumn: '',
    permissionsWithLabels: [],
  };

  onPermissionChanges = async (permissionIndex, input, { target }) => {
    let { users } = this.state;

    for (let i = 0; i < users.length; i++) {
      if (users[i].id === input.id) {
        users[i][permissionIndex] = target.checked;
      }
    }

    var newPermissions = Object.keys(input).reduce(function (obj, k) {
      if (k !== 'first_name') obj[k] = input[k];
      return obj;
    }, {});

    newPermissions = Object.keys(newPermissions).reduce(function (obj, k) {
      if (k !== 'last_name') obj[k] = newPermissions[k];
      return obj;
    }, {});

    newPermissions = Object.keys(newPermissions).reduce(function (obj, k) {
      if (k !== 'up_email') obj[k] = newPermissions[k];
      return obj;
    }, {});

    const body = {
      users: [newPermissions],
    }
    const res = await server(endpoints.setPermissions.route, endpoints.setPermissions.method, {}, body)
    const { error_message, message: successMessage } = res;
    if (error_message) {
      message.error(error_message);
      return;
    }
    else {
      message.success(successMessage);
    }
    this.setState({ users });
    this.getColumns(this.state.permissionsWithLabels);
  }

  getUsersWithPerms = async () => {
    server(endpoints.getUsersWithPerms.route, endpoints.getUsersWithPerms.method)
      .then((res) => {
        const { error_message } = res;
        if (error_message) {
          message.error(error_message);
        } else {
          const { users, permissionsWithLabels } = res.data;
  
          this.setState({ users: users, permissionsWithLabels }, () => { this.setState({ loaded: true }) });
          this.getColumns(permissionsWithLabels);
        }
      });
  }

  async componentDidMount() {
    await this.getUsersWithPerms();
  }

  getColumns = (permissionsWithLabels) => {
    const defaultColumns = [
      {
        title: 'Nombre',
        dataIndex: 'first_name',
        key: 'first_name',
        ...this.getColumnSearchProps('first_name', "Nombre"),
      },
      {
        title: 'Apellido',
        dataIndex: 'last_name',
        key: 'last_name',
        ...this.getColumnSearchProps('last_name', "Apellido"),
      },
      {
        title: 'UP Email',
        dataIndex: 'up_email',
        key: 'up_email',
        ...this.getColumnSearchProps('up_email', "UP Email"),
      },
    ];

    let columns = defaultColumns;

    for (let i = 0; i < permissionsWithLabels.length && flag === false; i++) {
      const permission = permissionsWithLabels[i];
      let permissionIndex = permission.value;
      columns.push(
        {
          title: permission.label,
          dataIndex: permissionIndex,
          key: permissionIndex,
          render: (value, record) => 
            <Checkbox
              checked={value} disabled={record.up_email === admin ? true : false} onChange={(user) => this.onPermissionChanges(permissionIndex, record, user)}
            />         
        }
      );
    }
    this.setState({ columns });
  }


  getColumnSearchProps = (dataIndex, searcher) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Buscar ${searcher}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Limpiar
        </Button>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
        </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>

      text

  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const { users, columns, loaded } = this.state;
    return (
      <React.Fragment>
        <div>
          <Row justify="center">
            <PageHeader
              title="Permisos de usuarios"
            />
          </Row>
          {loaded &&
            <Table dataSource={users} columns={columns} style={{ padding: '50px', paddingTop: '0px' }}>
            </Table>
          }
        </div>
      </React.Fragment>
    );
  }
}

export default AdminUsers;