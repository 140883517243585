import React, { Component, Fragment } from 'react';
import Forma from '../components/formPerfil';
import './../styles/profile.css';
import Estadisticas from '../components/estadisticasPerfil';
import 'antd/dist/antd.css';
import '../styles/antd.css';
import { Row, Col, PageHeader, Upload, Button, message } from 'antd';


import { filesSizeLimitMB } from "../utils/constants";
import Loading from '../components/loading';
import server, { endpoints } from '../utils/server';


class ProfileView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            info: {},
            editionMode: false,
            fileList: [],
        };
    };

    loadingFinished = () => {
        this.setState({ loading: true });
        this.setState({ loading: false });
    }

    componentDidMount = () => {
        this.getPorfileInfo()
    }
    getPorfileInfo = async () => {
        let idSelectedCycle = this.props.idSelectedCycle;
        const params = {
            id_cycle: idSelectedCycle,
        };
        await server(endpoints.getUserProfile.route, endpoints.getUserProfile.method, params).then(res => {
                let info = res.data;
                this.setState({ info, loading: true }, this.loadingFinished);
                this.setState({
                    fileList: {
                        name: "Carta.png",
                        uid: "Carta.png",
                    }
                })
            })
            .catch(err => console.log(err))
    }

    cambiarInfo = () => {
        alert('Se ha actulizado su informacion con exito');
        const { editionMode } = this.state;
        if (editionMode) {
            this.setState({ editionMode: false });
            return;
        }
    };


    cambiarDato = () => {
        const { editionMode } = this.state
        if (editionMode) {
            this.setState({ editionMode: false });
            return;
        }
        this.setState({ editionMode: true });
    };
    onNoStats = () => {
        const { teams } = this.state.info;
        return teams.length === 0 ? 14 : 8;
    }

    onProfilePhotoChanged = newFileUrl => {
        let info = { ...this.state.info };
        info.profile_photo_url = newFileUrl;
        this.setState({ info });
        this.props.onProfilePhotoChanged(newFileUrl);
    }

    uploadImageCard = (fileName, fileContents) => {
        const body = {
            fileName,
            fileContents,
        };
        server(endpoints.uploadImageCard.route, endpoints.uploadImageCard.method, {}, body).then(data => {
            const { error_message, message: response, data: d } = data;
            if (error_message) {
                message.error(error_message);
            } else {
                message.success(response);
                this.setState({ info: {
                    ...this.state.info,
                    image_card_url: d.url,
                }});
            }
        })
    }

    encodeFile = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = (error) => reject(error);
    });

    beforeUpload = async (file) => {
        let converted_file = await this.encodeFile(file);
        const isSizeValid = file.size / 1000000 < filesSizeLimitMB;
        if (!isSizeValid) {
        return message.error(`El tamaño del archivo debe ser menor a ${filesSizeLimitMB}MB`);
        }
        this.uploadImageCard(file.name, converted_file);
    };

    handleFileChange = info => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        if (fileList) {
          fileList[0].status = "done";
        }
        this.setState({ fileList });
    }

    render() {

        const { loading, info } = this.state;
        const { idUR } = this.props
        return (
            <Fragment>
                {loading ? <Loading /> :
                    <Fragment>
                        <Row justify="center">
                            <PageHeader
                                title="Información personal"
                            />
                        </Row>
                        <Row justify="space-around" gutter={[16, 0]} >
                            <Col span={11} style={{ marginTop: "0" }} justify="center">
                                <Row>
                                    <Forma
                                        data={info}
                                        onCambiarDato={this.cambiarDato}
                                        onCambiarInfo={this.cambiarInfo}
                                        idUR={idUR}
                                        onProfilePhotoChanged={this.onProfilePhotoChanged}
                                        getPorfileInfo={this.getPorfileInfo}
                                    />

                                </Row>
                            </Col >
                            <Col span={11}>
                                <Row justify="end" gutter={[8, 0]}>
                                    {
                                        info.teams.map(team =>
                                            <Estadisticas
                                                key={team.id_team_per_cycle}
                                                team={team.team}
                                                role={team.role}
                                                pendingTasks={team.pendingTasks}
                                                hours={team.hours}
                                                totalHours={team.total_nominal_hours}
                                                percentage={team.percentage}
                                                idUR={idUR}
                                                idSelectedCycle={this.props.idSelectedCycle}
                                                idTeam={team.id_team_per_cycle}
                                                qrCode={team.qr_code}
                                            />
                                        )
                                    }
                                </Row>
                                <Row justify='end' style={{ marginRight: "2rem" }}>
                                {/*
                                    <div style={{marginRight: "2rem"}}>
                                        <Upload
                                            multiple={false}
                                            beforeUpload={this.beforeUpload}
                                            fileList={this.fileList}
                                            onChange={this.handleFileChange}
                                            itemRender={() => null}
                                        >
                                            <Button
                                                style={{ backgroundColor: info.image_card_url ? "green" : "#9a171f" }}
                                                type='primary'
                                            >
                                                Subir carta de privacidad
                                            </Button>
                                        </Upload>
                                        {info.image_card_url && <a href={info.image_card_url}>Carta de Privacidad</a>}
                                    </div>
                                */}
                                    <div>
                                        <Upload
                                            multiple={false}
                                            beforeUpload={this.beforeUpload}
                                            fileList={this.fileList}
                                            onChange={this.handleFileChange}
                                            itemRender={() => null}
                                        >
                                            <Button
                                                style={{ backgroundColor: info.image_card_url ? "green" : "#9a171f" }}
                                                type='primary'
                                            >
                                                Subir carta de imagen
                                            </Button>
                                        </Upload>
                                        {info.image_card_url && <a href={info.image_card_url}>Carta uso de Imagen</a>}
                                    </div>
                                </Row>
                            </Col >
                        </Row>
                    </Fragment>
                }
            </Fragment>
        );
    }
};

export default ProfileView;
