import React, {useEffect, useState} from "react";
import {Button, Row, Select, Col, message, Modal} from "antd"
import {LeftCircleOutlined, CloseCircleOutlined,ArrowsAltOutlined} from "@ant-design/icons"
import {useParams }from "react-router"
import { rootEndpoint } from "../utils/constants";
import axios from "axios";
import { NavLink, withRouter, Link } from "react-router-dom"
import server, { endpoints} from "../utils/server";


// style={{background:"#EE8216"}}
const EvaluationAnswers = ({idUR, idCycle}) =>{
    const [evaluations, setEvaluations] = useState([]);
    const [questions, setQuestions] = useState();

    const [selectTeam, setSelectTeam] = useState();
    const [selectQuestion, setSelectQuestion] = useState();
    const [selectUser, setSelectUser] = useState();

    const [answers, setAnswers] = useState([]);
    const [teams, setTeams] = useState();

    const [answerDetailIndex, setAnswerDetailIndex] = useState();
    const [answerDetails, setAnswerDetails]  = useState();
    const [memberName, setMemberName] = useState();
    const [usersThatAnswered, setUsersThatAnswered] = useState();
    
    const params = useParams();
    useEffect(()=> {
        getEvaluation();
        getEvaluationQuestions(params.id);
        getTeamsInCycle();
      },[params])


    useEffect(()=>{
      getEvaluationAnswers(params.id);
      getTeamUsers(selectTeam);



    },[selectTeam, selectQuestion, selectUser])
    
    const getTeamAnswerDetails = async(id_user, index)=>{
      const idQuestion = answers[index].id
      let params = { id_user, id_question:idQuestion };
      if(selectTeam){
        params = { ...params, id_team:selectTeam}
      }
      const res = await server(endpoints.getTeamAnswerDetails.route,endpoints.getTeamAnswerDetails.method,params)
      if( res.error_mesasge){
        message.error(res.error_mesasge);
      }else{
        setAnswerDetails(res.data);
      }
    }

    const getTeamUsers = async(selectTeam) => {
      let params = {
        id_cycle: idCycle,
        id_team: selectTeam,
      };
      const res = await server(endpoints.getTeamUsers.route, endpoints.getTeamUsers.method, params)
      if( res.error_mesasge){
        message.error(res.error_mesasge);
      }else{
        setUsersThatAnswered(res.data);
      }
    };



    const getEvaluationAnswers = async (id_evaluation)=> {
      let params = {id_evaluation};
      if(selectQuestion){
        params = {... params, id_question:selectQuestion};
      }
      if(selectTeam){
        params = {... params, id_team:selectTeam};
      }
      if(selectUser){
        params = {... params, id_user:selectUser};
        console.log(params)

      }
      const res = await server(endpoints.getEvaluationAnswers.route,endpoints.getEvaluationAnswers.method, params);
      if (res.error_mesasge){
        setAnswers()
      }else{
        setAnswers(res.data);
      }
    }


    const getTeamsInCycle = async() => {
      const params= {id_cycle:idCycle}
      const res = await server(endpoints.getTeamsInCycle.route, endpoints.getTeamsInCycle.method, params)
      const teams = res.data;
      const {error_message, data} = res
      if (error_message){
        message.error(error_message);
        return;
      }else{
        setTeams(teams);
      }
    }

    
    const getEvaluation = async () =>{
      const params = {id_cycle:idCycle}
      const res = await server(endpoints.getEvaluation.route, endpoints.getEvaluation.method, params);
      if ( res.error_message){
        message.error(res.error_message);
      }else{
        setEvaluations(res.data);
      }
    }
    const getEvaluationQuestions = async (id_evaluation) =>{
      const params = {id_evaluation};
      const res = await server(endpoints.getEvaluationQuestions.route, endpoints.getEvaluationQuestions.method, params);
      if (res.error_message){
        message.error(res.error_message);
      }else{
        setQuestions(res.data);
      }
    }



    const handelOpenAnswersDetail = (index, i)=>{
      setAnswerDetailIndex(index);
      getTeamAnswerDetails(i.id, index);
      setMemberName(i.first_name);

    }
    
    const {Option} = Select;
    const questionType = {range: "range",multiple_choice: "multiple choice",user_review: "user review"};


    return(
        <div style={{padding:"10px"}}>

        <Row style={{margin:"10px"}}>
            <Button type="text" style={{fontSize:"30px"}} >
                <Link to="../editEvaluations"><LeftCircleOutlined  /></Link>
            </Button>
            <h1 style={{marginLeft:"50px"}}>Respuestas</h1>
        </Row>
        <Row >

            <Row justify="space-around" style={{margin:"10px", width:"100%"}}>
                <Select allowClear style={{width:"200px"}} placeholder="Equipo" 

                onChange={setSelectTeam}

                >
                    {teams&&teams.map((t)=>(
                      <Option key={t.id} value={t.id}>{t.name}</Option>
                    ))}
      
                </Select>

                <Select allowClear style={{width:"200px"}} placeholder="Usuario" onChange={setSelectUser}>
                    {usersThatAnswered && usersThatAnswered.users.map((t)=>(
                        <Option key={t.id} value={t.id}>{t.first_name}</Option>
                      
                    ))}
                </Select>
                
                <Select allowClear style={{width:"200px"}} placeholder="Pregunta" onChange={setSelectQuestion}>
                    {questions && questions.questions.map((t)=>(
                        <Option key={t.id} value={t.id}>{t.question}</Option>
                    ))}
                </Select>

            </Row>
        </Row>
        
          <Row 
            style={{
              width: "100%",
              minHeight: "100%",
              marginTop: "3rem",
              marginBottom: "3rem"
            }}
            justify="center"
            align="middle"
           >
            <div style={{ width: "85%", height: "80%", border: "3px solid #f0f0f0", borderRadius:"10px"}} >
                {!answers?
                <h2 style={{margin:"20px"}}>Porfavor selecciona un equipo o una pregunta a filtrar</h2>
                :
                <>
                <h2 style={{margin:"20px 30px"}}>Evaluacion:&nbsp;{evaluations.find(e => e.id===parseInt( params.id,10))?.name}</h2>
                
                {answers[0]?.answers?.length!==0 ? 
                <>
                {answers.map((t, index)=>(
                  
                  <div key={t.id}>
                        <h3 style={{margin:"25px 70px 10px"}}>Pregunta {index+1} : &nbsp;{t.question}</h3>
                        {t.answers.map((i)=>(
                          <div key={i.id}>
                            <Row style={{margin:"0px 10px", padding:"5px"}} align="middle">
                              <Col span={11} offset={3}>{i.first_name}&nbsp;{i.last_name}&nbsp;&nbsp;
                              {!selectTeam&&i.team&&`(${i.team})`}
                              </Col>
                              <Col span={6}>{i.answer}</Col>
                              {questions.questionTypes.find(element => element.id === t.id_question_type)?.label===questionType.user_review &&
                                <Button 
                                icon={<ArrowsAltOutlined  rotate={90} style={{color:"white"}}/>}
                                style={{background:"#d9d9d9", border:"5px solid #ececec", borderRadius:"5px", padding:0}}
                                onClick={()=>handelOpenAnswersDetail(index, i)}
                                >
                           
                                </Button>
                              }
                            </Row>
                          </div>
                        ))}
                  </div>
                ))}
                </>:
                <>
                {selectTeam&&
                  <h3 style={{margin:"25px 70px 10px"}}>Aun no tienes respuestas de este equipo, persona o pregunta</h3>
                }
                </>
                }
  
                </>
                }

            </div>
        </Row>
        <Modal
            visible={answerDetailIndex >=0}
            title={`Pregunta ${answerDetailIndex+1} : ${answers &&answers[answerDetailIndex]?.question}`}
            width={"70%"}
            onCancel={()=>setAnswerDetailIndex()}
            destroyOnClose={true}
            footer={null}
            >
              {answerDetails &&
              <>
              <Row justify="space-between" style={{margin:"10px", padding:"0px 30px"}}>
                <h3>
                Nombre del miembro:
                </h3>
                <h3>
                Calificación otorgada a {memberName}:
                </h3>
              </Row>
              {answerDetails.map((t, index)=>(
                <Row justify="space-around" 
                style={{margin:"10px"}}
                key={index}
                >
                <Col span={11}>{t.first_name}&nbsp;{t.last_name}&nbsp;&nbsp;({t.team})</Col>
                <Col span={2}>{t.answer}</Col>
                </Row>
              ))
              }
              
              </>

              }
              {/* <Button onClick={()=>console.log(answerDetails)}>buenas</Button>
              <Button onClick={()=>console.log(memberName)}>buenas 3</Button> */}
        </Modal>
        </div>
    )
}
export default EvaluationAnswers;