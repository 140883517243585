import React from "react";
import AycSiteLogo from "../resources/AyCsite_Logo.png";
import AycLogo from "../resources/AyCVU.png";
import { Row, Avatar, Space, Button, Drawer } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { IoArrowBackOutline, IoSettingsOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { MdGroups } from "react-icons/md";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { CgNotes, CgProfile } from "react-icons/cg";
import { TbCalendarTime, TbHome2 } from "react-icons/tb";
import { IoLibraryOutline } from "react-icons/io5";



const DrawerMobile = ({ visible, setVisible, session, logOut }) => {
    const history = useHistory();
    const onNavigate = (route) => {
        history.push(route);
        setVisible(false);
    };
    const DrawerTitle = () => (
        <div style={{ overflow: "visible", height: "8rem", backgroundColor: "#dddddd", padding: "2rem 1rem 1rem 0rem" }}>
            <Row justify="space-between" align="center" style={{ margin: "0rem 1rem 1rem 1rem" }}>
                <img
                    src={AycSiteLogo}
                    style={{
                        width: "20%",
                        height: "fit-content",
                    }}
                    alt="ayc"
                />
                <img
                    src={AycLogo}
                    style={{
                        width: "20%",
                        height: "fit-content",
                    }}
                    alt="ayc"
                />
            </Row>
            <Row style={{ margin: "2rem 1rem 0rem 1rem" }} align="middle">
                <Avatar
                    size={60}
                    src={session.profile_photo_url}
                    alt={UserOutlined}
                />
                <div style={{ marginLeft: "1rem", fontSize: "14px", fontWeight: "bold", maxWidth: "60%" }}>
                    {session.full_name?.toUpperCase()}
                </div>
            </Row>
        </div>
    )
    return (
        <Drawer placement="right" open={visible} closable={false} bodyStyle={{ padding: 0 }} width="80%">
            <DrawerTitle />
            <div style={{ padding: "1rem" }}>
                <Row align="top" justify="space-between">
                    <IoArrowBackOutline
                        color="#131313"
                        size={20}
                        onClick={() => setVisible(false)}
                        style={{ marginTop: "10px" }}
                    />
                    <div style={{ color: "#131313" }}>
                        {session.up_email}
                    </div>
                </Row>
                <Space direction="vertical" size="large" style={{ marginTop: "2rem" }}>
                    <Row align="middle" onClick={() => onNavigate("/events")}>
                        <TbHome2 size={30} style={{ marginRight: "1.5rem" }}/>
                        Inicio
                    </Row>
                    <Row align="middle" onClick={() => onNavigate("/myTasks")}>
                        <TbCalendarTime
                            size={30}
                            style={{ marginRight: "1.5rem" }}
                        /> 
                        Mis tareas
                    </Row>
                    <Row align="middle" onClick={() => onNavigate("/teams")}>
                        <MdGroups
                            size={30}
                            style={{ marginRight: "1.5rem" }}
                        />
                        Equipos y bitácoras
                    </Row>
                    
                    <Row align="middle" onClick={() => onNavigate("/dashboard")}>
                        <IoLibraryOutline
                            size={30}
                            style={{ marginRight: "1.5rem" }}
                        />
                        Dashboard
                    </Row>
                    {/*
                    <Row align="middle" onClick={() => onNavigate("/reports")}>
                        <CgNotes
                            size={30}
                            style={{ marginRight: "1.5rem" }}
                        />
                        Reportes
                    </Row>}
                    {/*
                    <Row align="middle" onClick={() => onNavigate("/changePassword")}>
                        <IoSettingsOutline
                            size={30}
                            style={{ marginRight: "1.5rem" }}
                        />
                        Cambiar contraseña
                    </Row>*/}
                    <Row align="middle" onClick={() => onNavigate("/profileView")}>
                        <CgProfile size={30} style={{ marginRight: "1.5rem" }}/>
                        Mi perfil
                    </Row>
                    <Row align="middle" onClick={() => onNavigate("/help")}>
                        <AiOutlineInfoCircle size={30} style={{ marginRight: "1.5rem"}}/>
                        Obtener ayuda
                    </Row>
                </Space>
            </div>
            {visible && (
                <Button
                    style={{
                        position: "absolute",
                        bottom: 30,
                        right: 30,
                        borderRadius: "5px",
                        backgroundColor: "#53a9e3",
                    }}
                    onClick={logOut}
                >
                    Logout
                </Button>
            )}
        </Drawer>
    );
};

export default DrawerMobile;
