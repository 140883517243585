import React, { useState, useEffect } from "react";
import { message, Row, Button, Input, InputNumber } from "antd";
import axios from "axios";
import { rootEndpoint } from "../utils/constants";
import Chart from "react-apexcharts";
import {
  AiOutlinePaperClip,
  AiOutlinePlusCircle,
  AiOutlineEdit,
} from "react-icons/ai";
import moment from "moment";
import useWindowDimensions from "../utils/useWindowDimensions";
import server, { endpoints } from "../utils/server";

const Reports = ({ idUR, idCycle }) => {
  const { width } = useWindowDimensions();
  const IsTablet = width < 1024;
  const IsMobile = width < 768;
  const SmallScreen = width < 375;
  const containerSWidth = IsTablet ? (SmallScreen ? "35%" : "25%") : "20%";
  const containerLWidth = IsTablet ? (SmallScreen ? "65%" : "75%") : "80%";
  const [reports, setReports] = useState([]);
  const [reportDetail, setReportDetail] = useState();
  const [canEdit, setCanEdit] = useState(false);
  const [editingReport, setEditingReport] = useState();
  const [reloadReports, setReloadReports] = useState(false);
  const [loading, setLoading] = useState(false);
  const options = {
    type: "donut",
    labels: [
      "Alumnos",
      "Miembros de arte y cultura",
      "Colaboradores de arte y cultura",
      "Digital",
      "Ex UP",
      "Externos",
    ],
    dataLabels: {
      enabled: false,
    },
    colors: [
      "#008ffb",
      "#00e396",
      "#ffe15d",
      "#ff4560",
      "#775dd0",
      "#fc6238",
      "#0065a2",
    ],
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
          },
          size: "50%",
        },
      },
    },
  };
  const options2 = {
    type: "donut",
    labels: ["Facebook", "Instagram"],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
          },
          size: "50%",
        },
      },
    },
  };
  const defaultReport = {
    name: "",
    link: "",
    students: 0, //////////////
    ayc_members: 0, //////////////
    ayc_colaborators: 0, ////////////////
    // former_ayc: 0,
    former_up: 0, ///////////////
    digital: 0, //////////
    external: 0, ////////////////
    facebook_interactions: 0, /////////
    instagram_interactions: 0, //////////
  };
  useEffect(() => {
    const params = { id_cycle: idCycle };
    server(endpoints.getAllEvents.route, endpoints.getAllEvents.method, params)
      .then((data) => {
        if (data.error_message) {
          message.error(data.error_message);
        } else {
          const { reports, total, canEdit } = data.data;
          setReports([{ id: 0, name: "Total", ...total }, ...reports]);
          if (!reportDetail) {
            setReportDetail({ id: 0, name: "Total", ...total });
          }
          setCanEdit(canEdit);
        }
      })
      .finally(() => setReloadReports(false));
    //eslint-disable-next-line
  }, [idUR, idCycle, reloadReports]);
  const onEditReport = (report) => {
    setReportDetail(report);
    setEditingReport(report);
  };
  const updateReport = (report) => {
    setLoading(true);
    const params = {
      id_report: report.id,
    };
    delete report.id;
    const body = {
      ...report,
    };
    server(
      endpoints.updateReport.route,
      endpoints.updateReport.method,
      params,
      body
    )
      .then((response) => {
        const { data, error_message, message: successMessage } = response;
        if (error_message) {
          message.error(error_message);
        } else {
          message.success(successMessage);
          setReportDetail(data);
          setEditingReport(null);
          setReloadReports(true);
        }
      })
      .finally(() => setLoading(false));
  };
  const deleteReport = (id_report) => {
    setLoading(true);
    const params = { id_report };
    server(
      endpoints.deleteEventReport.route,
      endpoints.deleteEventReport.method,
      params
    )
      .then((response) => {
        const { message: serverMessage, error_message } = response;
        if (error_message) {
          message.error(error_message);
        } else {
          message.success(serverMessage);
          setEditingReport(null);
          setReportDetail(null);
          setReloadReports(true);
        }
      })
      .finally(() => setLoading(false));
  };
  const insertReport = (report) => {
    setLoading(true);
    const body = {
      ...report,
      date: moment().format("YYYY-MM-DD"),
    };
    const params = {
      id_cycle: idCycle,
    };
    server(
      endpoints.insertEventReport.route,
      endpoints.insertEventReport.method,
      params,
      body
    )
      .then((response) => {
        const { data, error_message, message: successMessage } = response;
        if (error_message) {
          message.error(error_message);
        } else {
          message.success(successMessage);
          setReportDetail(data);
          setEditingReport(null);
          setReloadReports(true);
        }
      })
      .finally(() => setLoading(false));
  };
  return (
    <Row
      style={{
        width: "100%",
        minHeight: "100%",
        marginTop: "5rem",
      }}
      justify="center"
      align="middle"
    >
      <div
        style={{
          width: IsTablet ? "95%" : "80%",
          height: "80%",
          border: "1px solid #f0f0f0",
        }}
      >
        <Row style={{ width: "100%", height: "30px" }}>
          <Row
            style={{
              width: containerSWidth,
              background: "#fafafa",
              paddingLeft: "10px",
              border: "1px solid #f0f0f0",
            }}
            align="middle"
            justify="space-between"
          >
            <div
              style={{
                width: IsMobile ? "60%" : "80%",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              Nombre del evento
            </div>
            {canEdit && (
              <AiOutlinePlusCircle
                size={25}
                style={{ marginRight: "5px", cursor: "pointer" }}
                color="#8b8b8b"
                onClick={() => {
                  setEditingReport(defaultReport);
                }}
              />
            )}
          </Row>
          <Row
            style={{
              width: containerLWidth,
              background: "#fafafa",
              paddingLeft: "10px",
              border: "1px solid #f0f0f0",
              height: "30px",
            }}
            align="middle"
          >
            Especificaciones del evento
          </Row>
        </Row>
        <Row style={{ flex: 1, display: "flex" }}>
          <div
            style={{
              width: containerSWidth,
              flex: 1,
              background: "#fafafa",
              overflow: "auto",
              maxHeight: "40rem",
            }}
          >
            {reports.map((report) => (
              <Row
                style={{
                  background:
                    reportDetail &&
                    reportDetail.id === report.id &&
                    (!editingReport || editingReport.id === report.id)
                      ? "#d6d6d6"
                      : "#fafafa",
                  paddingLeft: "10px",
                  border: "1px solid #f0f0f0",
                  height: "50px",
                  cursor: "pointer",
                  margin: 0,
                  overflow: "auto",
                }}
                align="middle"
                onClick={() => setReportDetail(report)}
                key={report.id}
                justify="space-between"
              >
                <div
                  style={{
                    width: IsMobile ? "60%" : "80%",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {report.name}
                </div>
                {canEdit && report.id > 0 && (
                  <AiOutlineEdit
                    size={25}
                    color="#8b8b8b"
                    style={{ marginRight: "5px", cursor: "pointer" }}
                    onClick={() => onEditReport(report)}
                  />
                )}
              </Row>
            ))}
          </div>
          <div
            style={{
              width: containerLWidth,
              height: "100%",
              padding: SmallScreen ? "1rem" : "3rem",
            }}
          >
            {reportDetail && (
              <>
                {editingReport ? (
                  <div style={{ flexDirection: "column" }}>
                    <Row align="middle" style={{ marginBottom: "10px" }}>
                      Nombre
                      <Input
                        value={editingReport.name}
                        onChange={(e) =>
                          setEditingReport({
                            ...editingReport,
                            name: e.target.value,
                          })
                        }
                        style={{
                          flex: 1,
                          marginLeft: "10px",
                        }}
                        placeholder="Nombre del evento"
                      />
                    </Row>
                    <div>Link del reporte completo</div>
                    <Input
                      value={editingReport.link}
                      onChange={(e) =>
                        setEditingReport({
                          ...editingReport,
                          link: e.target.value,
                        })
                      }
                      style={{
                        width: "10rem",
                      }}
                      placeholder="Introduce link del reporte"
                    />
                  </div>
                ) : (
                  <h2>{reportDetail.name}</h2>
                )}
                {canEdit && reportDetail.link && !editingReport && (
                  <Button
                    style={{
                      width: "10rem",
                      height: "30px",
                      borderRadius: "5px",
                      background: "#0091fe",
                      color: "#ffffff",
                      border: "1px solid #a6d9ff",
                      marginBottom: "2rem",
                    }}
                    onClick={() => window.open(reportDetail.link)}
                  >
                    <Row align="middle" justify="space-between">
                      <AiOutlinePaperClip color="#ffffff" size={16} />
                      Reporte completo
                    </Row>
                  </Button>
                )}
                <Row align="middle" justify="space-between">
                  <div>
                    <b>Asistentes:</b>
                    <Row align="middle" style={{ marginBottom: "10px" }}>
                      <div style={{ marginRight: "1.1rem" }}>Alumnos</div>
                      {editingReport ? (
                        <InputNumber
                          value={editingReport.students}
                          onChange={(value) =>
                            setEditingReport({
                              ...editingReport,
                              students: value,
                            })
                          }
                          min={0}
                        />
                      ) : (
                        <div>{reportDetail.students}</div>
                      )}
                    </Row>
                    <Row align="middle" style={{ marginBottom: "10px" }}>
                      <div style={{ marginRight: "1rem" }}>
                        Miembros de arte y cultura
                      </div>
                      {editingReport ? (
                        <InputNumber
                          value={editingReport.ayc_members}
                          onChange={(value) =>
                            setEditingReport({
                              ...editingReport,
                              ayc_members: value,
                            })
                          }
                          min={0}
                        />
                      ) : (
                        <div>{reportDetail.ayc_members}</div>
                      )}
                    </Row>
                    <Row align="middle" style={{ marginBottom: "10px" }}>
                      <div style={{ marginRight: "1rem" }}>
                        Colaboradores de arte y cultura
                      </div>
                      {editingReport ? (
                        <InputNumber
                          value={editingReport.ayc_colaborators}
                          onChange={(value) =>
                            setEditingReport({
                              ...editingReport,
                              ayc_colaborators: value,
                            })
                          }
                          min={0}
                        />
                      ) : (
                        <div>{reportDetail.ayc_colaborators}</div>
                      )}
                    </Row>
                    <Row align="middle" style={{ marginBottom: "10px" }}>
                      <div style={{ marginRight: "1rem" }}>Digital</div>
                      {editingReport ? (
                        <InputNumber
                          value={
                            editingReport.digital ? editingReport.digital : 0
                          }
                          onChange={(value) =>
                            setEditingReport({
                              ...editingReport,
                              digital: value,
                            })
                          }
                          min={0}
                        />
                      ) : (
                        <div>{reportDetail.digital}</div>
                      )}
                    </Row>
                    <Row align="middle" style={{ marginBottom: "10px" }}>
                      <div style={{ marginRight: "1rem" }}>Ex UP</div>
                      {editingReport ? (
                        <InputNumber
                          value={editingReport.former_up}
                          onChange={(value) =>
                            setEditingReport({
                              ...editingReport,
                              former_up: value,
                            })
                          }
                          min={0}
                        />
                      ) : (
                        <div>{reportDetail.former_up}</div>
                      )}
                    </Row>
                    <Row align="middle" style={{ marginBottom: "10px" }}>
                      <div style={{ marginRight: "1rem" }}>Externos</div>
                      {editingReport ? (
                        <InputNumber
                          value={editingReport.external}
                          onChange={(value) =>
                            setEditingReport({
                              ...editingReport,
                              external: value,
                            })
                          }
                          min={0}
                        />
                      ) : (
                        <div>{reportDetail.external}</div>
                      )}
                    </Row>
                  </div>
                  <Chart //No funciona por que digital es NULL
                    options={options}
                    series={
                      editingReport
                        ? [
                            editingReport.students,
                            editingReport.ayc_members,
                            editingReport.ayc_colaborators,
                            editingReport.digital,
                            editingReport.former_up,
                            editingReport.external,
                          ]
                        : [
                            reportDetail.students,
                            reportDetail.ayc_members,
                            reportDetail.ayc_colaborators,
                            reportDetail.digital,
                            reportDetail.former_up,
                            reportDetail.external,
                          ]
                    }
                    type="donut"
                    width={IsMobile ? (SmallScreen ? 190 : 225) : 450}
                  />
                </Row>
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    marginTop: "2rem",
                    marginRight: !IsMobile ? "14%" : "0",
                  }}
                >
                  <div>
                    <b>Alcance:</b>
                    <Row align="middle" style={{ marginBottom: "10px" }}>
                      <div style={{ marginRight: "1.1rem" }}>Facebook</div>
                      {editingReport ? (
                        <InputNumber
                          value={editingReport.facebook_interactions}
                          onChange={(value) =>
                            setEditingReport({
                              ...editingReport,
                              facebook_interactions: value,
                            })
                          }
                          min={0}
                        />
                      ) : (
                        <div>{reportDetail.facebook_interactions}</div>
                      )}
                    </Row>
                    <Row align="middle">
                      <div style={{ marginRight: "1rem" }}>Instagram</div>
                      {editingReport ? (
                        <InputNumber
                          value={editingReport.instagram_interactions}
                          onChange={(value) =>
                            setEditingReport({
                              ...editingReport,
                              instagram_interactions: value,
                            })
                          }
                          min={0}
                        />
                      ) : (
                        <div>{reportDetail.instagram_interactions}</div>
                      )}
                    </Row>
                  </div>
                  <Chart
                    options={options2}
                    series={
                      editingReport
                        ? [
                            editingReport.facebook_interactions,
                            editingReport.instagram_interactions,
                          ]
                        : [
                            reportDetail.facebook_interactions,
                            reportDetail.instagram_interactions,
                          ]
                    }
                    type="donut"
                    width={IsMobile ? (SmallScreen ? 190 : 225) : 300}
                  />
                </Row>
                {editingReport && (
                  <Row justify="space-between">
                    <Row>
                      <Button
                        type="primary"
                        onClick={() =>
                          editingReport.id
                            ? updateReport(editingReport)
                            : insertReport(editingReport)
                        }
                        loading={loading}
                      >
                        Guardar
                      </Button>
                      <Button
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          marginLeft: "1rem",
                        }}
                        onClick={() => {
                          setEditingReport(null);
                        }}
                        loading={loading}
                      >
                        Cancelar
                      </Button>
                    </Row>
                    {editingReport.id && (
                      <Button
                        style={{ backgroundColor: "#c7092d", color: "white" }}
                        loading={loading}
                        onClick={() => deleteReport(editingReport.id)}
                      >
                        Eliminar evento
                      </Button>
                    )}
                  </Row>
                )}
              </>
            )}
          </div>
        </Row>
      </div>
    </Row>
  );
};

export default Reports;
