import React, { useState, useEffect } from "react";
import { Row, Avatar, message, Image, Select } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { IoArrowBackOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import ProfileM from "../components/profileM";
import { rootEndpoint } from "../utils/constants";
import axios from "axios";
import WorkViewMobile from "../components/workM";
import useWindowDimensions from "../utils/useWindowDimensions";
import server, { endpoints } from "../utils/server";

const { Option } = Select;

const ProfileViewMobile = ({ session, profileState, setProfileState, idCycle, idUR }) => {
    const history = useHistory();
    const [userInfo, setUserInfo] = useState({});
    const [careers, setCareers] = useState([]);
    const [qrTeam, setQrTeam] = useState();
    const { width } = useWindowDimensions();
    useEffect(() => {
        const params = {
            id_cycle: idCycle,
        };       
        server(endpoints.getUserProfile.route, endpoints.getUserProfile.method, params).then((res) => {
            const { error_message, data } = res;
            if (error_message) {
                message.error(error_message);
            } else {
                setUserInfo(data);
                setQrTeam(data?.teams[0]?.id_team_per_cycle);
            }
        })
    }, [idUR, idCycle]);
    useEffect(() => {
        server(endpoints.getCareerOptions.route, endpoints.getCareerOptions.method).then((res) => {
            const { error_message, data } = res;
            if (error_message) {
                this.message.error(error_message);
            } else {
                setCareers(data);
            }
        });
    }, []);

    return (
        <div style={{ height: "100%" }}>
            <div
                style={{
                    width: "100%",
                    backgroundColor: "#ac1830",
                    color: "white",
                    padding: "1rem 2rem 5rem 1rem",
                    fontSize: "18px",
                    overflow: "visible",
                    textAlign: "center",
                    height: "100%",
                }}
            >
                <Row justify="start">
                    <IoArrowBackOutline color="white" size={30} onClick={() => history.goBack()} />
                </Row>
                <Row justify="center" style={{ width: "100%" }}>
                    <Avatar
                        size={80}
                        src={session.profile_photo_url}
                        alt={UserOutlined}
                    />
                </Row>
                {session.full_name?.toUpperCase()}
            </div>
            <Row
                style={{
                    margin: "-2.25rem 1rem 2rem 1rem",
                    height: "4.5rem",
                    boxShadow: "0px 2px 8px 2px grey",
                    borderRadius: "5px",
                    backgroundColor: "white",
                }}
                align="middle"
                justify="space-around"
            >
                <div onClick={() => setProfileState("profile")}>
                    Perfil
                    {profileState === "profile" && <div style={{ height: "2px", width: "100%", backgroundColor: "black"}}/>}
                </div>
                <div onClick={() => setProfileState("work")}>
                    Trabajo
                    {profileState === "work" && <div style={{ height: "2px", width: "100%", backgroundColor: "black"}}/>}
                </div>
                <div onClick={() => setProfileState("qr")}>
                    Qr
                    {profileState === "qr" && <div style={{ height: "2px", width: "100%", backgroundColor: "black"}}/>}
                </div>
            </Row>
            {profileState === "profile" && (
                <ProfileM info={userInfo} careers={careers} idUR={idUR} setInfo={setUserInfo} />
            )}
            {profileState === "work" && (
                <WorkViewMobile teams={userInfo?.teams}/>
            )}
            {profileState === "qr" && (
                <div>
                    <Select
                        value={qrTeam}
                        onChange={setQrTeam}
                        style={{ marginLeft: "1rem", width: "10rem" }}
                    >
                        {userInfo?.teams?.map((team) => (
                            <Option key={team.id_team_per_cycle} value={team.id_team_per_cycle}>
                                {team.team}
                            </Option>
                        ))}
                    </Select>
                    <Image
                        width={width - 20}
                        src={userInfo?.teams?.find(team => team.id_team_per_cycle === qrTeam)?.qr_code}
                    />
                </div>
            )}
        </div>
    );
};

export default ProfileViewMobile;