import React, { Component } from "react";
import { Form, Input, Button, Row, Col, PageHeader, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone, CheckCircleTwoTone, CloseCircleOutlined } from "@ant-design/icons";
import { sha256 } from 'js-sha256';
import server, { endpoints } from "../utils/server";

class ChangePassword extends Component {
  state = {
    data: {},
    validateStatus: {},
    newPasswordReq: {
      numbers: false,
      uppercase: false,
      lowercase: false,
      characters: false
    },
    help: {},
    alertType: {},
    alertOn: false,
  };

  validateNewPassword = (password, charactersToFind, len) => {
    let i = 0;
    for (i = 0; i < len; i++) {
      if (password[i].match(charactersToFind)) {
        return 1;
      }
    }
    return 0;
  }

  validateStatus = ({ name, value }) => {
    let status = "";
    let helpMessage = "";
    let numbers = /^[0-9]+$/;
    let uppercase = /^[A-Z]+$/;
    let lowercase = /^[a-z]+$/;
    switch (name) {
      case "oldPassword":
        if (value) {
          status = "success";
          return { status, helpMessage };
        } else {
          status = "error";
          helpMessage = "Por favor introduzca su contraseña";
          return { status, helpMessage };
        }
      case "newPassword":
        if (value === "") {
          status = "error";
          helpMessage = "La contraseña no puede estar vacía";
          return { status, helpMessage };
        }
        if (value === this.state.data.oldPassword) {
          status = "error";
          helpMessage = "La contraseña no puede ser igual a la anterior";
          return { status, helpMessage };
        }
        if (value.includes(" ")) {
          status = "error";
          helpMessage = "La contraseña no puede contener espacios";
          return { status, helpMessage };
        }
        if (!this.validateNewPassword(value, numbers, value.length)) {
          status = "error";
          helpMessage = "La contraseña debe tener al menos un número";
          this.setState({ numbers: false })
          return { status, helpMessage };
        } else {
          this.setState({ numbers: true })
        }
        if (!this.validateNewPassword(value, uppercase, value.length)) {
          status = "error";
          helpMessage = "La contraseña debe tener al menos una mayúscula";
          this.setState({ uppercase: false })
          return { status, helpMessage };
        } else {
          this.setState({ uppercase: true })
        }
        if (!this.validateNewPassword(value, lowercase, value.length)) {
          status = "error";
          helpMessage = "La contraseña debe tener al menos una minúscula";
          this.setState({ lowercase: false })
          return { status, helpMessage };
        } else {
          this.setState({ lowercase: true })
        }
        if (value.length < 8 || value.length > 12) {
          status = "error";
          helpMessage = "La contraseña debe tener entre 8 y 12 carácteres";
          this.setState({ characters: false })
          return { status, helpMessage };
        } else {
          this.setState({ characters: true })
          status = "success";
          return { status, helpMessage };
        }
      case "repeatPassword":
        if (this.state.data.newPassword !== value || value === "") {
          status = "error";
          helpMessage = "La nueva contraseña no coincide";
          return { status, helpMessage };
        } else {
          status = "success";
          return { status, helpMessage };
        }
      default:
        break;
    }
  };
  handleChange = ({ currentTarget: input }) => {
    const validateStatus = { ...this.state.validateStatus };
    const { status, helpMessage } = this.validateStatus(input);
    validateStatus[input.name] = status;

    const help = { ...this.state.help };
    help[input.name] = helpMessage;

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, validateStatus, help });
  };
  enableButton = () => {
    const { oldPassword, repeatPassword, newPassword } = this.state.validateStatus;
    if (oldPassword === "success" && repeatPassword === "success" && newPassword === "success") return false;
    return true;
  };
  handleSubmit = async () => {
    const { data } = this.state;
    const body = {
      password: sha256(data.oldPassword),
      newPassword: sha256(data.repeatPassword),
    };
    server(endpoints.setPassword.route, endpoints.setPassword.method, {}, body).then(res => {
      const { error_message } = res;
      if (error_message) {
        // this.setAlert("error", `${error_message}`, "Inténtelo de nuevo");
        message.error(error_message);
        this.retry();
        return;
      }
      //this.setAlert("success", `${message}`, "Acción exitosa");
      message.success("La contraseña se ha cambiado exitosamente");
      this.props.setNavBar(true);
    }).catch(() => {
      message.error("Contraseña no valida");
    });
  };

  retry = () => {
    let { alertType } = this.state;
    alertType.type = "type";
    alertType.message = "message";
    alertType.description = "description";

    this.setState({ alertType, alertOn: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({
      data: {},
      validateStatus: {},
      numbers: false,
      uppercase: false,
      lowercase: false,
      characters: false,
      help: {},
      alertType: {},
      alertOn: false,
    });
  };

  render() {
    const layout = {
      labelCol: {
        span: 20,
      },
      wrapperCol: {
        span: 24,
      },
    };
    // const tailLayout = {
    //   wrapperCol: {
    //     span: 6,
    //   },
    // };

    const onFinish = async (values) => {
      await this.handleSubmit();
    };

    const onFinishFailed = (errorInfo) => {
    };
    const { validateStatus, help, data, alertOn } = this.state;


    return (
      <React.Fragment>
      {/* {console.log('This is my PSW', Login.password)}   */}
        <Row justify="center">
          <PageHeader title="Cambiar contraseña" />
        </Row>
        {alertOn ? (
          this.renderAlert()
        ) : (
          <Row justify="center">
            <Col span={this.props.isMobile ? 20 : 8}>
              <Form
                {...layout}
                layout="vertical"
                id="changePassword"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                style={{ textAlign: "center" }}
              >
                <Form.Item
                  label="Contraseña anterior"
                  hasFeedback
                  validateStatus={validateStatus.oldPassword}
                  help={help.oldPassword}
                >
                  <Input.Password
                    name="oldPassword"
                    onChange={this.handleChange}
                    value={data["oldPassword"]}
                    placeholder="Introduzca contraseña"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Nueva contraseña"
                  hasFeedback
                  validateStatus={validateStatus.newPassword}
                  help={help.newPassword}
                >
                  <Input.Password
                    name="newPassword"
                    onChange={this.handleChange}
                    value={data["newPassword"]}
                    placeholder="Introduzca contraseña"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
                
                  <Row align="middle">
                    <Col span="1" style={{ marginRight: this.props.isMobile ? "5px" : 0 }}>{this.state.characters ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleOutlined />}</Col> 8 a 12 cáracteres</Row>
                  <Row align="middle">
                    <Col span="1" style={{ marginRight: this.props.isMobile ? "5px" : 0 }}>{this.state.uppercase ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleOutlined />}</Col> Mayúsculas</Row>
                  <Row align="middle">
                    <Col span="1" style={{ marginRight: this.props.isMobile ? "5px" : 0 }}>{this.state.lowercase ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleOutlined />}</Col> Minúsculas</Row>
                  <Row align="middle">
                    <Col span="1" style={{ marginRight: this.props.isMobile ? "5px" : 0 }}>{this.state.numbers ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleOutlined />}</Col> Números</Row>

                <Form.Item
                  label="Repita la nueva contraseña"
                  hasFeedback
                  validateStatus={validateStatus.repeatPassword}
                  help={help.repeatPassword}
                >
                  <Input.Password
                    name="repeatPassword"
                    onChange={this.handleChange}
                    value={data["repeatPassword"]}
                    placeholder="Introduzca contraseña"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>

                <Form.Item >
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={this.enableButton() ? null : { backgroundColor: "#9a171f", borderColor: "#9a171f" }}
                    disabled={this.enableButton()}
                  >
                    Cambiar
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        )
        }
      </React.Fragment>
    );
  }
}

export default ChangePassword;
