import React from "react";
import { Table, Input, Button, Space, Tag, message, Row, Select} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import server, { endpoints } from "../utils/server";


class TeamInfoTable extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    report: '',
    month_filter: '',
    loading: false,
  };

  componentDidMount() {
    this.getTeamReportInfo();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.month_filter !== this.state.month_filter) {
      this.getTeamReportInfo();
    }
  }
 
  getTeamReportInfo = async () => {
    const params = {
      id_team: this.props.id_team,
      id_cycle: this.props.id_cycle,
      month_filter: this.state.month_filter,
    }
    this.setState({ loading: true });
    const res = await server(endpoints.getTeamReport.route, endpoints.getTeamReport.method, params);
    const {error_message, data: report} = res;
    if (error_message) {
      message.error(error_message);
      return;
    } else {
      this.setState({ report });
    }
    this.setState({ loading: false });
  }


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'first_name',
        key: 'first_name',
        ...this.getColumnSearchProps('first_name'),
      },
      {
        title: 'Apellido',
        dataIndex: 'last_name',
        key: 'last_name',
        ...this.getColumnSearchProps('last_name'),
      },
      {
        title: 'Rol',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Horas',
        dataIndex: 'total_nominal_hours',
        key: 'total_nominal_hours',
      },
      {
        title: 'Horas por semana',
        dataIndex: 'hours_per_week',
        key: 'hours_per_week',
      },
      {
        title: 'Horas registradas',
        dataIndex: 'registered_hours',
        key: 'registered_hours',
      },
      {
        title: 'Hipótesis de horas',
        dataIndex: 'hypotethical_hours',
        key: 'hypotethical_hours',
      },
      {
        title: 'Horas faltantes',
        dataIndex: 'missing_hours',
        key: 'missing_hours',
      },
      {
        title: 'Estatus',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
          let color = "volcano";
          if((status * 100) >= 75){
            color = "orange";
          }
          if((status * 100) >= 85){
            color = "yellow";
          }
          if((status * 100) >= 95){
            color = "green";
          }
          return(
            <div>
                <Tag color={color}>
                  {((status * 100)) + "%"}
                </Tag>
            </div>
          )
        }    
      },
      {
        title: 'Actualización',
        dataIndex: 'last_update',
        key: 'last_update',
      },
      
    ];
    const { Option } = Select;
    return (
      <>
      
    <Row>
    <Select
      placeholder="Seleciona un mes"
      style={{ width: 170, marginBottom: 24}}
      listHeight={420}
      onChange={(value) => this.setState({ month_filter: value })}
      defaultValue={''}
    >
      <Option value={''}>Todos los meses</Option>
      <Option value={1}>Enero</Option>
      <Option value={2}>Febrero</Option>
      <Option value={3}>Marzo</Option>
      <Option value={4}>Abril</Option>
      <Option value={5}>Mayo</Option>
      <Option value={6}>Junio</Option>
      <Option value={7}>Julio</Option>
      <Option value={8}>Agosto</Option>
      <Option value={9}>Septiembre</Option>
      <Option value={10}>Octubre</Option>
      <Option value={11}>Nobiembre</Option>
      <Option value={12}>Diciembre</Option>
    </Select>
    </Row>
    <Table columns={columns} dataSource={this.state.report} loading={this.state.loading} />
    </>
    );
  }
}
export default TeamInfoTable;