import React, { useState, useEffect, useCallback } from 'react';
import axios from "axios";
import { rootEndpoint } from "../utils/constants";
import server, { endpoints } from '../utils/server';

import {
  Row,
  Col,
  Divider,
  Button,
  Form,
  Input,
  Select,
  Checkbox,
  message,
} from "antd";

//llamar a los iconos y las especificiaciones
import { fields } from '../utils/iconosFormulario'

//Variable activeHS

const { Option } = Select;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

//Variable otra Preparatoria

//Variable para evitar agregar dos veces la preparatoria
let cError = false;

const EditInformation = (props) => {

  const { id, isMobile, idUR, user, perms, idTeam, idSelectedCycle, userCanEdit, originPage, handleSubmitPerfil, onHandleCancel, onMemberAdded, } = props;
  const [userInfo, setUserInfo] = useState({ ...user });
  const [userPermsission, setUserPerms] = useState(perms || {});
  const [formulario] = useState(fields);
  const [button, buttonChange] = useState(true)
  const [careers, setCareers] = useState([])
  const [roles, setRoles] = useState([])
  const [permissions, setPermissions] = useState([])
  const [highschools, sethighSchools] = useState([])
  const [activeHighschool, setActiveHighschool] = useState(userInfo.highschool);
  const [otherHighschool, setOtherHighschool] = useState("");

  const getPerms = useCallback(async () => {
    const res = await server(endpoints.getTeamPermOptions.route, endpoints.getTeamPermOptions.method);
    const dataPerms = res.data;
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
    } else {
      setPermissions(dataPerms);
      let userPerms = {};
      (dataPerms || []).forEach(({value: perm}) => {
        if (!userPermsission[perm]) {
          userPerms[perm] = false;
        } else {
          userPerms[perm] = true;
        }
      });
      setUserPerms(userPerms);
    }
  //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCarrers();
    getHighschools();
    getRoles();
    getPerms();
  }, [getPerms]);

  const getCarrers = async () => {
    const res = await server(endpoints.getCareerOptions.route, endpoints.getCareerOptions.method);
    const dataCareers = res.data;
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
    } else {
      setCareers(dataCareers);
    }
  };

  const getRoles = async () => {
    const res = await server(endpoints.getRoles.route, endpoints.getRoles.method);
    const dataRoles = res.data;
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
    } else {
      setRoles(dataRoles);

    }
  };

  const getHighschools = async () => {
    const res = await server(endpoints.getHighSchoolOptions.route, endpoints.getHighSchoolOptions.method);
    const dataHighschools = res.data;
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
    } else {
      let otherHighschool = {
        id: 0,
        name: "Otra"
      };
      const newHighschool = [otherHighschool].concat(dataHighschools);

      sethighSchools(newHighschool);

    }
  };

  const onInfoChange = e => {
    const { id, value } = e.target;
    setUserInfo({
      ...userInfo,
      [id]: value
    })
    handlerButton()
  };
  
  const onChangeSelect = (value, field) => {
    var changedField = field.label;

    if (changedField === "highschool") {
      setActiveHighschool(value);
    }
    // if (changedField === "role"){
    //   var idRole = (roles.find((role) => role.name === value)).id;
    //   value = idRole;
    // }
    setUserInfo({
      ...userInfo,
      [changedField]: value
    })
    handlerButton()
  };

  const onPermsChange = e => {
    const { name, checked } = e.target;

    setUserPerms({
      ...userPermsission,
      [name]: checked
    })
    handlerButton()
  };

  const handleSubmit = async () => {
    delete userInfo.career

    if (originPage === "teams") {
      handleSubmitTeams();
    }
    if (originPage === "addMember") {
      handleSubmitAddMember();
    }
    if (originPage === "perfil") {
      handleSubmitPerfil(userInfo, otherHighschool);
    }
  }

  const handleCancel = async () => {
    if (originPage === "teams") {
      props.onCancel();
    }
    if (originPage === "addMember") {
      props.onHandleCancel();
    }
    if (originPage === "perfil") {
      props.handleCancelProfile();
    }
  }


  const handleSubmitTeams = async () => {
    delete userInfo.profile_photo_url


    if (otherHighschool !== '' && cError === false) {
      await insertHightschool(otherHighschool);
    }

    const data = {
      ...userInfo,
      ...userPermsission
    }
    
    const params = {
      id_user: id,
      id_team: idTeam,
      id_cycle: idSelectedCycle,
    };
    // Find the id for the name of the role in userInfo
    const idRole = (roles.find((role) => role.name === userInfo.role))?.id
    data.role = idRole ? idRole : data.role;

    if (otherHighschool !== '') {
      data.highschool = otherHighschool;
      setActiveHighschool(otherHighschool);
    }

    const res = await server(endpoints.updateUserInTeam.route, endpoints.updateUserInTeam.method, params, data);
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
      cError = true;
      return;
    }
    message.success("Informacion Actualizada exitosamente");
    setOtherHighschool("");
    props.onOk(id, data);
  };

  const handleSubmitAddMember = async () => {
    if (otherHighschool !== '' && cError === false) {
      await insertHightschool(otherHighschool);
    }

    const data = {
      ...userInfo,
      ...userPermsission
    }
    
    delete data.eventPerms
    delete data.eventReportPerms;
    
    if (otherHighschool !== '') {
      data.highschool = otherHighschool;
      setActiveHighschool(otherHighschool);
    }
    
    const params = {
      id_user: 0,
      id_cycle: idSelectedCycle,
      id_team: idTeam,
    }



    const res = await server(endpoints.insertUserToTeam.route, endpoints.insertUserToTeam.method, params, data);
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
      cError = true
      return;
    }
    message.success("Informacion Actualizada exitosamente");
    setOtherHighschool("");
    onMemberAdded();
    onHandleCancel();
  };

  const handlerButton = () => {

    if (
      userInfo.first_name === "" ||
      userInfo.last_name === "" ||
      userInfo.up_email === ""
    ) {
      if (button === true) {
        return
      }
      buttonChange(true)
      return;
    }
    else {
      buttonChange(false)
    }
  };

  const insertHightschool = async (otherHighschool) => {
    const data = {
      name: otherHighschool
    }



    const res = await server(endpoints.insertHighschool.route, endpoints.insertHighschool.method, {}, data);
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
      return;
    }
    message.success("Preparatoria agregada exitosamente");
  }

  return (
    <React.Fragment>
      <Form {...layout} preserve={false} onFinish={handleSubmit} style={{ margin: isMobile ? "1rem" : "0"}}>
        {
          formulario.map((field) =>
          ((field.name === "Sexo"
            ?
            <Form.Item
              key={field.label}
              name={field.label}
              label={field.name}
              tyle={{ width: "100%" }}
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "Por favor llena este campo"
                },
              ]}
              initialValue={userInfo[field.label]}
            >
              <Select
                onChange={(value) => onChangeSelect(value, field)}
                defaultValue={userInfo[field.label]}
              >
                <Option value="Hombre" key={"Hombre"}>Hombre</Option>
                <Option value="Mujer" key={"Mujer"} >Mujer</Option>
              </Select>
            </Form.Item>
            :
            (field.name === "Carrera"
              ?
              <Form.Item
                key={field.label}
                name={field.label}
                label={field.name}
                tyle={{ width: "100%" }}
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Por favor llena este campo"
                  },
                ]}
                initialValue={userInfo[field.label]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  defaultValue={userInfo[field.label]}
                  onChange={(key) => onChangeSelect(key, field)}
                >
                  {careers &&
                    careers.map((career) => (
                      <Option key={career.id} value={career.id}>{career.full_name}</Option>
                    ))
                  }

                </Select>


              </Form.Item>
              :
              (field.name === "Preparatoria"
                ?
                <Form.Item
                  key={field.label}
                  name={field.label}
                  label={field.name}
                  tyle={{ width: "100%" }}
                  labelAlign="left"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (activeHighschool) {
                          return Promise.resolve()
                        } else {
                          return Promise.reject("Por favor rellena este campo")
                        }
                      }
                    }),
                  ]}
                  initialValue={userInfo[field.label]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    defaultValue={userInfo[field.label]}
                    onChange={(value) => onChangeSelect(value, field)}
                  >
                    {highschools &&
                      highschools.map((highschool) => (
                        <Option key={highschool.id} value={highschool.name}>{highschool.name}</Option>
                      ))
                    }
                  </Select>
                  {activeHighschool === "Otra" ?
                    <Form.Item
                      key={"addHighschool"}
                      name={"addHighschool"}
                      label={"Nombre"}>
                      <Input onChange={(e) => setOtherHighschool(e.target.value)} />
                    </Form.Item> : null}
                </Form.Item>
                :
                (field.name === "Rol en el equipo"
                  ?
                  (originPage !== "perfil" &&
                    <Form.Item
                      key={field.label}
                      name={field.label}
                      label={field.name}
                      tyle={{ width: "100%" }}
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: "Por favor llena este campo"
                        },
                      ]}
                      initialValue={userInfo[field.label]}
                    >
                      <Select
                        defaultValue={userInfo[field.label]}
                        onChange={(value) => onChangeSelect(value, field)}
                      >
                        {roles &&
                          roles.map((rol) => (
                            <Option key={rol.id} value={rol.id}>{rol.name}</Option>
                          ))
                        }

                      </Select>
                    </Form.Item>
                  )
                  :
                  (field.name === "Horas a cumplir"
                    ?
                    (originPage !== "perfil" &&
                      <Form.Item
                        name={field.label}
                        label={field.name}
                        initialValue={userInfo[field.label]}
                        tyle={{ width: "100%" }}
                        labelAlign="left"
                        key={field.label}
                        rules={[{ required: field.isRequired, message: 'Por favor llena este campo' }]}
                      >
                        <Input
                          size="large"
                          prefix={field.icon}
                          type={field.type}
                          span={15}
                          onChange={e => onInfoChange(e)}
                          min="0"
                        />
                      </Form.Item>
                    )
                    :
                    <Form.Item
                      name={field.label}
                      label={field.name}
                      initialValue={userInfo[field.label]}
                      tyle={{ width: "100%" }}
                      labelAlign="left"
                      key={field.label}
                      rules={[{ required: field.isRequired, message: 'Por favor llena este campo' }]}
                    >
                      <Input
                        size="large"
                        prefix={field.icon}
                        type={field.type}
                        span={15}
                        onChange={e => onInfoChange(e)}
                        min="0"
                      />
                    </Form.Item>
                  )))))))}
        {

          userCanEdit &&
          <div>
            <Divider>Permisos:</Divider>

            {permissions &&
              permissions.map((perm, index) => (
                < Col align="middle" span={24} key={index}>
                  <Checkbox checked={userPermsission[perm.value]} name={perm.value} onChange={e => onPermsChange(e)}>
                    {perm.label}
                  </Checkbox>
                </Col>

              ))
            }

            {/* <Row justify="center">
              <Col align="middle" span={8}>
                <Popover content={
                  <p>Crear, editar o eliminar miembros del equipo en el ciclo actual</p>
                }
                  title="Editar miembros"
                  trigger="hover">
                  <Checkbox checked={userPermsission.membersPerms} name={"membersPerms"} onChange={e => onPermsChange(e)}>
                    Editar miembros
                  </Checkbox>
                </Popover>
              </Col>
             
              <Col align="middle" span={7}>
                <Popover content={
                  <p>Crear, editar o eliminar eventos del equipo en el ciclo actual</p>
                }
                  title="Editar eventos"
                  trigger="hover">
                  <Checkbox checked={userPermsission.eventPerms} name={"eventPerms"} onChange={e => onPermsChange(e)}>
                    Editar eventos
                  </Checkbox>
                </Popover>
              </Col>
              
              <Col align="middle" span={7}>
                <Popover content={
                  <p>Poder hacer los informes de eventos del equipo</p>
                }
                  title="Editar informes"
                  trigger="hover">
                  <Checkbox checked={userPermsission.eventReportPerms} name={"eventReportPerms"} onChange={e => onPermsChange(e)}>
                    Editar informes
                  </Checkbox>
                </Popover>
              </Col>
            </Row>
            <Row justify="center">
              {/* 
             <Col align="middle" span={8}>
             <Popover content={
               <p>Poder editar base de datos</p>
              }
              title="Editar base de datos"
              trigger="hover">
              <Checkbox checked={userPermsission.dataBasePerms} name={"dataBasePerms"} onChange={e => onPermsChange(e)}>
              Editar Base de Datos
              </Checkbox>
              </Popover>
            </Col>
              <Col align="middle" span={8}>
                <Popover content={
                  <p>Poder editar información en el Dashboard</p>
                }
                  title="Editar dashboard"
                  trigger="hover">
                  <Checkbox checked={userPermsission.dashboardPerms} name={"dashboardPerms"} onChange={e => onPermsChange(e)}>
                    Editar Dashboard
                  </Checkbox>
                </Popover>
              </Col>
              <Col align="middle" span={8}>
                <Popover content={
                  <p>Poder editar categorias de tareas</p>
                }
                  title="Editar categorias"
                  trigger="hover">
                  <Checkbox checked={userPermsission.categoriesPerms} name={"categoriesPerms"} onChange={e => onPermsChange(e)}>
                    Editar categorías
                  </Checkbox>
                </Popover>
              </Col>
            </Row>*/}
          </div>
        }
        <Divider />

        <Row justify="center">
          <Col span={6}>
            <Button type="secondary" onClick={handleCancel} >
              Cancelar
            </Button>
          </Col>
          <Col offset={1} span={6}>
            <Form.Item align="middle" >
              <Button
                type="primary"
                htmlType="submit"
                style={{ backgroundColor: "#9a171f", borderColor: "#9a171f" }}
                disabled={button}
              >
                Actualizar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

    </React.Fragment >
  );
};

export default EditInformation;