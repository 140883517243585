import React, { useState, useEffect } from "react";
import {
  Button,
  Select,
  Table,
  Col,
  Row,
  DatePicker,
  Modal,
  Input,
  Divider,
  Space,
  Popconfirm,
  Checkbox,
  message,
} from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  LeftOutlined,
  RightOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import server, { endpoints } from "../utils/server";
import moment from "moment";
import AddUser from "../components/addUserWorkshops";
const { TextArea } = Input;
const { Option } = Select;
const Workshops = ({ idCycle }) => {
  useEffect(() => {
    getWorkshop();
    setAssistanceListNumber();
    getWorkshopOfOtherCycle();
    getWorkshopInCycle();
  }, []);

  const [assistanceArray, setAssistanceArray] = useState([]); // Variable para poder desplegar los asistentes en el modal de asistentes, se sirve del llamado a back getAttendance
  const [selectedIdSession, setSelectedIdSession] = useState(0); //Variable para proporcionar la sesion que se esta editando en donde se necesite
  const [messageApi, contextHolder] = message.useMessage();

  const [assistanceList, setAssistanceList] = useState(0);
  const [workshopName, setWorkshopName] = useState("");
  const [newWorkshopNameToInsert, setnewWorkshopNameToInsert] = useState();
  const [selectedDate, setSelectedDate] = useState(""); //Variable para poner la fecha seleccionada por el usuario al crear una nueva clase
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [newClassName, setNewClassName] = useState(""); //Variable para el nombre de una clase nueva
  const [workshopId, setWorkshopId] = useState(0); // Variable para proporcionar a los llamados de back con el Id de el taller seleccionado por el usuario
  const [participantArray, setParticipantArray] = useState([]); //Variable para poder desplegar los participantes en el modal de participantes, se sirve del llamado a back getUserInWorkshopPerCycle
  const [isClassesModalOpen, setIsClassesModalOpen] = useState(false); //setStates para abrir los modales. ClassesModal.- Nueva clase / AssistanceModal.- Asistencia / WorkshopsModal.- Nuevo taller / ParticipantModal .- Participantes
  const [isAssistanceModalOpen, setIsAssistanceModalOpen] = useState(false);
  const [isWorkshopsModalOpen, setIsWorkshopsModalOpen] = useState(false);
  const [isParticipantsModalOpen, setIsParticipantsModalOpen] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false); //Variable para desabilitar botones de agregar clase y participantes en caso de no haberse seleccionado un taller
  const [workshops, setWorkshops] = useState([]);
  const [newWorkshopName, setNewWorkshopName] = useState(""); //Variable usada para añadir un nuevo taller. Se usa en el modal3
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedNewWorkshopInCycle, setselectedNewWorkshopInCycle] =
    useState();
  const [temporaryArray, setTemporaryArray] = useState([]);
  const [newarray, setNewArray] = useState([]);
  const [participantName, setParticipantName] = useState();
  const [textClean, setTextClean] = useState(false);
  const [userInsert, setUserInsert] = useState([]);
  const [selectNewWorkshopVisible, setselectNewWorkshopVisible] =
    useState(false);

  //EndPoint//

  const getWorkshopSessions = async (idWorkshop) => {
    try {
      const res = await server(
        endpoints.getWorkshopSessions.route,
        endpoints.getWorkshopSessions.method,
        { id_workshop: idWorkshop, id_cycle: idCycle }
      );
      const normal = res.data;
      const reverted = normal.reverse();
      setSelectedValue(reverted);
    } catch (err) {
      message.error(err);
    }
  };
  
  const getUserInWorkshopPerCycle = async () => {
    const res = await server(
      endpoints.getUserInWorkshopPerCycle.route,
      endpoints.getUserInWorkshopPerCycle.method,
      { id_cycle: idCycle, id_workshop: workshopId }
    );
    if (res.error_message) {
      message.error(res.error_message);
    } else {
      setParticipantArray(res.data);
    }
  };

  const getAttendance = async (idSession) => {
    const res = await server(
      endpoints.getAttendance.route,
      endpoints.getAttendance.method,
      { id_session: idSession }
    );

    if (res.error_message) {
      message.error(res.error_message);
      return;
    } else {
      setAssistanceArray(res.data.attendance);
    }
  };

  const updateAttendance = async () => {
    const body = assistanceArray.map((e) => ({
      id_attendance: e.id_attendance || 0,
      id_user: e.id_user,
      attendance: e.attendance || 0,
    }));
    const res = await server(
      endpoints.updateAttendance.route,
      endpoints.updateAttendance.method,
      { id_session: selectedIdSession },
      { attendance: body }
    );
    if (res.error_message) {
      message.error(res.error_message);
      return;
    } else {
      message.success(res.message);
      getWorkshopSessions(workshopId);
      setIsAssistanceModalOpen(false);
    }
  };

  // const insertWorkshopUsers = async (firstName, lastName, email) => {
  //   try {
  //     const res = await server(
  //       endpoints.insertWorkshopUsers.route,
  //       endpoints.insertWorkshopUsers.method,
  //       { first_name: firstName, last_name: lastName, email: email }
  //     );
  //   } catch (err) {
  //     message.error(err);
  //   }
  // };

  const insertWorkshopsSessions = async () => {
    const res = await server(
      endpoints.insertWorkshopsSessions.route,
      endpoints.insertWorkshopsSessions.method,
      { id_workshop: workshopId, id_cycle: idCycle },
      {
        name: newClassName,
        start_date: selectedDate,
        end_date: selectedEndDate,
      }
    );
    if (res.error_message) {
      message.error(res.error_message);
      return;
    } else {
      message.success(res.message);
      getWorkshopSessions(workshopId);
    }
  };

  // const postWorkshopSessions = async (workshopId, cycleId) => {
  //   try {
  //     const res = await server(
  //       endpoints.postWorkshopSessions.route,
  //       endpoints.postWorkshopSessions.method,
  //       { id_workshop: workshopId, id_cycle: cycleId }
  //     );
  //   } catch (err) {
  //     message.error(err);
  //   }
  // };

  // const updateWorkshopSessions = async (workshopSessionId) => {
  //   try {
  //     const res = await server(
  //       endpoints.updateWorkshopSessions.route,
  //       endpoints.updateWorkshopSessions.method,
  //       { id_workshop_session: workshopSessionId }
  //     );
  //   } catch (err) {
  //     message.error(err);
  //   }
  // };

  const deleteWorkshopSessions = async (selectedIdSession) => {
    const res = await server(
      endpoints.deleteWorkshopSessions.route,
      endpoints.deleteWorkshopSessions.method,
      { id_workshop_session: selectedIdSession }
    );
    if (res.error_message) {
      message.error(res.error_message);
      return;
    } else {
      message.success(res.message);
      getWorkshopSessions(workshopId);
    }
  };

  const getWorkshopOfOtherCycle = async () => {
    const res = await server(
      endpoints.getWorkshopOfOtherCycle.route,
      endpoints.getWorkshopOfOtherCycle.method,
      { id_cycle: idCycle }
    );

    setworkshopsOtherCycles(res.data.workshopsOfOtherCycles);
  };

  const deleteWorkshopInCycle = async () => {
    const res = await server(
      endpoints.deleteWorkshopInCycle.route,
      endpoints.deleteWorkshopInCycle.method,
      { id_cycle: idCycle, id_workshop: selectedNewWorkshopInCycle }
    );

    if (res.error_message.length != 0) {
      message.error(res.message_error);
    } else {
      message.success(res.message);
    }
    getWorkshopInCycle();
    setWorkshopName();
    setselectedNewWorkshopInCycle();
    setSelectedValue();
    setDisableButtons(false);
  };

  //Handlers
  const handleOnAssistanceChange = (id) => {
    let auxArray = assistanceArray;
    const index = auxArray.map((e) => e.id_user).indexOf(id);
    if (auxArray[index].attendance === 1) {
      auxArray[index].attendance = 0;
    } else {
      auxArray[index].attendance = 1;
    }
    setAssistanceArray(auxArray);
    setAssistanceListNumber();
  };

  const handleSelectOnChange = (value, m) => {
    setselectedNewWorkshopInCycle(m.key);
    setWorkshopName(m.value);
    if (value !== "") {
      setDisableButtons(true); //Si un taller es seleccionado, y el valor que se manda desde el select (value) es diferente a "", entonces se pueden abilitar los botones para usarse
      setWorkshopId(m.key);
    }
    getWorkshopSessions(m.key);
  };
  const handleSelectOnChangeWorkshopToInsert = (value, m) => {
    setselectedNewWorkshopInCycle(m.key);
    setnewWorkshopNameToInsert(m.value);
    if (value !== "") {
      setDisableButtons(true); //Si un taller es seleccionado, y el valor que se manda desde el select (value) es diferente a "", entonces se pueden abilitar los botones para usarse
    }
  };

  const handleDatePickerOnChange = (e) => {
    setSelectedDate(e.valueOf().toString());
  };

  const handleEndDatePickerOnChange = (e) => {
    setSelectedEndDate(e.valueOf().toString());
  };

  const handleClassNameOnChange = (e) => {
    if (e.target.value !== "") {
      setNewClassName(e.target.value);
    }
  };

  //Setters

  const setAssistanceListNumber = () => {
    //Función que checa cuantos de los estudiantes estan presentes, pero solo para desplegar en la tabla
    var count = 0;
    for (let i = 0; i < assistanceArray.length; i++) {
      if (assistanceArray[i].attendance === true) {
        count++;
      }
    }
    setAssistanceList(count);
  };

  const defaultColumns = [
    {
      title: "Clase",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Fecha",
      dataIndex: "start_date",
      key: "date",
      align: "center",
      render: (date) => (
        <DatePicker
          showToday="true"
          showTime={{ format: "HH:mm" }}
          format="YYYY-MM-DD HH:mm"
          defaultValue={moment(date)}
        ></DatePicker>
      ),
    },
    {
      title: "Asistencias",
      dataIndex: "attendees",
      key: "attendees",
      align: "center",
      render: (attendees, element) => (
        <>
          <Button
            type="default"
            size="small"
            style={{ marginRight: "1rem", borderRadius: "15px" }}
            onClick={() => {
              setSelectedIdSession(element.id);
              getAttendance(element.id);
              showAssistanceModal();
            }}
          >
            Lista de asistencia
          </Button>
          {`${attendees}/${element.totalUsersInSession}`}
        </>
      ),
    },
    {
      title: "Acciones",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (key) => showDeleteConfirm(key),
    },
  ];

  const [paginationAssistanceList, setPaginationAssistanceList] = useState({
    pages: Math.ceil(assistanceArray.length / 10) + 1,
    itemCount: 10,
    currentPage: 1, //Define el numero de paginas maximo (5), limite de items por pagina (16), y la pagina en la que empieza al abrir la app (1) en la paginacion del AssistanceModal
  });

  const paginationArrayAssistanceList = () => {
    setTemporaryArray([]);
    const array_extra = [];
    const item_start =
      (paginationAssistanceList.currentPage - 1) *
      paginationAssistanceList.itemCount;
    const item_show =
      paginationAssistanceList.currentPage * paginationAssistanceList.itemCount;

    assistanceArray.map((i, index) => {
      if (index >= item_start && index < item_show) {
        array_extra.push(i);
      }
    });
    setTemporaryArray(array_extra);
  };

  useEffect(() => {
    paginationArrayAssistanceList();
  }, [assistanceArray, paginationAssistanceList]);

  const toNextPageAssistanceList = () => {
    if (
      assistanceArray.length >
      paginationAssistanceList.itemCount * paginationAssistanceList.pages
    )
      paginationAssistanceList.pages++;

    const nextPage = Math.min(
      paginationAssistanceList.currentPage + 1,
      paginationAssistanceList.pages
    );
    setPaginationAssistanceList({
      ...paginationAssistanceList,
      currentPage: nextPage,
    });
  };

  const toPrevPageAssistanceList = () => {
    const prevPage = Math.max(paginationAssistanceList.currentPage - 1, 1);

    setPaginationAssistanceList({
      ...paginationAssistanceList,
      currentPage: prevPage,
    });
  };

  const [paginationParticipants, setPaginationParticipants] = useState({
    pages: Math.ceil(participantArray.length / 9),
    itemCount: 9,
    currentPage: 1,
  });

  const toNextPageParticipants = () => {
    if (
      participantArray.length >
      paginationParticipants.itemCount * paginationParticipants.pages
    )
      paginationParticipants.pages++;
    const nextPage = Math.min(
      paginationParticipants.currentPage + 1,
      paginationParticipants.pages
    );
    setPaginationParticipants({
      ...paginationParticipants,
      currentPage: nextPage,
    });
  };
  const toPrevPageParticipants = () => {
    const prevPage = Math.max(paginationParticipants.currentPage - 1, 1);
    setPaginationParticipants({
      ...paginationParticipants,
      currentPage: prevPage,
    });
  };

  const paginationParticipantschange = () => {
    setNewArray([]);
    const array_temporal = [];
    const item_start =
      (paginationParticipants.currentPage - 1) *
      paginationParticipants.itemCount;
    const item_show =
      paginationParticipants.currentPage * paginationParticipants.itemCount;

    participantArray.map((i, index) => {
      if (index >= item_start && index < item_show) array_temporal.push(i);
    });

    setNewArray(array_temporal);
  };

  // UseEffect para recargar la lista de participants
  useEffect(() => {
    paginationParticipantschange();
  }, [participantArray, paginationParticipants]);

  // const addParticipant = () => {
  //   const error = () => {
  //     messageApi.open({
  //       type: "error",
  //       content: "Por favor introduzca un participante",
  //     });
  //   };
  //   if (participantName !== undefined && participantName !== "") {
  //     participantArray.push({
  //       key: participantArray.length + 1,
  //       name: participantName,
  //     });
  //     setPaginationParticipants({
  //       ...paginationParticipants,
  //       pages: Math.ceil(participantArray.length / 9),
  //     });
  //     message.success("Agregado exitosamente");
  //   } else {
  //     error();
  //   }
  // };

  const deleteUserInWorkshopPerCycle = async (idUser) => {
    const res = await server(
      endpoints.deleteUserInWorkshopPerCycle.route,
      endpoints.deleteUserInWorkshopPerCycle.method,
      { id_user: idUser, id_workshop: workshopId, id_cycle: idCycle }
    );
    if (res.error_message) {
      message.error(res.error_message);
      return;
    } else {
      message.success(res.message);
      getUserInWorkshopPerCycle();
    }
  };

  const getUsersOfOtherWorkshopPerCycle = async () => {
    const res = await server(
      endpoints.getUsersOfOtherWorkshopPerCycle.route,
      endpoints.getUsersOfOtherWorkshopPerCycle.method,
      { id_cycle: idCycle, id_workshop: selectedNewWorkshopInCycle }
    );
    if (res.error_message) {
      message.error(res.error_message);
    } else {
      setUserInsert(res.data.usersOfOtherWorkshopPerCycle);
    }
  };

  //Funcion para preguntar si el usuario si desea borrar la fila de la tabla. Se llama a handleDeleteConfirm para borrarla
  const showDeleteConfirm = (key) => {
    return (
      <Popconfirm
        title="¿Segur@ que quieres eliminar esta clase?"
        okText="Si"
        okType="danger"
        cancelText="Cancelar"
        onConfirm={() => {
          deleteWorkshopSessions(key);
        }}
      >
        <DeleteOutlined />
      </Popconfirm>
    );
  };

  const buttonStyle = {
    borderRadius: "10px",
    height: "3.15rem",
    width: "7.5rem",
  };
  const tableContainerStyle = {
    width: "90%",
    borderRadius: "15px",
    overflow: "hidden",
  };
  const selectBackground = {
    height: "76px",
    width: "360px",
    borderRadius: "28px",
    backgroundColor: "rgba(219, 219, 219, 0.34)",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  };
  const showClassesModal = () => {
    //showModal.- Hace que sea visible el modal especifico / handleOk y handleCancel.- Hace que el modal desaparezca, puesto que el usuario ya termino con el modal
    setIsClassesModalOpen(true);
  };
  const handleClassesModalCancel = () => {
    setIsClassesModalOpen(false);
  };
  const showAssistanceModal = () => {
    setIsAssistanceModalOpen(true);
  };
  const handleAssistanceModalOk = () => {
    setIsAssistanceModalOpen(false);
  };
  const handleAssistanceModalCancel = () => {
    setIsAssistanceModalOpen(false);
  };
  const showWorkshopsModal = () => {
    setIsWorkshopsModalOpen(true);
  };
  const handleWorkshopsModalCancel = () => {
    setIsWorkshopsModalOpen(false);
  };
  const handleParticipantsModalOk = () => {
    setIsParticipantsModalOpen(false);
  };
  const showParticipantsModal = () => {
    setIsParticipantsModalOpen(true);
  };
  const handleParticipantsModalCancel = () => {
    setIsParticipantsModalOpen(false);
  };
  ///////////////////////////////////////////////////////////////////////////////
  //Endpoints de clases

  // const insertWorkshop = async () => {
  //   const res = await server(
  //     endpoints.insertWorkshop.route,
  //     endpoints.insertWorkshop.method,
  //     {},
  //     { name: newWorkshopName }
  //   );
  // };

  const getWorkshop = async () => {
    const res = await server(
      endpoints.getWorkshop.route,
      endpoints.getWorkshop.method
    );
    if (res.error_message) {
      message.error(res.error_message);
      return;
    } else {
      setWorkshops(res.data.workshop);

      return;
    }
  };

  const [workshopsInCycle, setWorkshopsInCycle] = useState([]);
  const getWorkshopInCycle = async () => {
    const res = await server(
      endpoints.getWorkshopInCycle.route,
      endpoints.getWorkshopInCycle.method,
      { id_cycle: idCycle }
    );
    if (res.error_message) {
      message.error(res.error_message);
      return;
    } else {
      setWorkshopsInCycle(res.data.workshop);
    }
  };

  const insertWorkshopInCycle = async () => {
    const res = await server(
      endpoints.insertWorkshopInCycle.route,
      endpoints.insertWorkshopInCycle.method,
      { id_cycle: idCycle, id_workshop: selectedNewWorkshopInCycle },
      { name: newWorkshopName }
    );
    if (res.error_message.length != 0) {
      message.error(res.message_error);
    } else {
      message.success(res.message);
    }
    getWorkshopOfOtherCycle();
    getWorkshopInCycle();
    setselectedNewWorkshopInCycle();
    setWorkshopName();
    setnewWorkshopNameToInsert();
    setSelectedValue();
    setDisableButtons(false)
    
  };

  const dropdownRenderWorkshops = (menus) => (
    <div>
      {menus}
      <Divider style={{ margin: 0 }} />
      <div style={{ padding: 8 }}>
        <Input
          placeholder="Nombre de la nueva clase"
          onChange={(e) => {
            setNewWorkshopName(e.target.value);
            setselectedNewWorkshopInCycle(0);
          }}
          style={{ width: "55%" }}
        />
        <Button
          type="link"
          onClick={() => {
            insertWorkshopInCycle();
            setIsWorkshopsModalOpen(false);
            setselectNewWorkshopVisible(false);
          }}
        >
          Agregar
        </Button>
      </div>
    </div>
  );

  const dropdownRender = (menus) => (
    <div>
      <div style={{ padding: 8 }}>
        <Button
          type="link"
          onClick={() => {
            showWorkshopsModal();
          }}
        >
          Agregar taller {<PlusOutlined />}
        </Button>
        {menus}
      </div>
    </div>
  );
  const [workshopsOtherCycles, setworkshopsOtherCycles] = useState([]);

  return (
    <>
      {contextHolder}
      <Col style={{
            display:"flex",
            flexDirection:"column",
          }}>
        <Row
          justify="space-between"
          align="middle"
          style={{
            alignSelf:"center",
            width:"90%",
            marginTop:"3%"
          }}
        >
          <Row
            style={{
              display: "flex",
            }}
          >
            <div style={selectBackground}>
              <Space style={{gap:"30px"}}>
                <h3 style={{ float: "left", fontSize:"16px"}}>Taller: </h3>
                <Select
                  style={{
                    width: "200px",
                    height:"30px",
                    float: "left",
                  }}
                  dropdownRender={dropdownRender}
                  placeholder="Seleccionar"
                  onChange={(e, m) => handleSelectOnChange(e, m)}
                  value={workshopName}
                  showSearch
                >
                  {workshopsInCycle.map((t) => {
                    //Mapeo para mostrar las opciones con back en el select de talleres
                    return (
                      <Option key={t.id} value={t.name}>
                        {t.name}
                      </Option>
                    );
                  })}
                </Select>
              </Space>
            </div>

            <h1 style={{ float: "left", marginLeft: "4rem" }}>
              {workshopName}
            </h1>
          </Row>
          <Divider style={{
              backgroundColor:"black"
            }}/>
          <Row
            style={{
              display: "flex",
              width: "100%",
              alignItems:"center",
              paddingLeft:"1%"
            }}
          >
            <Popconfirm
              title="¿Segur@ que quieres eliminar este taller?"
              okText="Si"
              okType="danger"
              cancelText="Cancelar"
              onConfirm={() => {
                if (selectedNewWorkshopInCycle > 0) {
                  deleteWorkshopInCycle();
                } else message.error("Seleccione un taller primero");
              }}
            >
              <Button
                danger
                style={{
                  borderRadius: "10px",              
                }}
                disabled={!disableButtons}
              >
                Eliminar del ciclo
              </Button>
            </Popconfirm>
            <Button
              onClick={() => showClassesModal()}
              type="primary"
              style={{
                borderRadius: "20px",
                height: "50px",
                width: "194px",
                marginRight: "2rem",
                marginLeft: "30%",
              }}
              disabled={!disableButtons}
            >
              Agregar Clase
            </Button>
            {/*Modal de clases */}
            <Modal
              title="Agregar Clase"
              visible={isClassesModalOpen}
              onOk={() => {
                insertWorkshopsSessions();
                setIsClassesModalOpen(false);
              }} //////////////////////////////////////////////////////
              onCancel={handleClassesModalCancel}
            >
              <div>
                <div style={{ display: "flex" }}>
                  <h3>Clase: </h3>
                  <Input
                    placeholder="Ensayo 01"
                    onChange={handleClassNameOnChange}
                  />
                </div>
                <Divider style={{ borderTop: "#000000" }} />
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <h3 style={{ marginRight: "20px" }}>Fecha de inicio: </h3>
                  <DatePicker
                    showTime={{ format: "HH:mm" }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={(_, e) => handleDatePickerOnChange(e)}
                  ></DatePicker>
                </div>
                <div style={{ display: "flex" }}>
                  <h3 style={{ marginRight: "40px" }}>Fecha de fin: </h3>
                  <DatePicker
                    showTime={{ format: "HH:mm" }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={(_, e) => handleEndDatePickerOnChange(e)}
                  ></DatePicker>
                </div>
              </div>
            </Modal>
            <Button
              style={buttonStyle}
              onClick={() => {
                getUserInWorkshopPerCycle();

                showParticipantsModal();
                getUsersOfOtherWorkshopPerCycle();

                paginationAssistanceList.currentPage = 1;
              }}
              disabled={!disableButtons}
            >
              Participantes
            </Button>
            {/*Modal de participantes */}
            <Modal
              title="Participantes"
              visible={isParticipantsModalOpen}
              onOk={handleParticipantsModalOk}
              onCancel={handleParticipantsModalCancel}
              okText="Aceptar"
              cancelText="Cancelar"
              destroyOnClose={true}
              width={800}
              height={500}
            >
              <Row>
                <Col span={12} align="center" justifycontent="center">
                  <div>
                    {newarray.map((i, index) => {
                      return (
                        <div style={{ width: "70%" }} key={index}>
                          <div style={{ display: "flex" }}>
                            <h3>
                              {index +
                                1 +
                                (paginationParticipants.currentPage - 1) *
                                  paginationParticipants.itemCount}
                              . - {i.first_name} {i.last_name}
                            </h3>
                            <Popconfirm
                              title="Eliminar Participante"
                              description="Seguro que quiere eliminar a este participante?"
                              key={i.id_user}
                              onConfirm={() => {
                                deleteUserInWorkshopPerCycle(i.id_user);
                              }}
                              okText="Si"
                              cancelText="No"
                            >
                              <DeleteOutlined
                                style={{
                                  marginLeft: "auto",
                                }}
                              />
                            </Popconfirm>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "0%",
                      marginLeft: "38%",
                    }}
                  >
                    <button onClick={toPrevPageParticipants}>
                      <LeftOutlined />
                    </button>
                    <span>
                      {paginationParticipants.currentPage} /
                      {Math.ceil(participantArray.length / 9)}
                    </span>
                    <button onClick={toNextPageParticipants}>
                      <RightOutlined />
                    </button>
                  </div>
                </Col>
                <Divider
                  type="vertical"
                  style={{
                    height: "auto",
                  }}
                />
                <Col span={11}>
                  <Row
                    style={{ height: "10%", alignItems: "center" }}
                    align="center"
                  >
                    <div style={{ fontSize: "20px", fontWeight: "600" }}>
                      Total: {participantArray.length}
                    </div>
                  </Row>
                  <Divider />
                  <Row align="center">
                    <h3 align="center">Agregar a un participante</h3>
                    <Divider />
                    <AddUser
                      selectedNewWorkshopInCycle={selectedNewWorkshopInCycle}
                      idCycle={idCycle}
                      usersToInsert={userInsert}
                      onHandleCancel={getUserInWorkshopPerCycle}
                      onHandleAdded={getUsersOfOtherWorkshopPerCycle}
                    />
                  </Row>
                </Col>
              </Row>
            </Modal>
          </Row>
        </Row>
        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: "2%",
          }}
        >
          <div style={tableContainerStyle}>
            <Table
              scroll={{ x: 200 }}
              columns={defaultColumns}
              dataSource={selectedValue}
              bordered={true}
              size="middle"
              style={{ alignItems: "center" }}
            />
          </div>
        </Row>
        {/*Modal de lista de asistencia, se accede desde la tabla */}
        <Modal
          title="Lista de asistencia"
          visible={isAssistanceModalOpen}
          onOk={handleAssistanceModalOk}
          onCancel={handleAssistanceModalCancel}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", width: "500px" }}>
            {temporaryArray.map((i, index) => {
              return (
                <div key={index}>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "-25px",
                      marginTop: "-25px",
                      justifyContent: "left",
                      alignItems: "center",
                      width: "80%",
                    }}
                  >
                    <Checkbox
                      checked={i.attendance}
                      onChange={() => {
                        handleOnAssistanceChange(i.id_user);
                        paginationArrayAssistanceList();
                      }}
                      style={{
                        marginLeft: "30%",
                      }}
                    />
                    <h3
                      style={{
                        marginLeft: "20%",
                      }}
                    >
                      {index +
                        1 +
                        (paginationAssistanceList.currentPage - 1) *
                          paginationAssistanceList.itemCount}
                      .- {i.first_name} {i.last_name}
                    </h3>
                  </div>
                  <Divider style={{ size: "100%" }} />
                </div>
              );
            })}

            <div style={{ justifyContent: "center", alignItems: "center" }}>
              <button onClick={toPrevPageAssistanceList}>
                <LeftOutlined />
              </button>
              <>
                {paginationAssistanceList.currentPage} /{" "}
                {paginationAssistanceList.pages}
              </>
              <button onClick={toNextPageAssistanceList}>
                <RightOutlined />
              </button>
            </div>
            <div style={{ justifyContent: "right", alignItems: "right" }}>
              <Button
                type="primary"
                style={{ marginRight: "20px" }}
                onClick={() => {
                  updateAttendance();
                }}
              >
                Actualizar
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          title="Agregar Taller al ciclo"
          visible={isWorkshopsModalOpen}
          okText="Agregar"
          cancelText="Cancelar"
          onOk={() => {
            setIsWorkshopsModalOpen(false);
            insertWorkshopInCycle();
          }} //Ya que se haya actualizado workshopName, se le da al arreglo de options. Value se añade tomando el length del array y sumandole uno para que siempre este correcto (Si length es 3, el nuevo valor será length+1). Tambien cierra el modal, por lo que no tiene un "handleOk3"
          onCancel={handleWorkshopsModalCancel}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col>
              <Row>
                <h3>Taller a agregar </h3>
              </Row>
              <Row>
                <Select
                  open={selectNewWorkshopVisible}
                  style={{
                    width: "200px",
                  }}
                  dropdownRender={dropdownRenderWorkshops}
                  placeholder="Seleccionar"
                  onChange={(e, m) => handleSelectOnChangeWorkshopToInsert(e, m)}
                  value={newWorkshopNameToInsert}
                  onDropdownVisibleChange={(visible) =>
                    setselectNewWorkshopVisible(visible)
                  }
                  showSearch
                >
                  {workshopsOtherCycles.map((t) => {
                    //Mapeo para mostrar las opciones con back en el select de talleres
                    return (
                      <Option key={t.id} value={t.name}>
                        {t.name}
                      </Option>
                    );
                  })}
                </Select>
              </Row>
            </Col>
          </div>
        </Modal>
      </Col>
    </>
  );
};

export default Workshops;