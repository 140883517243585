import React from "react";
import { Row, Col } from "antd";
import TeamMemberCard from "./../components/teamMemberCard";

const TeamTable = (props) => {
  const { members, onDelete, onTasksReportClick, userCanEdit, onEditInformation, onSwitchTeam, userCanSwitch } = props;
  if (members.length > 0) {
    return (
      <React.Fragment>
        <Col
          span={22}
          style={{
            borderColor: "white",
          }}
        >
          <Row justify="center" gutter={[24, 16]}>
            {members.map((m) => (
              <Col key={m.id} span={6}>
                <TeamMemberCard
                  member={m}
                  onEditInformation={onEditInformation}
                  onDelete={onDelete}
                  onTasksReportClick={onTasksReportClick}
                  userCanEdit={userCanEdit}
                  idUR={props.idUR}
                  onSwitchTeam={onSwitchTeam}
                  userCanSwitch={userCanSwitch}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </React.Fragment>
    );
  } else {
    return (
      <Col
        span={22}
        style={{
          borderStyle: "solid",
          borderWidth: "0.5px",
          borderRadius: "10px",
          borderColor: "#e6e6e6",
          height: "67vh",
        }}
      >
        <Row
          justify="center"
          align="center"
          gutter={[24, 16]}
          style={{ height: "67vh" }}
        >
          <Col>
            <div style={{ fontStyle: "oblique", color: "#999999" }}>
              No hay miembros
            </div>
          </Col>
        </Row>
      </Col>
    );
  }
};

export default TeamTable;
