import React, { useState, useEffect, useRef  } from 'react';
import axios from "axios";
import { rootEndpoint } from "../utils/constants";
import server, { endpoints } from '../utils/server';

import{
  Row,
  Col,
  Button,
  Form,
  Select,
  message,
}from "antd";



const SwitchTeam =(props)=>{

  const {id, idUR , id_cycle, id_teams, id_user, originPage, perms, members, setMembers} = props;
  const [teams, setTeams, ] = useState([])
  var [teamUpdate]= useState(false)
  const [idTeamDestiny, setIdTeamDestiny] = useState();
  const { Option } = Select;
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    getTeams();
  }, );

  
  const getTeams = async () => {
    const params = {
      id_cycle,
      id_user,
    };
    const res = await server(endpoints.getNotUserTeams.route, endpoints.getNotUserTeams.method, params);
    const dataTeams = res.data;
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
    }
    else{
     setTeams(dataTeams);
    }
    };

  const handleCancel = async () => {
    if (originPage === "teams") {
      props.onCancel();
    }
    if (originPage === "addMember") {
      props.onHandleCancel();
    }
    if (originPage === "perfil") {
      props.handleCancelProfile();
    }
  }
  
  const toggleIsLoading = () => {
    
    setIsLoading(current => !current);
  };


 const changeTeam = async () => {
  const body = {
    id_team_origin: id_teams,
    id_team_destiny: idTeamDestiny
  }

  const params = {
    id_user,
    id_cycle,
  }

  const res = await server(endpoints.switchUserOfTeam.route, endpoints.switchUserOfTeam.method, params, body);
  const { error_message } = res;
  if (error_message) {
    message.error(error_message);
    handleCancel()
  }
  else{ 
    message.success("Informacion Actualizada exitosamente");
    handleCancel()
    const index = members.findIndex((user) => user.id === id_user);
    members.splice(index, 1);
    setMembers(members);
  }
 };

 const isFirstRender = useRef(true);
 
 useEffect(() => {
  if (isFirstRender.current) {
    isFirstRender.current = false;
    return; 
  }
  changeTeam()
}, [isLoading]);

    return(
        <React.Fragment>
    <Select
      placeholder="Equipos"
      style={{
        width: 120,
      }}
      onChange={setIdTeamDestiny}
    >
      {teams &&
        teams.map((team) => (
          <Option key={team.id} value={team.id}>{team.name}</Option>  
        ))
      }
    </Select >
    {/* findIndex
    splice */}
 <Row  justify="center" style={{marginTop:"20px"}}>
 
          <Col span={6}>
            <Button  onClick={handleCancel} type="secondary"  >
              Cancelar
            </Button>
          </Col>
          <Col offset={1} span={6}>

            <Form.Item align="center" >
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                style={{ backgroundColor: "#9a171f",  borderColor: "#9a171f" }}
                onClick={()=>toggleIsLoading()}
              >
                Actualizar
              </Button>
              
            </Form.Item>
            
          </Col>   
        </Row>
        </React.Fragment>
    
    );
}
export default SwitchTeam;