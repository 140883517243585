import React, { useState, Fragment, useEffect } from "react";
import { Input, Select, Button, Form, Row, Col, Upload, message, Typography } from "antd";
import { rootEndpoint, filesSizeLimitMB } from "./../utils/constants";
import axios from "axios";
import useWindowDimensions from "../utils/useWindowDimensions";
import { widthMobile } from "./../utils/constants";
import server, { endpoints } from "../utils/server";

const { Text, Title } = Typography;


const Help = (props) => {

  const [type, setType] = useState("")
  const [helpMessage, setHelpMessage] = useState("")
  const [fileContens, setFileContens] = useState("")
  const [fileName, setFileName] = useState("")
  const [options, setOptions] = useState([])
  const [optionsLoaded, setOptionsLoaded] = useState(false)
  const [fileList, setFileList] = useState([])
  const [sendingRequest, setSendingRequest] = useState(false)
  const { width } = useWindowDimensions();
  const isMobile = width < widthMobile;

  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    getOptions();
  }, [])


  const getOptions = async () => {
    const res = await server(endpoints.helpTypes.route, endpoints.helpTypes.method);
    const options = res.data;
    setOptions(options);
    setOptionsLoaded(true)
  }

  const onReset = () => {
    form.resetFields();
    setFileList([]);
  };

  const onSubmit = async (e) => {
    if (!type || !helpMessage) {
      message.error("Llena todos los campos por favor");
      return;
    }
    try {
      setSendingRequest(true);
      const body = {
        type: type, message: helpMessage, fileContents: fileContens, fileName: fileName
      }
      const data = await server(endpoints.insertHelpRequest.route, endpoints.insertHelpRequest.method, {}, body);
      const { error_message } = data;
      const { message: successMessage } = data;
      if (error_message) {
        message.error(error_message);
        return;
      }
      onReset();
      message.success(successMessage);
      setSendingRequest(false);
    } catch (error) {
      message.error(error);
    }
  };

  const encodeFile = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = (error) => reject(error);
    });

  const beforeUpload = async (file) => {
    let converted_file = await encodeFile(file);
    const isSizeValid = file.size / 1000000 < filesSizeLimitMB;
    if (!isSizeValid) {
      return message.error(`El tamaño del archivo debe ser menor a ${filesSizeLimitMB}MB`);
    }
    setFileContens(converted_file);
    setFileName(file.name);
  };

  const handleFileChange = info => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    if (fileList) {
      fileList[0].status = "done";
    }
    setFileList(fileList)
  }

  return (
    <Fragment>
      <Row justify="center">
        <Row justify="center" style={{ width: "100%" }}>
          <Title level={4}>Ayuda</Title>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Text type="secondary">Haznos saber en qué te podemos ayudar</Text>
        </Row>
      </Row>

      <Row justify="center">
        <Col span={isMobile ? 20 : 6}>
          <Form
            form={form}
            layout="vertical"
            onSubmitCapture={onSubmit}
          >
            <Form.Item
              label="¿Qué necesitas?"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese qué tipo de ayuda necesita",
                },
              ]}
            >
              {
                optionsLoaded &&
                <Select
                  labelInValue
                  onChange={(value) => { setType(value.key) }}
                  placeholder="--Seleccione--"
                >
                  {
                    options.map(option => (
                      <Option value={option.value} key={option.value}>{option.label}</Option>
                    ))
                  }
                </Select>
              }
            </Form.Item>
            <Form.Item
              label="Cuéntanos más"
              name="message"
              rules={[
                {
                  required: true,
                  message: "Por favor agregue una descripción",
                }
              ]}
            >
              <Input.TextArea
                onChange={e => setHelpMessage(e.target.value)}
                value={helpMessage}
                rows={4}
              />
            </Form.Item>
            <div style={{ textAlign: "center" }}>
              <Form.Item
                valuePropName="fileList"
                extra="Adjunte un archivo en caso de ser necesario"
              >

                <Upload
                  multiple={false}
                  beforeUpload={beforeUpload}
                  fileList={fileList}
                  onChange={handleFileChange}
                >
                  <Button >Adjuntar</Button>
                </Upload>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: "#9a171f", borderColor: "#9a171f" }}
                  loading={sendingRequest}
                >
                  Enviar
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
}

export default Help;
