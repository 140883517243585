import React, { Component } from "react";
// importando Generales
import axios from "axios";
import { rootEndpoint } from "../utils/constants";
import Loading from "./loading";
import TasksTable from "./tasksTable";
// componentes de AntD
import {
  Row,
  Col,
  Select,
  Button,
  Pagination,
  Modal,
  message,
  Popconfirm,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";

//Componentes para team table
import { paginate } from "../utils/pagination";
import TeamTable from "./teamTable";
import AddTeam from "./addTeam";
//componentes de Modales
import AddMember from "./addMember";
import EditInformation from "./editUserInformation";
import SwitchTeam from "./switchTeam";
import TeamInfoTable from "./teamInfoTable";
import server, { endpoints } from "../utils/server";

class Teams extends Component {
  state = {
    members: [],
    currentPage: 1,
    pageSize: 30,
    idMemberToDelete: -1,
    idSelectedTeam: "Seleccionar",
    idSelecteMember: "allMembers",
    teamSelected: false,
    modalVisible: false,
    teamsRefreshed: false,
    usersToInsert: [],
    selectedMember: 0,
    modalInformationVisible: false,
    modalTeamInformationVisible: false,
    modalAddMemberVisible: false,
    modalBitacoraVisible: false,
    modalInfoVisible: false,
    reload: false,
    userCanSwitch: false,
  };

  // Manejo de la tabla de Teams, su paginacion y sus cambios
  getTeamUsers = (idSelectedTeam) => {
    const { idSelectedCycle } = this.props;
    const params = {
      id_cycle: idSelectedCycle,
      id_team: idSelectedTeam,
    };
    server(endpoints.getTeamUsers.route, endpoints.getTeamUsers.method, params)
      .then((data) => {
        const { error_message } = data;
        if (!error_message) {
          this.setState({
            members: data.data.users,
            userCanEdit: data.data.user_can_edit,
            userCanSwitch: data.data.user_can_switch_users,
          });
        }
      });
  };

  componentDidMount() {
    const { teams } = this.props;
    if (teams.length > 0) {
      this.handleSelectTeam(teams[0].id);
      this.getTeamUsers(teams[0].id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.teams !== prevProps.teams) {
      if (this.props.teams.length > 0) {
        this.handleSelectTeam(this.props.teams[this.props.teams.length - 1].id);
        this.getTeamUsers(this.props.teams[this.props.teams.length - 1].id);
      } else this.setState({ teamSelected: true });
    }
    const { idSelectedTeam } = this.state;
    if (
      idSelectedTeam !== prevState.idSelectedTeam &&
      idSelectedTeam !== "createTeam"
    ) {
      this.getTeamUsers(idSelectedTeam);
    }
  }

  handleMemberEdition() {
    const { teams } = this.props;
    if (teams.length > 0) {
      this.handleSelectTeam(teams[0].id);
      this.getTeamUsers(teams[0].id);
    }
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSelectTeam = (idTeam) => {
    this.setState({ idSelectedTeam: idTeam, currentPage: 1 }, () => {
      this.setState({ teamSelected: true });
    });
    if (this.state.memberSelected === true) {
      this.setState({ memberSelected: false, idSelecteMember: "allMembers" });
    }
  };

  handleSelectMember = (idMember) => {
    this.setState({ idSelecteMember: idMember, currentPage: 1 }, () => {
      if (idMember === "allMembers") {
        this.setState({ memberSelected: false });
      }
      else {
        this.setState({ memberSelected: true });
      }
    });
  };

  renderTeams = () => {
    const { Option } = Select;
    const { teams } = this.props;
    if (teams.length > 0) {
      return teams.map((t) => (
        <Option key={t.id} value={t.id}>
          {t.name}
        </Option>
      ));
    } else
      return (
        <Option key="disabled1" value="disabled1" disabled>
          Sin equipos
        </Option>
      );
  };


  renderOtherTeams = () => {
    const { Option } = Select;
    const { otherTeams } = this.props;
    if (otherTeams.length > 0) {
      return otherTeams.map((t) => (
        <Option key={t.id} value={t.id}>
          {t.name}
        </Option>
      ));
    } else
      return (
        <Option key="disabled2" value="disabled2" disabled>
          Sin equipos
        </Option>
      );
  };

  renderMembers = () => {
    const { Option } = Select;
    const { members } = this.state;
    if (members.length > 0) {
      return members.map((member) => (
        <Option key={member.id} value={member.id}>
          {member.first_name}
        </Option>
      ));
    } else
      return (
        <Option key="disabled1" value="disabled1" disabled>
          Sin miembros
        </Option>
      );
  };


  renderPagination = () => {
    const { members, pageSize } = this.state;
    if (members.length > pageSize) {
      return (
        <Pagination
          defaultCurrent={1}
          total={members.length}
          pageSize={pageSize}
          onChange={this.handlePageChange}
        />
      );
    } else return <Row></Row>;
  };

  onDeleteTeam = async () => {
    const { idSelectedTeam } = this.state;
    const { idUR, idSelectedCycle } = this.props;
    const params = {
      id_cycle: idSelectedCycle,
      id_team: idSelectedTeam,
    }
    const { error_message } =
      await server(endpoints.deleteTeamFromCycle.route, endpoints.deleteTeamFromCycle.method, params);
    if (error_message) {
      message.error(error_message);
    } else {
      message.success("Equipo eliminado correctamente del ciclo", 3);
    }
    window.location.reload(true);
  };

  onKeepTeam = () => { };

  onTeamInsert = async (newTeamName, idNewTeam, idParentTeam) => {
    const { idSelectedCycle } = this.props;
    const body = {
      name: newTeamName,
      id_parent_team: idParentTeam,
    };
    const params = {
      id_team: idNewTeam,
      id_cycle: idSelectedCycle,
    }

    const res = await server(endpoints.insertTeam.route, endpoints.insertTeam.method, params, body);
    const { error_message, data } = res;
    if (error_message) {
      message.error(error_message);
      return;
    }
    message.success(res.message);
    const idSelectedTeam = data.id;
    await this.props.refreshTeams();
    this.setState({ idSelectedTeam });
  };

  //TEAMS CONDITIONAL RENDERING
  getTeamsContent = () => {
    const {
      idSelectedTeam,
      idSelecteMember,
      members,
      pageSize,
      currentPage,
      teamSelected,
      memberSelected,
      userCanEdit,
      userCanSwitch,
    } = this.state;
    const { teams, otherTeams, idSelectedCycle } = this.props;
    let membersList;

    if (teams.length === 0 && !teamSelected) {
      return "No tienes equipos. Crea tu primer equipo";
    }
    if (memberSelected) {
      const member = members.filter(m => m.id === idSelecteMember);
      membersList = paginate(member, currentPage, pageSize);
    } else {
      membersList = paginate(members, currentPage, pageSize);
    }

    return teamSelected ?
      (
        idSelectedTeam !== "createTeam"
          ?
          <TeamTable
            members={membersList}
            onDelete={this.handleDeleteMember}
            onEditInformation={this.showModalEditInformation}
            onTasksReportClick={this.showModalTasksReport}
            onSwitchTeam={this.showModalSwitchTeam}
            idUR={this.props.idUR}
            userCanEdit={userCanEdit}
            userCanSwitch={userCanSwitch}
            originPage={"teams"}
          />
          :
          <AddTeam
            onTeamInsert={this.onTeamInsert}
            teams={teams}
            otherTeams={otherTeams}
            idSelectedCycle={idSelectedCycle}
          />
      )
      : (
        <Loading />
      );
  };

  // MANJEO DE ELIMINAR UN MIEMBRO DEL EQUIPO
  handleDeleteMember = async (memberId) => {
    var { members, idSelectedTeam } = this.state;
    const { idSelectedCycle, idUR } = this.props;
    var ogMembers = members;
    const params = {
      id_cycle: idSelectedCycle,
      id_team: idSelectedTeam,
      id_user: memberId
    }
    server(endpoints.deleteUserFromTeam.route, endpoints.deleteUserFromTeam.method, params)
      .then(( data ) => {
        const { error_message } = data;
        if (error_message) {
          message.error(`No se eliminó. ${error_message}`, 2);
          this.setState({ members: ogMembers });
        } else {
          message.success("Usuario eliminado", 2);
          const newMembers = data.data;
          this.setState({ members: newMembers });
          if (newMembers.length % 10 === 0) {
            this.setState({ currentPage: this.state.currentPage - 1 });
          }
        }
      });
  };

  //******MANEJO DE LOS MODALES DE LA VISTA */
  // MANEJO DEL MODAL DE AGREGAR MIEMBRO
 
  showModalAddMember = () => {
    const { modalAddMemberVisible } = this.state;
    if (modalAddMemberVisible) {
      this.setState({
        buttonClicked: null,
        modalAddMemberVisible: false,
      });
      return;
    }
    this.setState({
      buttonClicked: "addMember",
      modalAddMemberVisible: true,
    });
    this.findUsersToInsert();
  };

  handleAddMemberOk = () => {
    this.setState({ modalAddMemberVisible: false });
  };

  handleAddMemberCancel = () => {
    this.setState({ modalAddMemberVisible: false });
  };

  findUsersToInsert = async () => {
    var { idSelectedTeam } = this.state;
    const { idSelectedCycle } = this.props;
    const params = {
      id_team: idSelectedTeam,
      id_cycle: idSelectedCycle,
    };
    const res = await server(endpoints.getUsersToInsert.route, endpoints.getUsersToInsert.method, params);

    var users = res.data;
    const newMembers = users.filter((user) => user.can_be_inserted === true);
    this.setState({ usersToInsert: newMembers });
  };
   /////Modal informes del equipo//////////////////////////////////////////////////////////////////////////////////////////////////////////
   showModalTeamInformation = () => {
    const { modalTeamInformationVisible } = this.state;
    if (modalTeamInformationVisible) {
      this.setState({
        buttonClicked: null,
        modalTeamInformationVisible: false,
      });
      return;
    }
    this.setState({
      buttonClicked: "teamInfo",
      modalTeamInformationVisible: true,
    });
  };
  handleTeamInformationCancel = () => {
    this.setState({ modalTeamInformationVisible: false });
  };

  //MODAL CAMBIO DE EQUIPO
  showModalSwitchTeam =(memberId) => {
    //const member = this.state.members.find((m) => m.id === memberId);
    //var { idSelectedTeam } = this.state;
    this.setState({
      modalWith: 1300,
      buttonClicked: "switchTeam",
      modalTitle: `Cambio de equipo`,
      modalSwitchTeamVisible: true,
      
      modalFooter: true,
      idSelectedMember: memberId,
      
    }); 
  }
  handleSwitchTeamOk = () => {
    this.setState({ modalInfoVisible: false });
    this.handleMemberEdition();
  };

  handleSwitchTeamCancel = () => {
    this.setState({ modalSwitchTeamVisible: false });
  };

  // MODAL BITACORA DE MIEMBRO
  showModalTasksReport = (memberId) => {
    const member = this.state.members.filter((m) => m.id === memberId)[0];
    this.setState({
      modalWith: 1300,
      buttonClicked: "tasksReport",
      modalTitle: `Cambio de ${member.first_name} ${member.last_name}`,
      modalTasksReportVisible: true,
      modalFooter: true,
      idSelectedMember: memberId,
    });
  };

  handleTasksReportOk = () => {
    this.setState({ modalBitacoraVisible: false });
    this.handleMemberEdition();
  };

  handleTasksReportCancel = () => {
    this.setState({ modalTasksReportVisible: false });
  };

  // MODAL EDITAR INFORMACION
  showModalEditInformation = async (memberId) => {
    var { idSelectedTeam } = this.state;
    const { idSelectedCycle } = this.props;
    const member = this.state.members.filter((m) => m.id === memberId)[0];

    const params = {
      id_user: member.id,
      id_team: idSelectedTeam,
      id_cycle: idSelectedCycle,
    }
    const res = await server(endpoints.getUserToInviteInfo.route, endpoints.getUserToInviteInfo.method, params);
    this.setState({
      buttonClicked: "editInformation",
      modalTitle: `Editar información de ${member.first_name} ${member.last_name}`,
      modalEditInformationVisible: true,
      selectedMember: res.data,
      idSelectedMember: memberId,
    });
  };
  handleEditInformationOk = () => {
    const { idSelectedTeam } = this.state;
    this.setState({ modalEditInformationVisible: false });
    this.getTeamUsers(idSelectedTeam);
  };

  handleEditInformationCancel = () => {
    this.setState({ modalEditInformationVisible: false });
  };

  //RENDER CONDITIONAL MODAL
  renderConditionalModals = (buttonClicked) => {
    const {
      selectedMember,
      modalEditInformationVisible,
      modalAddMemberVisible,
      modalTeamInformationVisible,
      modalTasksReportVisible,
      modalSwitchTeamVisible,
      idSelectedTeam,
      usersToInsert,
      modalTitle,
      idSelectedMember,
      userCanEdit,
    } = this.state;
    const { idSelectedCycle, idUR } = this.props;
    /*let teamName;
    for(let x = 0; x < teams.length; x++){
      if(teams[x].id === idSelectedTeam){
        teamName = teams[x].name;
      }
    }
    for(let x = 0; x < otherTeams.lenght; x++){
      if(otherTeams[x].id === idSelectedTeam){
        teamName = otherTeams[x];
      }
    }*/
    switch (buttonClicked) {
      case "switchTeam":
      return (
        <Modal
          visible={modalSwitchTeamVisible}
          width={500}
          title={modalTitle}
          footer={null}
          style={{ textAlign: "center" }}
          onOk={this.handleSwitchTeamOk}
          onCancel={this.handleSwitchTeamCancel}
          destroyOnClose={true}
        >
          <SwitchTeam
        idUR={idUR}
        id_user={idSelectedMember}
        id_cycle={idSelectedCycle}  
        id_teams={idSelectedTeam}
        userCanEdit={userCanEdit}
         user={selectedMember.user_info}
         perms={selectedMember.permissions}
         onCancel={this.handleSwitchTeamCancel}
         onOk={this.handleSwitchTeamOk}
         originPage={"teams"}
         members={this.state.members}
         setMembers={(members) => this.setState({ members })}
          />
        </Modal>
      );
      

      case "teamInfo":
        return (
          <Modal
            visible={modalTeamInformationVisible}
            title={"Informe de bitacora" /* + teamName*/}
            width={"85%"}
            
            style={{ textAlign: "center" }}
            onCancel={this.handleTeamInformationCancel}
            destroyOnClose={true}
            footer={null}
          > 
          <TeamInfoTable 
            id_team={idSelectedTeam} 
            id_cycle={idSelectedCycle} 
            idUR={idUR}
          />

          </Modal>
        );
      case "addMember":
        return (
          <Modal
            visible={modalAddMemberVisible}
            title="Actualizando Equipo"
            width={500}
            footer={null}
            style={{ textAlign: "center" }}
            onOk={this.handleAddMemberOk}
            onCancel={this.handleAddMemberCancel}
            destroyOnClose={true}
          >
            <AddMember
              idTeam={idSelectedTeam}
              idSelectedCycle={idSelectedCycle}
              onHandleCancel={this.handleAddMemberCancel}
              idUR={idUR}
              userCanEdit={userCanEdit}
              usersToInsert={usersToInsert}
              onMemberAdded={this.handleMemberAdded}
            />
          </Modal>
        );
      case "editInformation":
        return (
          <Modal
            visible={modalEditInformationVisible}
            title={modalTitle}
            width={500}
            footer={null}
            style={{ textAlign: "center" }}
            onOk={this.handleEditInformationOk}
            onCancel={this.handleEditInformationCancel}
            okText={"Enviar"}
            destroyOnClose={true}
          >
            <EditInformation
              idUR={idUR}
              id={idSelectedMember}
              idTeam={idSelectedTeam}
              idSelectedCycle={idSelectedCycle}
              user={selectedMember.user_info}
              perms={selectedMember.permissions}
              onCancel={this.handleEditInformationCancel}
              onOk={this.handleEditInformationOk}
              userCanEdit={userCanEdit}
              originPage={"teams"}
            />
          </Modal>
        );

      case "tasksReport":
        return (
          <Modal
            visible={modalTasksReportVisible}
            width={1400}
            title={modalTitle}
            footer={null}
            style={{ textAlign: "center" }}
            onCancel={this.handleTasksReportCancel}
            destroyOnClose={true}
          >
            <TasksTable
              idTeam={idSelectedTeam}
              idUR={idSelectedMember}
              idSelectedCycle={idSelectedCycle}
              refreshAllPendingTaskBadges={this.props.refreshAllPendingTaskBadges}
              getTeams={this.props.getTeams}
            /> 
          </Modal>
        );
        default:
          return(
            <div></div>
          )
    }
  };

  // AGREGAR MIEMBROS NUEVOS A UN EQUIPO
  handleMemberAdded = async () => {
    await this.findUsersToInsert();
    await this.getTeamUsers(this.state.idSelectedTeam);
  };

  render() {
    const {
      idSelectedTeam,
      idSelecteMember,
      teamSelected,
      buttonClicked,
      userCanEdit,
    } = this.state;
    const { userCanDeleteAndCreateTeams } = this.props;
    const { OptGroup, Option } = Select;
    return (
      <React.Fragment>
        <div>
          <Row justify="space-around" align="middle" style={{ margin: "20px" }} >
            <Col >
              <Select
                value={idSelectedTeam}
                style={{ width: 160 }}
                onChange={this.handleSelectTeam}
              >
                <Option
                  disabled={!userCanDeleteAndCreateTeams}
                  key="createTeam"
                >
                  Agregar equipo
                </Option>
                <OptGroup label="Mis equipos">{this.renderTeams()}</OptGroup>
                <OptGroup label="Otros equipos">
                  {this.renderOtherTeams()}
                </OptGroup>
              </Select>
            </Col>
            <Col>
              <Select
                showSearch={false}
                value={idSelecteMember}
                style={{ width: 160}}
                onChange={this.handleSelectMember}
              >
                <Option
                  key="allMembers"
                >
                  Todos los miembros
                </Option>
                {this.renderMembers()}
              </Select>
            </Col>
            </Row>
            {teamSelected && userCanEdit && idSelectedTeam !== "createTeam" && (

              <Row justify="space-around" align="middle" style={{ margin: "20px" }} >
           
                {/*<Col >
                {userCanEdit ? 
                  <Button
                  onClick={this.showModalTeamInformation}
                      type="link"
                      size={"medium"}
                    >
                      Informe del equipo
                      </Button>
                      : null}
                    </Col>*/}

                <Col >
                  <Button
                    onClick={this.showModalAddMember}
                    type="primary"
                    size={"medium"}
                    
                    >
                    Agregar miembro
                  </Button>
                </Col>
                </Row>
            )}
                
 
          
          <Row justify="center" gutter={[16, 16]}>
            {this.getTeamsContent()}
          </Row>
          <Row justify="center">
            {teamSelected &&
              userCanDeleteAndCreateTeams &&
              idSelectedTeam !== "createTeam" ? (
              <Col>
                <Popconfirm
                  title="¿Deseas eliminar este equipo?"
                  onCancel={this.onKeepTeam}
                  onConfirm={this.onDeleteTeam}
                  okText="Eliminar"
                  cancelText="Cancelar"
                >
                  <Button
                    type="primary"
                    danger
                    size="large"
                    icon={<DeleteOutlined />}
                    width="200px"
                  >
                    Eliminar equipo
                  </Button>
                </Popconfirm>
              </Col>
            ) : (
              <div />
            )}
          </Row>
        </div>
        <div>{this.renderConditionalModals(buttonClicked)}</div>
      </React.Fragment>
    );
  }
}

export default Teams;
