import React, { useState, Component } from "react";
import axios from "axios";
import { rootEndpoint } from "../utils/constants";
import {
  HistoryOutlined,
} from "@ant-design/icons";
import {
  Select,
  Row,
  Col,
  Switch,
  Checkbox,
  Divider,
  Button,
  Form,
  Input,
  message,
} from "antd";
import EditInformation from "../components/editUserWorkshops";
import server, { endpoints } from "../utils/server";


const { Option } = Select;

class AddUserWorkshop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addMembersMode: true,
      newMember: false,
      careers: [],
      info: {
        id_workshop: this.props.selectedNewWorkshopInCycle,
        id_cycle: this.props.idCycle,
        usersToInsert: this.props.userInsert,
        id_user: 0,
        first_name: "",
        last_name: "",
        up_email: "",
        career_id: "",
        semester: 0,
      },
      button: true,
      nameUser:"Busca a un miembro registrado"
    };

  };
  
  componentDidMount() {
    this.getCarrers();
  }

  //opciones para el selector de carreras
  getCarrers = async () => {
    const res = await server(endpoints.getCareerOptions.route, endpoints.getCareerOptions.method);
    const dataCareers = res.data;
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
    } else {
      this.setState({ careers: dataCareers })

    }
  };



  onInfoChange = (e) => {
    const { id, value } = e.target;
    let newData = { ...this.state.info };
    newData[id] = value;
    this.setState({ info: newData });
    this.handlerButton(newData);
  };


  handleSubmitAddMember = async () => {
    delete this.state.info.usersToInsert
    const { info, newMember } = this.state;

    if (!newMember) {
      if (info.id_user === 0) {
        message.error("Por favor selecciona un usuario a agregar al equipo");
        return;

      }
    }
    let data = { ...info };
    
    delete data.usersToInsert
    
    const params = {
      id_workshop:data.id_workshop,
      id_user: data.id_user,
      id_cycle:data.id_cycle,
    }

    data.up_email = this.state.info.up_email.replace(/\s+/g, '');
    this.setState({ button: false });
    const res = await server(endpoints.insertUserPerWorkshopPerCycle.route, endpoints.insertUserPerWorkshopPerCycle.method, params);
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
      this.setState({ button: true });
      return;
    }
    this.props.onHandleCancel();
    this.props.onHandleAdded();
    this.setState({ nameUser:"Busca a un miembro registrado" });
    this.setState({ button: true });
    message.success("Miembro agregado exitosamente");
    
  };

  handlerButton = () => {
    const { info } = this.state;
    if (
      ((!info.first_name ||
        !info.last_name ||
        !info.email) && this.state.newUser)
      || (
        !info.id_user
      )
    ) {

      return;

    }
    else {
      this.setState({ button: false });
    }
  };

  //switch para agregar miembro nuevo
  handleOldMember = () => {
    const { newMember } = this.state;
    if (newMember) {
      this.setState({ newMember: false });
      return;
    }
    this.setState({ newMember: true });
  };


  onExistingUserSelect = async (value) => {
    this.setState(
      prevState => ({
        info: {
          ...prevState.info,
          id_user: value
        }
      }),
      () => {
        this.setState({nameUser: value})
        this.handlerButton();
      }
    );
  };

  render() {
    const { info,   button } = this.state;
    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };
    return (

      <div width={"100%"}>
        <Row align="middle" justify="center">
          <Row span={24} justify="center">
            {this.state.newMember ? (
              <p>Registra Nuevo Usuario</p>
            ) : (
              <p>Agrega Usuario Registrado</p>
            )}
          </Row>
        </Row>
        <Row align="middle" justify="center">
          <Row span={24}>
            <Switch
              checkedChildren="Nuevo Usuario"
              unCheckedChildren="Usuario Registrado"
              onChange={this.handleOldMember}
            />
          </Row>
          <Divider style={{margin:"15px 0px"}}/>
        </Row>
        {this.state.newMember ?
          <EditInformation
            idWorkshop={info.id_workshop}
            idSelectedCycle={info.id_cycle}
            originPage={"addMember"}
            onHandleCancel={this.props.onHandleCancel}
          />
          : (
            <Col align="middle" justify="center" margin={20}>
              <Select
                align="middle"
                justify="center"
                showSearch
                style={{ width: "80%" }}
                placeholder="Busca a un miembro registrado"
                optionFilterProp="children"
                onChange={this.onExistingUserSelect}
                value={this.state.nameUser}
              >
                {
                  this.props.usersToInsert.map((user) => (
                    <Option value={user.id} key={user.id}>
                      {user.first_name} {user.last_name}
                    </Option>
                  ))
                }
              </Select>
            </Col>
            
          )}
          {info.name !== "" && !this.state.newMember
          ?
          (
            <div>
              <Col span={25} align="middle" justify="center">
                <Divider  style={{margin:"15px 0px"}}/>
                <Form span={25} {...layout} onFinish={this.handleSubmitAddMember} justify="center" style={{alignItems:"center"}}>        
                  <Row justify="center" align="middle">
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ backgroundColor: "#1890ff", borderColor: "#1890ff",borderRadius: "14px", color:"#fff" }}
                        disabled={button}
                      >
                        Agregar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </div>
          )
          :
          (
            <div />
          )
        }
      </div>
    );
  }
}

export default AddUserWorkshop;