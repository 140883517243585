import React, {useEffect, useState} from "react";
import {Button, Row, Select, Col, message, Divider} from "antd"
import Chart from "react-apexcharts";

const EvaluationChartsBar = ({question, index}) =>{

    const options = {
        chart: {
            id: "basic-bar"
          },
          xaxis: {
            categories: question.stats?.map(stat => stat.label)
          }
      };
      const series = [
        {
            name: "Respuesta",
            data: question.stats?.map(stat => stat.stat)
        }
      ]

    return(
        <div>
             <Row  
                >
                  <h4 style={{marginLeft:"60px", marginBottom:"20px"}}>Pregunta {index + 1}: {question.question}</h4>
                  <Row style={{width:"100%", marginLeft:"60px"}}>
                    <Chart
                    options={options}
                    series={series}
                    type="bar"
                    width={1000}
                    height={300}
                    />
                  </Row>

                </Row>
        </div>
    )

};
export default EvaluationChartsBar