import React,{ useEffect, useState }from "react";
import DashboardCards from "../components/dashboardCards";
import { Col, Row, Divider, Tooltip, message, Select, Button, Modal, Form, Input, Space} from 'antd';
import server, { endpoints } from "../utils/server";

import axios from "axios";
import { rootEndpoint } from "../utils/constants";



const DashboardM = ({idUR, idCycle}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [teamsIdToFilter, setTeamsIdToFilter] = useState([]);
  const [categorieIdToFilter, setCategorieIdToFilter] = useState([]);
  const [teams, setTeams] = useState();
  const [dashboardInfo, setDashboardInfo] = useState();
  const [teamToNewItem, setTeamToNewItem] = useState(0);
  const [categorieToNewItem, setCategorieToNewItem] = useState();
  const [itemLabel, setItemLabel] = useState();
  const [itemValue, setItemValue] = useState();



  useEffect(()=> {
    getDashboardInfo();
    getTeams();
  },[])



  const getTeams = async () => {
    const res = await server(endpoints.getTeamsInCyle.route, endpoints.getTeamsInCyle.method, { id_cycle: idCycle });
    const dataTeams = res.data;
    const {error_message} = res;
    if (error_message){
      message.error(error_message);
        return;
      }
      else {
        setTeams(dataTeams)
      }
  }

  const getDashboardInfo = async (teamsIdToFilter, categorieIdToFilter) => {
    const body = {
      team_filter: teamsIdToFilter,
      category_filter: categorieIdToFilter,
    };
    const params = {
      id_cycle: idCycle,
    };
    const res =  await server(endpoints.getDashboardInfo.route, endpoints.getDashboardInfo.method, params, body);
    const dataDasboard = res.data;
    const {error_message} = res;
    if (error_message) {
      message.error(error_message);
      return;
    }else{
      setDashboardInfo(dataDasboard);
    }
  }
 
  const editDashboardItem = async (id_team, label, value, id_dashboard_category, id) => {
    setIsModalOpen(false);
    const body = {
      id_team : id_team,
      label : label,
      value : value,
      id_dashboard_category: id_dashboard_category,
      id: id,
    };
    const params = {
      id_cycle: idCycle,
    }
    const res = await server(endpoints.editDashboardItem.route, endpoints.editDashboardItem.method, params, body);
    const {error_message }=res;
    if (error_message) {
      message.error(error_message);
      return;
    }
    message.success(res.message);
    getDashboardInfo(teamsIdToFilter, categorieIdToFilter);
    setIsModalOpen(false);
  }

  const handleTeamSelect = (list) => {
    setTeamsIdToFilter(list)
    getDashboardInfo(list, categorieIdToFilter)
  }
  const handleCategorieSelect = (list) => {
    setCategorieIdToFilter(list)
    getDashboardInfo(teamsIdToFilter, list)
  }
  const handleDelete = ()=>{
    getDashboardInfo(teamsIdToFilter, categorieIdToFilter)
  }
  const { Option } = Select;

  return(
    <React.Fragment>

        <Row justify="center">
          {dashboardInfo && dashboardInfo.general_info.user_can_edit &&
          <Button 
          style={{ width:150, borderRadius:10, margin: 10}}
          type="primary"
          onClick={()=>{setIsModalOpen(true)}}
          >
            Añadir enlace
          </Button>
          }
        </Row>
        <Row justify="center" style={{}}>   
            <Select
            style={{width: 150, margin: 10}}
            onChange={list => handleTeamSelect(list)}
            mode="multiple"
            placeholder="Todos los equipos"
            > 
            {teams && teams.map((t) => (
              <Option key={t.id} value={t.id}>{t.name}</Option>
            ))}
            </Select>
            <Select
            style={{width: 150, margin: 10}}
            onChange={list => handleCategorieSelect(list)}
            mode="multiple"
            placeholder="Categoria"
            >
            {dashboardInfo && dashboardInfo.general_info.categories.map((t)=> (
              <Option key={t.id} value={t.id}>{t.label}</Option>
            ))}
            </Select>       
        </Row>

      <Modal 
      title="Introducir URl de destino" 
      visible={isModalOpen} 
      onOk={() => {setIsModalOpen(false)}} 
      onCancel={() => {setIsModalOpen(false)}}
      width={370}
      footer={[
        <Row justify="space-around" key="buttons">

            <Button 
            key="back" 
            onClick={() => { setIsModalOpen(false);}}
            type="text"
            danger
            size="small"
            >
              Cancelar
            </Button> 
              <Button key="submit" type="primary" size="small" shape="round"
              onClick={()=>{editDashboardItem(teamToNewItem,itemLabel,itemValue,categorieToNewItem,0)}}
              > Añadir</Button>
        </Row>
    ]}>
        <Form
        name="basic"
        style={{}}
        >
           <Form.Item
            label="Titulo"
            name="title"
            rules={[  
              {
                required: true,
                message: "Favor de ingresar un titulo." 
              }
            ]}
            >
              <Input 
              onChange={(e)=>setItemLabel(e.target.value)}
              placeholder="Manual de marca" />
            </Form.Item>
            <Form.Item
            label="URL"
            name="url"
            rules={[  
              {
                required: true,
                message: "Favor de ingresar una URL." 
              }
            ]}
            >
              <Input 
              onChange={(e)=>setItemValue(e.target.value)}
              placeholder="http://manual.pdf" />
            </Form.Item>
        </Form>

        <Row justify="center">

          <Select
            style={{width: 130, margin: 10}}
            defaultValue={0}
            onChange={setTeamToNewItem}
            >
              <Option key={0} value={0}>General</Option>
              {teams && teams.map((t) => (
              <Option key={t.id} value={t.id}>{t.name}</Option>
            ))}
            </Select>
          <Select
            style={{width: 130, margin: 10}}
            placeholder="Categoria"
            onChange={setCategorieToNewItem}
            >
              {dashboardInfo && dashboardInfo.general_info.categories.map((t)=> (
              <Option key={t.id} value={t.id}>{t.label}</Option>
            ))}
            </Select>
        </Row>


      </Modal>
      <Divider></Divider>
      <Row align="center">
          <Space direction="vertical">
            {dashboardInfo && dashboardInfo.teams.map((i)=>(
              i.items.length > 0 && (
              <div key={i.id}>
                <Divider >{i.name}</Divider>
                {i.items.map((item)=>(
                  <DashboardCards 
                  key={item.id} 
                  value={item.id}
                  item={item}
                  editDashboardItem={editDashboardItem}
                  idTeam={i}
                  perms={dashboardInfo.general_info}
                  handleDelete={handleDelete}
                  idUR={idUR}
                  />
                ))}
              </div>  
              )   
            ))}
           </Space>
        
      </Row>
      </React.Fragment>
      )
}

export default DashboardM;