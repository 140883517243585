import React from 'react';
import { Select, Space } from 'antd';

import './MultipleSelect.css';

const MultipleSelect = props => {
    const { options, stateChanger, label } = props;

    const selectOptions = [];
    options.forEach(option => {
        selectOptions.push({
            label: option.name || option.label,
            value: option.id,
        });
    });

    const handleChange = value => {
        stateChanger(value);
    };

    return (
        <div className="MultipleSelect">
            <label htmlFor="">{label}</label>
            <Space
                style={{
                    width: '100%',
                }}
                direction="vertical"
            >
                <Select
                    mode="multiple"
                    allowClear
                    style={{
                        width: '100%',
                    }}
                    placeholder="Please select"
                    onChange={handleChange}
                    options={selectOptions}
                />
            </Space>
        </div>
    );
};

export default MultipleSelect;
