// ESTOY AQUI PARA QUE FRANK NO ME OLVIDe
//Object.keys(objeto).map( (key) => ({console.log(objeto[key])}))

import React from "react";
import {
  UserOutlined,
  TeamOutlined,
  MailOutlined,
  IdcardOutlined,
  ExperimentOutlined,
  FieldNumberOutlined,
  TabletOutlined,
  CrownOutlined,
  AuditOutlined,
  ClockCircleOutlined
} from "@ant-design/icons";

export const fields = [
  {
    label: "first_name",
    name: "Nombre",
    type: "text",
    value: '',
    icon: <UserOutlined style={{ fontSize: "20px", color: "#7384E7" }} />,
    isRequired: true
  },
  {
    label: "last_name",
    name: "Apellido",
    type: "text",
    value: '',
    icon: <TeamOutlined style={{ fontSize: "20px", color: "#7384E7" }} />,
    isRequired: true
  },
  {
    label: "up_email",
    name: "Email institucional",
    type: "mail",
    value: '',
    icon: <IdcardOutlined style={{ fontSize: "20px", color: "#7384E7" }} />,
    isRequired: true
  },
  {
    label: "email",
    name: "Email personal",
    type: "mail",
    value: '',
    icon: <MailOutlined style={{ fontSize: "20px", color: "#7384E7" }} />,
    isRequired: true
  },
  {
    label: "career_id",
    name: "Carrera",
    type: "text",
    value: '',
    icon: <ExperimentOutlined style={{ fontSize: "20px", color: "#7384E7" }} />,
    isRequired: true
  },
  {
    label: "semester",
    name: "Semestre",
    type: "number",
    value: '',
    icon: (
      <FieldNumberOutlined style={{ fontSize: "20px", color: "#7384E7" }} />
    ),
    isRequired: true
  },
  {
    label: "phone_number",
    name: "Teléfono",
    type: "number",
    value: '',
    icon: <TabletOutlined style={{ fontSize: "20px", color: "#7384E7" }} />,
    isRequired: true
  },
  {
    label: "birthday",
    name: "Cumpleaños",
    type: "date",
    value: '',
    icon: <CrownOutlined style={{ fontSize: "20px", color: "#7384E7" }} />,
    isRequired: true
  },
  {
    label: "sex",
    name: "Sexo",
    type: "text",
    value: '',
    icon: <CrownOutlined style={{ fontSize: "20px", color: "#7384E7" }} />,
    isRequired: true
  },
  {
    label: "highschool",
    name: "Preparatoria",
    type: "text",
    value: '',
    isRequired: true
  },
  {
    label: "role",
    name: "Rol en el equipo",
    type: "text",
    value: '',
    icon: <AuditOutlined style={{ fontSize: "20px", color: "#7384E7" }} />,
    isRequired: true
  },
  {
    label: "total_nominal_hours",
    name: "Horas a cumplir",
    type: "number",
    value: '',
    icon: <ClockCircleOutlined style={{ fontSize: "20px", color: "#7384E7" }} />,
    isRequired: true
  },
];

export let values = {
  membersPerms: false, //permisos dados al nuevo usuario para editar miembros
  eventPerms: false, //permisos dados al nuevo usuario para editar eventos 
  eventReportPerms: false, //permisos dados al nuevo usuario para editar reportes de eventos 
  id_user: 0,
  first_name: '',
  last_name: '',
  up_email: '',
  email: '',
  career_id: '',
  semester: 0,
  phone_number: 1234567890,
  birthday: '',
  sex: '',
  highschool: '',
  role: '',
  total_nominal_hours: 0,
}