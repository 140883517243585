import React, { Component } from 'react';
import 'antd/dist/antd.css';
import './../styles/antd.css';
import { Progress, Statistic, Card, Row, Col, Image, Button, message } from 'antd';
import { ClockCircleOutlined, SnippetsOutlined } from '@ant-design/icons';
import axios from 'axios';
import { rootEndpoint } from "../utils/constants";
import server, { endpoints } from '../utils/server';

class Estadisticas extends Component {
	state = {
		tareasPendientes: this.props.pendingTasks,
		horas: this.props.hours,
		porcentaje: this.props.percentage,
		equipo: this.props.team,
		totalHours: this.props.totalHours,
		qr: this.props.qrCode,
		loadingQr: false,
	};

	sendQr = async () => {
		const body = {
			qr_code_url: this.state.qr,
			idtpc: this.props.idTeam,
		};
		const data = server(endpoints.sendQRcodeEmail.route, endpoints.sendQRcodeEmail.method, {}, body);
		const { error_message } = data;
		if (error_message) {
			message.error(error_message, 2)
			return;
		}

		message.success("El QR ha sido enviado exitosamente");



	}
	render() {
		return (
			<div style={{ width: "100%", marginBottom: "20px" }} >
				<Card title={this.props.role + " en: " + this.state.equipo} style={{ borderColor: "white" }} >
					<Row gutter={[8, 6]} >
						<Col style={{ width: "33%" }} >
							<Card justify="center" align="middle" style={{ borderColor: "#9a171f", height: "100%" }}>
								<Row align="top">
									<h3>
										<SnippetsOutlined style={{ fontSize: '20px' }} />
										{" Tareas Pendientes: "}
									</h3>
								</Row>
								<Row justify="center" align="middle">
									<Statistic align="middle" value={this.state.tareasPendientes} style={{ height: "100%" }} />
								</Row>
							</Card>
						</Col>
						<Col style={{ width: "33%" }}>
							<Card justify="center" align="start" style={{ borderColor: "#9a171f", height: "100%" }}>
								<Row align="top">
									<h3>
										<ClockCircleOutlined style={{ fontSize: '20px' }} />
										{" "} Cumplimento de Horas:
									</h3>
									<h2>
										{this.state.horas}
									</h2>
								</Row>
								<Row justify="center">
									<Col align="middle">
										<Progress width={80} type="circle" percent={this.props.percentage} />
									</Col>
								</Row>
							</Card>
						</Col>
						<Col style={{ width: "33%" }}>
							<Card align="middle" style={{ borderColor: "#9a171f", height: "100%" }}>
								<Row align="top">
									<h3>
										Para registrar horas de oficina:
									</h3>
								</Row>
								<Row justify="center">
									<Image
										width={150}
										src={this.props.qrCode}
									/>
								</Row>
								{!this.state.loadingQr  && (
									<Row justify="center">
										<Button
											type='link'
											onClick={() => this.sendQr()}>
											Enviar por correo
										</Button>
									</Row>
								)}
							</Card>
						</Col>
					</Row>
				</Card>
			</div>
		)
	}
}

export default Estadisticas;