import React, { Component } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { FileUnknownOutlined } from "@ant-design/icons";
import AycLogoPath from "./../resources/arteycultura.png";
import AyCsiteLogoPath from "./../resources/AyCsite_Logo.png";
import { sha256 } from 'js-sha256';
import ForgotPassword from "../components/ForgotPassword";
import server, { endpoints } from "../utils/server";
 
class Login extends Component {
 state = {
   up_email: "",
   password: "",
 };
 
 handleChange = ({ currentTarget: input }) => {
   let up_email = this.state.up_email;
   let password = this.state.password;
   switch (input.name) {
     case "up_email":
       up_email = input.value;
       break;
     case "password":
       password = input.value;
       break;
     default:
       break;
   }
   this.setState({ up_email, password });
 };
 
 handleSubmit = async (e) => {
 
   const body = {
     up_email: this.state.up_email,
     password: sha256(this.state.password),
   };
   //console.log("bodo", body);
   if (!this.state.up_email || !this.state.password) {
     return;
   }
   const res = await server(endpoints.login.route, endpoints.login.method, {}, body);
   const { error_message } = res;
   if (error_message) {
     message.error(error_message, 2)
     return;
   }
   this.props.onSuccessfulLogin(res.data);
 };
 
 render() {
 
   const { up_email, password } = this.state;
 
   return (
     <Col >
       <Row align="middle" gutter={[24, 100]} style={{ padding: 25 }} >
         <Col span={10}>
           <img width={150} src={AycLogoPath} alt={<FileUnknownOutlined />} />
         </Col>
       </Row>
       <Col >
         <Row align="center" gutter={[24, 150]} >
           <img width={270} src={AyCsiteLogoPath} alt={<FileUnknownOutlined />} />
         </Row>
      
         <Row justify="center">
      
           <Form
             initialValues={{ remember: true }}
             onSubmitCapture={this.handleSubmit}
           >
            
             <Form.Item
               name="up_email"
               style={{ width:300}}
 
               rules={[
                 {
                   required: true,
                   message: "Introduzca su correo institucional",
                 },
               ]}
             >
              
               <Input
                 value={up_email}
                 name="up_email"
                 placeholder="Correo institucional"
                 onChange={this.handleChange}
                
               />
             </Form.Item>
             <Form.Item
               rules={[{ required: true, message: "Introduzca la contraseña" }]}
             >
               <Input.Password
                 name="password"
                 value={password}
                 placeholder="Contraseña"
                 onChange={this.handleChange}
               />
             </Form.Item>
             <Row align="center">
               <Form.Item >
                 <Button
                  
                   type="primary"
                   htmlType="submit"
                   style={{ backgroundColor: "#9a171f", borderColor: "#9a171f" }}
                   shape="round"
                 >
                   Login
                 </Button>
               </Form.Item>
             </Row>
             <Row align="center">
               <ForgotPassword />
             </Row>
           </Form>
         </Row>
       </Col>
     </Col>
   );
 }
}
 
export default Login;
 
 

