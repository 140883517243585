import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Input,
  Select,
  Button,
  Form,
  Row,
  Col,
  PageHeader,
  Transfer,
  message,
  DatePicker,
  InputNumber
} from "antd";
import { SwapRightOutlined} from '@ant-design/icons';
import server, { endpoints } from "../utils/server";
import axios from "axios";
import { rootEndpoint } from "../utils/constants";
import Loading from "../components/loading";

const { Option } = Select;

const NewCycle = (props) => {
  const [loading, setLoading] = useState(false);
  const [newCycleName, setNewCycleName] = useState("");
  const [cycles, setCycles] = useState([]);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [idCycleOrigin, setIdCycleOrigin] = useState(0);
  const [originTeams, setOriginTeams] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedKeysEvaluations, setSelectedKeysEvaluations] = useState([]);
  const [enableButton, setenableButton] = useState(true);
  const [startDate, setStartDate] = useState();
  const [numberOfWeeks, setNumberOfWeeks] = useState(1);
  const [evaluations, setEvaluations] = useState([]);
  const [nameEvaluation, setNameEvaluation] = useState([]);
  const [targetKeysEvaluations, setTargetKeysEvaluations] = useState([]);
  const [startDateEvaluations, setStartDateEvaluations] = useState([]);
  const [endDateEvaluations, setEndDateEvaluations] = useState([]);
  const history = useHistory();
  

  useEffect(() => {
    getOptions();
  }, []);


  useEffect(() => {
    if (idCycleOrigin){

      getEvaluation();
    }
  }, [idCycleOrigin]);

  const getOptions = async () => {
    const res = await server(endpoints.getCyclesWithTeams.route, endpoints.getCyclesWithTeams.method);
    const cycles = res.data;
    setCycles(cycles);
    setOptionsLoaded(true);
  };
  const getEvaluation = async () => {
    const params = {
      id_cycle: idCycleOrigin,
    };
    const res = await server(endpoints.getEvaluation.route, endpoints.getEvaluation.method, params);
    const evaluations = res.data;
    const { error_message } = res
    if (error_message){
      message.error(error_message);
      return;
    }else{
      const dataEvaluation = [];
      for (let i = 0; i < evaluations.length; i++) {
   
        dataEvaluation.push({
          key: evaluations[i].id.toString(),
          name: evaluations[i].name,
          start_date: evaluations[i].start_date,
          end_date: evaluations[i].end_date
        });
      }
      setEvaluations(dataEvaluation)
    }
  }

  cycles.map((cycle) => (
    <Option value={cycle.id} key={cycle.id}>
      {cycle.title}
    </Option>
  ));

  const handleChangeSelect = ({ value: idCycle }) => {
    setIdCycleOrigin(idCycle);
    const teams = cycles.filter((cycle) => cycle.id === idCycle)[0].teams;
    const dataTeams = [];
    for (let i = 0; i < teams.length; i++) {
      dataTeams.push({
        key: teams[i].id.toString(),
        title: teams[i].name,
      });
    }
    setOriginTeams(dataTeams);
    setenableButton(false);
  };

  const pushNames = (id) => {
    const nameTemp = [];

    for (let i = 0; i < id.length; i++){
        const names = evaluations.filter((Name) => Name.key === id[i].toString())[0].name;
        nameTemp.push(names)
    }
    setNameEvaluation(nameTemp);
  };

  const handleChange = (nextTargetKeys, direction, moveKeys) => {
    setTargetKeys(nextTargetKeys);
  };
  const handleChangeEvaluations = (nextTargetKeys, direction, moveKeys) => {
    setTargetKeysEvaluations(nextTargetKeys);
    pushNames(nextTargetKeys);
  };

  const handleSelectTransferChange = (
    sourceSelectedKeys,
    targetSelectedKeys
  ) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };
  const handleSelectTransferChangeEvaluations = (
    sourceSelectedKeys,
    targetSelectedKeys
  ) => {
    setSelectedKeysEvaluations([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const [form] = Form.useForm();

  const selectAllLabels = [
    'Seleccionar Todos',
    ({ selectedCount, totalCount }) => `${selectedCount}/${totalCount}`,
  ];

  const onSubmit = async (e) => {
    if (!newCycleName) {
      message.error("Por favor escribe el nombre del nuevo ciclo");
      return;
    } else {
      setLoading(true);
      const teamIDs = [];
      for (let i = 0; i < targetKeys.length; i++) {
        teamIDs.push({
          id: parseInt(targetKeys[i]),
        });
      }

      const evaluationsData = [];

      for (let i = 0; i < targetKeysEvaluations.length; i++){
        evaluationsData.push({
          id: parseInt(targetKeysEvaluations[i]),
          name: nameEvaluation[i],
          start_date: startDateEvaluations[i],
          end_date: endDateEvaluations[i],
        });
      }
      const body = {
        title: newCycleName,
        teams: teamIDs,
        idCycleOrigin: idCycleOrigin,
        start_date: startDate,
        number_of_weeks: numberOfWeeks,
        evaluations: evaluationsData
      };
      const data = await server(endpoints.insertCycle.route, endpoints.insertCycle.method, {}, body);
      const { error_message } = data;
      if (error_message) {
        setLoading(false);
        message.error(error_message);    
      } else {
        message.success("Ciclo creado correctamente");
        setLoading(false);
        props.onCycleCreated();
        history.push("/myTasks");
        
      }
    }
  };
  const selectForEvaluations =(item) =>{
    return(
      <div className="optionlist-evaluation">
      {item.name} 
      {targetKeysEvaluations.includes(item.key) && (
        <Form.Item>
          <DatePicker
            placeholder="Inicio"
            format="DD/MM/YYYY"
            onChange={(date) => setStartDateEvaluations([...startDateEvaluations,date.format("YYYY-MM-DD")])}
            className="Calendar"
          />
          
          <SwapRightOutlined />
          <DatePicker
          placeholder="Final"
          format="DD/MM/YYYY"
          onChange={(date) => setEndDateEvaluations([...endDateEvaluations,date.format("YYYY-MM-DD")])}
          className="Calendar"
        />
        </Form.Item>
        
      )}
      </div>
      
      
    )
  }

  if(loading){
    return(
      <Loading mensaje={"Creando ciclo..."} />
    )
  }
  else{
    return (
      <Col>
        <Row justify="center" gutter={[0, 0]}>
          <PageHeader
            title="Crear nuevo ciclo"
          />
        </Row>
        <Form form={form} layout="horizontal" onSubmitCapture={onSubmit}>
          <Row justify="center" gutter={[0, 20]}>
            <Col span={7}>
              <Form.Item
                label="Seleccione un ciclo"
                name="cycleOrigin"
                rules={[
                  {
                    required: true,
                    message: "Por favor seleccione un ciclo",
                  },
                ]}
              >
                {optionsLoaded && (
                  <Select
                    labelInValue
                    onChange={handleChangeSelect}
                    placeholder="--Seleccione--"
                    ref="cycleOrigin"
                  >
                    {cycles.map((cycle) => (
                      <Option value={cycle.id} key={cycle.id}>
                        {cycle.title}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                label="Nombre del nuevo ciclo"
                name="newCycle"
                rules={[
                  {
                    required: true,
                    message: "Por favor agregue una descripción",
                  },
                ]}
              >
                <Input
                  onChange={(e) => setNewCycleName(e.target.value)}
                  value={newCycleName}
                />
              </Form.Item>
              <Form.Item
                label="Seleccione la fecha de inicio del ciclo"
                name="startDate"
                rules={
                  [
                    {
                      required: true,
                      message: "Agregar fecha de inicio"
                    }
                  ]
                }
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  onChange={(date) => setStartDate(date.format("YYYY-MM-DD"))}
                />
              </Form.Item>
              <Form.Item
                label="Seleccione cuántas semanas durará el ciclo"
                name="numberOfWeeks"
                rules={
                  [
                    {
                      required: true,
                      message: "Agregar número de semanas"
                    }
                  ]
                }
              >
                <InputNumber
                  min={1}
                  value={numberOfWeeks}
                  onChange={setNumberOfWeeks}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center">
            <PageHeader
              //title="Crear nuevo ciclo"
              subTitle="Seleccione los equipos que desea mantener de ciclos anteriores"
            />
          </Row>
          <Row justify="center">
            <Col >
              <Transfer
                dataSource={originTeams}
                titles={["Equipos anteriores", "Equipos a mantener"]}
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                onChange={handleChange}
                onSelectChange={handleSelectTransferChange}
                render={(item) => item.title}
                style={{ marginBottom: 40 }}
                listStyle={{
                  width: 350,
                  height: 350,
                }}
                selectAllLabels={selectAllLabels}
              //showSearch={true}
              />
            </Col>
          </Row>
          <Row justify="center">
            <PageHeader
              //title="Crear nuevo ciclo"
              subTitle="Seleccione las evaluaciones que desea mantener"
            />
          </Row>
          
          <Row justify="center">
            <Col >
              <Transfer
                dataSource={evaluations}
                titles={["Evaluaciones anteriores", "Evaluaciones a mantener"]}
                targetKeys={targetKeysEvaluations}
                selectedKeys={selectedKeysEvaluations}
                onChange={handleChangeEvaluations}
                onSelectChange={handleSelectTransferChangeEvaluations}
                render={(item) => selectForEvaluations(item)}
                style={{ marginBottom: 40 }}
                listStyle={{
                  width: 550,
                  height: 350,
                }}
                selectAllLabels={selectAllLabels}
              //showSearch={true}
              />
            </Col>
          </Row>
          <Row justify="center">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ backgroundColor: "#9a171f", borderColor: "#9a171f" }}
                disabled={enableButton}
              >
                Crear nuevo ciclo
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Col>
    );
  }
  
};

export default NewCycle;
