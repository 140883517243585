
import React, { Component } from "react";
import "./../styles/profile.css";
import "antd/dist/antd.css";
import { Row, Button, Card, Space, Upload, message, Avatar } from "antd";
import { CameraOutlined, LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { rootEndpoint } from "../utils/constants";
import server, { endpoints } from "../utils/server";

export class FotoPerfil extends Component {
  state = {
    fileContents: "",
    fileName: "",
    loadingProfilePhoto: false,
    fileList: []
  };

  encodeFile = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = (error) => reject(error);
    });

  beforeUpload = async (file) => {
    let converted_file = await this.encodeFile(file);
    this.setState({ fileContents: converted_file, fileName: file.name, loadingProfilePhoto: true, fileList: [] }, this.onFileSubmit);
  };

  onFileSubmit = () => {
    const body = {
      fileContents: this.state.fileContents,
      fileName: this.state.fileName,
    };
    server(endpoints.updateProfilePhoto.route, endpoints.updateProfilePhoto.method, {}, body)
      .then((res) => {
        const { error_message } = res;
        if (error_message) {
          message.error(error_message);
        }
        this.setState({ loadingProfilePhoto: false });
        this.props.onProfilePhotoChanged(res.data);
        message.success("Foto de perfil actualizada");
      })
      .catch((err) => {
        this.setState({ loadingProfilePhoto: false });
        message.error("No se pudo subir la imagen");
      })
  };

  render() {

    const antIcon = <LoadingOutlined style={{ fontSize: 24, marginTop: "15px" }} />;
    const { loadingProfilePhoto, fileList } = this.state;
    return (
      <Row>
        <Space align="center" direction="vertical">
          <Card
            title="Foto de perfil"
            style={{ textAlign: "center", borderColor: "white" }}
            cover={
              loadingProfilePhoto
                ?
                antIcon
                :
                (
                  this.props.imagen === ""
                    ?
                    null
                    :
                    <Row justify="center">
                      <Avatar src={this.props.imagen} alt="" size={150} shape="square" />
                    </Row>
                )}
          >
            <form>
              <Upload
                name="imagen"
                beforeUpload={this.beforeUpload}
                accept="image/*"
                multiple={false}
                fileList={fileList}
              >
                <Button
                  icon={<CameraOutlined />}
                  type="primary"
                  style={{
                    backgroundColor: "#9a171f",
                    borderColor: "#9a171f",
                    marginLeft: "0.8rem",
                  }}
                >
                  Click para escoger una nueva foto
                </Button>
              </Upload>
            </form>
          </Card>
        </Space>
      </Row>
    );
  }
}

export default FotoPerfil;
