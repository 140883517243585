import React, { useEffect, useState } from 'react';
import './EditModal.css';

//* Assets

import {
    LinkOutlined,
    PaperClipOutlined,
    UserOutlined,
    TagOutlined,
} from '@ant-design/icons';
import { useContext } from 'react';
import { DashboardContext } from '../../../views/dashboardP';

const EditModal = ({ props }) => {
    const { show, id , id_dashboard_category} = props;

    const { data, methods } = useContext(DashboardContext);
    const { editFile, toggleEdit } = methods;
    const { teams, categories, files } = data;

    const [fileInfo, setFileInfo] = useState({
        id: 0,
        label: '',
        value: '',
        id_dashboard_category: 0,
        team: 0,
    });

    useEffect(() => {
        if (id === 0) {
            setFileInfo({
                id: 0,
                label: '',
                value: '',
                id_dashboard_category: id_dashboard_category,
                team: 0,
            });
            return;
        }
        files.forEach(f => {
            if (f.id === id) {
                setFileInfo({ ...f });
            }
        });
    }, [show, id]);

    if (!show) {
        return <></>;
    }
    const handleChange = (evt, fn) => {
        evt.preventDefault();
        const { value } = evt.target;
        fn(value);
    };

    const setFileLabel = val => {
        setFileInfo({ ...fileInfo, label: val });
    };
    const setFileValue = val => {
        setFileInfo({ ...fileInfo, value: val });
    };
    const setFileCategory = val => {
        setFileInfo({ ...fileInfo, id_dashboard_category: val });
    };

    const setFileTeam = val => {
        setFileInfo({ ...fileInfo, team: Number(val) });
    };

    return (
        <div className="EditModal">
            <article className="EditModal-container">
                <header>
                    <h3>{id === 0 ? 'Nuevo' : 'Editar'} Archivo</h3>
                </header>
                <form className="EditModal-form">
                    <div className="EditModal-inputGroup">
                        <label htmlFor="fileName">
                            <PaperClipOutlined />
                            Nombre
                        </label>
                        <input
                            type="text"
                            name="fileName"
                            id="fileName"
                            placeholder="Manual de Equipo"
                            value={fileInfo.label || ''}
                            onChange={evt => handleChange(evt, setFileLabel)}
                        />
                    </div>
                    <div className="EditModal-inputGroup">
                        <label htmlFor="fileLink">
                            <LinkOutlined />
                            Enlace
                        </label>
                        <input
                            type="text"
                            name="fileLink"
                            id="fileLink"
                            placeholder="https://www.google.com/"
                            value={fileInfo.value || ''}
                            onChange={evt => handleChange(evt, setFileValue)}
                        />
                    </div>
                    <div className="EditModal-inputGroup">
                        <label htmlFor="fileTeam" id="fileTeam">
                            <UserOutlined />
                            Equipo
                        </label>
                        <select
                            type="select"
                            value={fileInfo.team || 0}
                            onChange={evt => handleChange(evt, setFileTeam)}
                        >
                            {teams.map(t => {
                                return (
                                    <option key={t.id} value={t.id}>
                                        {t.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="EditModal-inputGroup">
                        <label htmlFor="fileCategory" id="fileCategory">
                            <TagOutlined />
                            Categoria
                        </label>
                        <select
                            type="select"
                            value={fileInfo.id_dashboard_category || 0}
                            onChange={evt => handleChange(evt, setFileCategory)}
                        >
                            {categories.map(c => {
                                return (
                                    <option key={c.id} value={c.id}>
                                        {c.label}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </form>
                <footer className="EditModal-buttons">
                    <button className="DeleteModal-cancel" onClick={toggleEdit}>
                        Cancelar
                    </button>
                    <button
                        className="EditModal-edit"
                        onClick={() => editFile(fileInfo)}
                    >
                        Listo
                    </button>
                </footer>
            </article>
        </div>
    );
};

export default EditModal;
