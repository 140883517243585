import React, { useContext, useEffect } from 'react';
import { DashboardContext } from '../../../views/dashboardP';
import { DeleteFilled } from '@ant-design/icons';

import './DeleteModal.css';

export default function DeleteModal({ props }) {
    const { show, id } = props;
    useEffect(() => {}, [show, id]);

    const { methods, data } = useContext(DashboardContext);
    const { files } = data;
    const { deleteFile, toggleDelete } = methods;

    if (!show) return <></>;

    let currentFile = {};
    files.forEach(f => {
        if (f.id === id) currentFile = f;
    });

    return (
        <div className="DeleteModal">
            <div className="DeleteModal-container">
                <span className="DeleteModal-icon">
                    <DeleteFilled />
                </span>
                <strong>{currentFile.label}</strong>
                <h3>¿Seguro que deseas eliminar el siguiente archivo?</h3>

                <div className="DeleteModal-buttons">
                    <button
                        className="DeleteModal-delete"
                        onClick={() => deleteFile(id)}
                    >
                        Eliminar
                    </button>
                    <button
                        className="DeleteModal-cancel"
                        onClick={toggleDelete}
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    );
}
