import React, {useEffect, useState} from "react";
import {Button, Row, Select, Col, message, Divider} from "antd"
import Chart from "react-apexcharts";

const EvaluationChartsDonut = ({question, index}) =>{
    const options = {
        
        type: "donut",
        labels: question.stats?.map(stat => stat.label),
        dataLabels: {
            enabled: false,
        },
        colors: ["#008ffb", "#00e396", "#E2F53E","#F5943E", "#E62A0C"],
        plotOptions: {
            pie: {
              expandOnClick: false,
              donut: {
                labels: {
                    show: false,
                },
                size: "50%",
              },
            },
        },
      };
      const series = [
        1,2,3
      ]

    return(
        <div>
             <Row  
                >
                  <h4 style={{marginLeft:"60px", marginBottom:"20px"}}>Pregunta {index + 1}: {question.question}</h4>
                  <Row style={{width:"100%", marginLeft:"160px"}}>
                    <Chart
                    options={options}
                    series={question.stats?.map(stat => stat.stat)}
                    type="donut"
                    width={400}
                    />
                  </Row>

                </Row>
        </div>
    )

};
export default EvaluationChartsDonut;