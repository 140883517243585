import React from "react";
import useWindowDimensions from "../utils/useWindowDimensions";
import Loginpc from "../components/loginPc";
import Loginm from "../components/loginMobile";
import {widthMobile} from "../utils/constants"

const Login = (props) => {
    const {width} = useWindowDimensions();
    const IsMobile = width < widthMobile;
    return IsMobile ? <Loginm {...props} /> : <Loginpc {...props} />;
}
export default Login;