import React, { useState, useEffect } from "react";
import { Col, Row, Radio, Slider, message,Input,Button } from "antd";
import { FrownOutlined, SmileOutlined } from "@ant-design/icons";
import axios from "axios";
import { rootEndpoint } from "../utils/constants";
const { TextArea } = Input;
const EvaluationQuestions = ({
  t,
  idUR,
  teamUsers = [],
  answeredEvaluation,
  onChangeEvaluationsAnswer = () => {},
  onChangeUserReview = () => {},
}) => {
  const questionType = {
    range: 1,
    multiple_choice: 2,
    user_review: 3,
    open: 4,
  };
  return (
    <Row
      style={{
        margin: "10px 30px",
      }}
      justify="space-between"
    >
      <Col
        style={{
          width: "80%",
          padding: "10px 0px",
        }}
      >
        <Row style={{ margin: "0px 0px 0px 20px", fontSize: "20px" }}>
          <b>{t.name}</b>
        </Row>

        {t.id_question_type === questionType.range && (
          <Row style={{ marginLeft: "70px", maxWidth: "80%" }}>
            <FrownOutlined style={{ marginTop: "8px" }} />
            <Slider
              // disabled
              disabled={answeredEvaluation ? true : false}
              max={t.max}
              min={t.min}
              value={t.answer || t.min}
              style={{ width: "40%", marginLeft: "15px", marginRight: "15px" }}
              onChange={onChangeEvaluationsAnswer}
            />

            <SmileOutlined style={{ marginTop: "8px" }} />
          </Row>
        )}

        {t.id_question_type === questionType.multiple_choice && (
          <>
            {t.options.length > 0 ? (
              t.options.map((o, index) => (
                <div key={o.id} value={o.label}>
                  <Row style={{ marginLeft: "70px", maxWidth: "80%" }}>
                    <Radio.Group value={t.answer}>
                      <Radio
                        key={o.id}
                        value={o.id}
                        onChange={(e) =>
                          onChangeEvaluationsAnswer(e.target.value)
                        }
                      >
                        {o.option}
                      </Radio>
                    </Radio.Group>
                    {o.label}
                  </Row>
                </div>
              ))
            ) : (
              <div>No hay opciones en esta pregunta</div>
            )}
          </>
        )}

        <div>
          {t.id_question_type === questionType.user_review && (
            <>
              {teamUsers && teamUsers.length > 0 ? (
                teamUsers.map((n, index) => (
                  <div key={n.id} value={n.value}>
                    {/* {n.id === idUR && removeSecond(n.id, index)} */}
                    {n.id !== idUR && (
                      <div
                        style={{
                          fontSize: "18px",
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* Users names */}
                        <Row style={{ width: "50%" }}>
                          {/* {setUserIndex(userIndex)} */}
                          {n.first_name + " " + n.last_name}
                        </Row>
                        {/* Slider */}
                        <Row
                          style={{
                            alignItems: "center",
                            flexWrap: "nowrap",
                            position: "relative",
                            width: "50%",
                          }}
                        >
                          <FrownOutlined style={{ width: "10%" }} />
                          <Slider
                            max={t.max}
                            min={t.min}
                            defaultValue={t.min}
                            value={
                              t.answer?.find(
                                (answer) => answer.id_user === n.id
                              )?.answer || t.min
                            }
                            onChange={(value) =>
                              onChangeUserReview(value, n.id)
                            }
                            style={{ width: "80%" }}
                          />
                          <SmileOutlined style={{ width: "10%" }} />
                        </Row>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div>No hay participantes en tu equipo</div>
              )}
            </>
          )}
        </div>
        
        
        {t.id_question_type === questionType.open && (
          <>
          <Row style={{ marginLeft: "70px", maxWidth: "80%" }}>
          
          <TextArea
                    showCount
                    maxLength={2000}
                    style={{ height: 10, marginBottom: 12, resize: 'none'}}
                    //onChange={onChange}
                    placeholder="Respuesta aquí"
                    
                    autoSize
                    onChange={(e)=>onChangeEvaluationsAnswer(e.target.value)}
                  />
          </Row>
            
          </>
            )}
      </Col>
    </Row>
  );
};
export default EvaluationQuestions;
