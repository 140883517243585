import React from 'react';
import { Row, Col, Spin } from "antd";
import { SettingFilled } from "@ant-design/icons";

const Loading = (props) => {
    const antIcon = <SettingFilled style={{ fontSize: 100 }} spin />;
    return (  
        <Row justify="center" align="middle" style={{ height: "100vh"}}>
            <Col>
              <Spin indicator={antIcon} tip={props.mensaje} style={{ fontSize: 40 }}/>
            </Col>
        </Row>
    );
}
 
export default Loading;