import React, { useContext, useEffect, useState } from 'react';
import './LinkToFileList.css';

//*Components
import LinkToFile from '../LinkToFile/LinkToFile';

import { DashboardContext } from '../../../views/dashboardP';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export default function LinkToFileList(props) {
    const { data } = useContext(DashboardContext);
    const { files } = data;

    const [fileList, setFileList] = useState([]);
    const [sortDirection, setSortDirection] = useState(true); //true: descending, false: ascending
    const [pagination, setPagination] = useState({
        pages: 0,
        itemCount: 5,
        currentPage: 1,
    });

    useEffect(() => {
        let totalPages = Math.ceil(files.length / pagination.itemCount);
        if (totalPages === 0) totalPages = 1;
        setPagination({ ...pagination, pages: totalPages });

        setFileList(files);
    }, [files]);

    const clipFiles = filesToClip => {
        const startIdx = (pagination.currentPage - 1) * pagination.itemCount;
        let endIdx = Math.min(
            startIdx + pagination.itemCount,
            filesToClip.length
        );

        const temp = filesToClip.slice(startIdx, endIdx);
        return temp;
    };

    const sortFiles = () => {
        const temp = files;
        const sorted = temp.sort((after, before) => {
            const a = after.label.toLowerCase();
            const b = before.label.toLowerCase();
            if (a > b) {
                return sortDirection ? 1 : -1;
            }
            if (a < b) {
                return sortDirection ? -1 : 1;
            }
            return 0;
        });

        setFileList(sorted);
        setSortDirection(!sortDirection);
    };

    const nextPage = () => {
        const nextPage = Math.min(pagination.currentPage + 1, pagination.pages);
        setPagination({ ...pagination, currentPage: nextPage });
    };
    const prevPage = () => {
        const prevPage = Math.max(pagination.currentPage - 1, 1);
        setPagination({ ...pagination, currentPage: prevPage });
    };

    const changeItemsPerPage = evt => {
        evt.preventDefault();
        const value = parseInt(evt.target.value);
        let totalPages = Math.ceil(files.length / value);
        if (totalPages === 0) totalPages = 1;
        setPagination({
            pages: totalPages,
            currentPage: 1,
            itemCount: value,
        });
    };

    return (
        <div className="LinkToFileList">
            <div className="LinkToFileList-actions">
                <div>
                    <span>Mostrar resultados</span>
                    <select
                        name="itemsPerPage"
                        id="itemsPerPage"
                        value={pagination.itemCount}
                        onChange={changeItemsPerPage}
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                </div>

                <button onClick={sortFiles}>
                    Ordernar {!sortDirection ? 'Z - A' : 'A - Z'}
                </button>
            </div>
            <div className="LinkToFileList-list">
                {clipFiles(fileList).map(f => {
                    const { id } = f;
                    return <LinkToFile key={id} {...f} />;
                })}
            </div>
            <div className="LinkToFileList-pagination">
                <button onClick={prevPage}>
                    <LeftOutlined />
                </button>
                <span>
                    {pagination.currentPage} / {pagination.pages}
                </span>
                <button onClick={nextPage}>
                    <RightOutlined />
                </button>
            </div>
        </div>
    );
}
