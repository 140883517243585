import React, { Component } from "react";
import { Card, Row, Col, Button, Popconfirm, Avatar, Progress, Tooltip, Badge } from "antd";


import {
  DeleteOutlined,
  /*SolutionOutlined,*/
  EditOutlined,
  UserOutlined,
  BranchesOutlined,
} from "@ant-design/icons";


class TeamMemberCard extends Component {
  render() {

    const { member, onDelete, /*onTasksReportClick,*/ userCanEdit, idUR, onEditInformation, onSwitchTeam, userCanSwitch } = this.props;

    return (
      <React.Fragment>
        <Badge.Ribbon
          color={member.inOffice ? "lime" : "red"}
        >
          <Card
            //title={member.first_name + " " + member.last_name}
            style={{ height: 200 }}
            actions={[

              <Button
                //type="link"
                //block={true}
                style={{ borderColor: "white" }}
                onClick={() => onEditInformation(member.id)}
                disabled={!(userCanEdit || idUR === member.id)}

              >
                <Tooltip title="Editar información">
                  <EditOutlined />
                </Tooltip>
              </Button>,


              <Button
                type="secondary"
                size={"small"}
                style={{ borderColor: "white" }}
                onClick={() => onSwitchTeam(member.id)}
                disabled={!userCanSwitch}
              >
                <Tooltip title="Cambiar de equipo">
                  <BranchesOutlined />
                </Tooltip>
              </Button>
              ,
              /*
              <Button
                type="secondary"
                size={"medium"}
                style={{ borderColor: "white" }}
                onClick={() => onTasksReportClick(member.id)}
                disabled={!(userCanEdit || idUR === member.id)}
              >
                <Tooltip title="Bitácora">
                <SolutionOutlined />
                </Tooltip>
              </Button>
            ,*/


              <Popconfirm
                placement="topLeft"
                title={idUR === member.id ? "¿Salir del equipo?" : `¿Desaeas eliminar a ${member.first_name} ${member.last_name} de este equipo?`}
                okText="Sí"
                cancelText="No"
                onConfirm={() => onDelete(member.id)}
                disabled={!(userCanEdit)}
              >
                <Button
                  //type="danger"
                  size={"small"}
                  block={true}
                  style={{ borderColor: "white" }}
                  disabled={!(userCanEdit)}
                >
                  <Tooltip title="Eliminar miembro">
                    <DeleteOutlined />
                  </Tooltip>
                </Button>
              </Popconfirm>

            ]}
          >
            <Row align="middle" justfiy="space-between">
              <Progress percent={member.percentage} />
              <Col span={"24"}>
                <Row justify="center" align="middle">
                  <Col span={"6"}>
                    <Avatar
                      size={50}
                      src={member.profile_photo_url}
                      alt={UserOutlined}
                      style={{ marginRight: 10 }}
                    />
                  </Col>
                  <Col span={"18"} style={{ fontSize: "xsmall" }}>
                    <dl>
                      <li> <b>{member.first_name + " " + member.last_name}</b></li>
                      <li>{member.up_email}</li>
                      <li>{member.role}</li>
                    </dl>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Badge.Ribbon>
      </React.Fragment >
    );
  }
}

export default TeamMemberCard;
