import React from "react";
import useWindowDimensions from "../utils/useWindowDimensions";
import AddMemberPc from "./addMemberPc";
import AddMemberM from "./addMemberMobile";
import {widthMobile} from "../utils/constants"

const AddMembers = (props) => {
    const {width} = useWindowDimensions();
    const IsMobile = width < widthMobile;
    return IsMobile ? <AddMemberM {...props} /> : <AddMemberPc {...props} />;
}
export default AddMembers;