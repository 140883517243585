import React, { useState } from "react";
import { Row, Button, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { MdGroups } from "react-icons/md";
import { AiOutlineQrcode } from "react-icons/ai";
import DrawerMobile from "../components/drawerMobile";
import { TbCalendarTime } from "react-icons/tb";
import { FiList } from "react-icons/fi";
import { IoLibraryOutline } from "react-icons/io5";

const StartPageMobile = ({ session, logOut, setProfileState }) => {
    const history = useHistory();
    const [drawerVisible, setDrawerVisible] = useState(false);
    return (
        <div>
            <DrawerMobile
                visible={drawerVisible}
                setVisible={setDrawerVisible}
                session={session}
                logOut={logOut}
            />
            <div
                style={{
                    width: "100%",
                    backgroundColor: "#ac1830",
                    color: "white",
                    padding: "1rem 2rem 2rem 1rem",
                }}
            >
                Bienvenido,
                <Row justify="space-between" align="center">
                    <div style={{ marginTop: "10px" }}>
                        {session?.full_name}
                    </div>
                    <div>
                        <Row align="center">
                            <Button
                                style={{
                                    backgroundColor: "#af1116",
                                    width: "6rem",
                                    height: "30px",
                                    borderRadius: "15px",
                                    boxShadow: "0px 2px 8px 5px #931427",
                                    textAlign: "center",
                                    padding: "5px",
                                    borderWidth: "0",
                                    color: "white",
                                }}
                                onClick={() => history.push("/profileView")}
                            >
                                {session.up_email?.replace("@up.edu.mx", "")}
                            </Button>
                            <Avatar
                                size={38}
                                src={session.profile_photo_url}
                                alt={UserOutlined}
                                style={{ marginLeft: "-20px", marginTop: "-10px" }}
                            />
                        </Row>
                    </div>
                </Row>
            </div>
            <Row
                style={{
                    margin: "-15px 1rem 2rem 1rem",
                    height: "4.5rem",
                    boxShadow: "0px 2px 8px 2px grey",
                    borderRadius: "5px",
                    backgroundColor: "white",
                }}
                align="middle"
                justify="space-around"
            >
                <TbCalendarTime size={30} onClick={() => history.push("/myTasks")} /> 
                <MdGroups size={30} onClick={() => history.push("/teams")} />
                <AiOutlineQrcode size={30} onClick={() => {
                    history.push("/profileView");
                    setProfileState("qr");
                }}/>
                <IoLibraryOutline size={30} onClick={() => history.push("/dashboard")}/>
                <FiList size={30} onClick={() => setDrawerVisible(true)} />
            </Row>     
        </div>
    );
};

export default StartPageMobile;
