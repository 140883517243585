import React, { useContext } from 'react';
import { DeleteOutlined, EditFilled } from '@ant-design/icons';
import { DashboardContext } from '../../../views/dashboardP';

// styles
import './LinkToFile.css';

/**
 * Component that create the clickable box to the open file
 * @param {Object} fileInformation this will contain all the information about the file
 * @returns {React.Component}
 */
const LinkToFile = props => {
    const { label, value, id, team, id_dashboard_category, user_can_edit} = props;

    const { data, methods } = useContext(DashboardContext);
    const { categories, teams } = data;
    const { toggleDelete, toggleEdit } = methods;

    const category =
        categories.filter(c => c.id === id_dashboard_category)[0] ?? {};

    const fileTeam = teams.filter(t => t.id === team)[0] ?? {};

    const handleEditButton = evt => {
        evt.preventDefault();
        toggleEdit(id);
    };

    const handelDeletebutton = evt => {
        evt.preventDefault();
        toggleDelete(id);
    };

    return (
        <div className="linkToFile">
            <article className="LinkToFile-container">
                <a href={value} className="LinkToFile-anchor">
                    <h3 className="LinkToFile-title">{label}</h3>
                    <section className="LinkToFile-content">
                        <span className="LinkToFile-team">
                            {fileTeam?.name || 'undefined'}
                        </span>{' '}
                        -
                        <span className="LinkToFile-category">
                            {category?.label || 'undefined'}
                        </span>
                    </section>
                </a>
                <div className="LinkToFile-actionButtons">
                    {user_can_edit &&
                        <>
                            <button
                            className="LinkToFile-edit"
                            onClick={handleEditButton}
                            >
                                <EditFilled />
                            </button>
                            <button
                            className="LinkToFile-delete"
                            onClick={handelDeletebutton}
                            >
                                <DeleteOutlined />
                            </button>
                        </>
                    }
                </div>
            </article>
        </div>
    );
};

export default LinkToFile;
