//this file was created to implement the pagination method in order to render
//a new list of items without overflowing the render method. Here we're manipulating
//the original items list to create a new list with just the neccesary items in the page or filter.
import _ from "lodash";
export function paginate(items, pageNumber, pageSize) {
  const startIndex = (pageNumber - 1) * pageSize;
  return _(items).slice(startIndex).take(pageSize).value();
  //_(items) convert the items array into a lodash array
  //slice(startIndex) takes the items lodash array and slice it starting in the given index, startIndex
  //take(pageSize) takes the specified number of items from the sliced array, pageSize = 4
  //value() returns the resultant array
}
