import React, { useState, useEffect } from "react";
import { Form, DatePicker, Button, Input, Select, message, Modal, Col, TimePicker, ConfigProvider, Row,Popconfirm,} from 'antd';
import { rootEndpoint } from "../utils/constants";
import axios from "axios";
import moment from 'moment';
import useWindowDimensions from "../utils/useWindowDimensions";
import { DeleteOutlined } from '@ant-design/icons'; 
import server, { endpoints } from "../utils/server";

const { Option } = Select;

const NewTaskM = ({ idUR, idSelectedCycle, id_team, taskParameters, getTasks, minuteStep, timeFormat,
    convertTimeToMinutes, refreshAllPendingTaskBadges, getTeams, task, setShowDescription, setTask }) => {
    const { width } = useWindowDimensions();
    const SmallScreen = width <= 320;

    const [idSelectedUsers, setIdSelectedUsers] = useState(task?.users?.map(user => user.id.toString()) || [idUR.toString()]);
    const [idSelectedCategory, setIdSelectedCategory] = useState(task?.id_task_category || null);
    const [taskTitle, setTaskTitle] = useState(task?.title || "");
    const [description, setDescription] = useState(task?.description || "");
    const [startDate, setStartDate] = useState(task?.opening_date || moment().format(`YYYY-MM-DD ${timeFormat}`));
    const [duration, setDuration] = useState(task?.minutes_duration || 0);
    //const history = useHistory();
    const [form] = Form.useForm();

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    };

    useEffect(() => {

    }, []);


    const onReset = () => {
        if (idSelectedUsers.length && startDate && taskTitle) {
            form.resetFields();
            setShowDescription(false);
            setIdSelectedUsers([idUR.toString()]);
            setIdSelectedCategory(taskParameters.categories[0].id);
            setTaskTitle("");
            setDescription("");
            setStartDate(moment().format(`YYYY-MM-DD ${timeFormat}`));
            setDuration(0);
            setTask();
        }
    };


    const handleSubmit = async () => {

        if (startDate && taskTitle && idSelectedUsers.length) {



            const usersIDs = []
            for (let i = 0; i < idSelectedUsers.length; i++) {
                usersIDs.push({
                    id: idSelectedUsers[i]
                });
            }
            const bodyForBack = {
                title: taskTitle,
                description: description,
                opening_date: startDate,
                id_task_category: idSelectedCategory,
                users: usersIDs,
                minutes_duration: duration,
            }

            const params = {
                id_cycle: idSelectedCycle,
                id_team,
                id_task: 0,
            };
            const data = await server(endpoints.registerTask.route, endpoints.registerTask.method, params, bodyForBack);
            const { error_message } = data;
            const { message: messageSuccess } = data;
            if (error_message) {
                message.error(error_message);
            } else {
                message.success(messageSuccess);
                getTasks();
                refreshAllPendingTaskBadges();
                getTeams();
                onReset();
            }
        }
        else {
            message.error("Campos vacíos");
            return;

        }
    }
    const deleteTask = async () => {
        const data = await server(endpoints.deleteTask.route, endpoints.deleteTask.method, { id_task: task.id });
        const { error_message } = data;
        const { message: successMessage } = data;
        if (error_message) {
            message.error(error_message);
            return;
        }
        else {
            message.success(successMessage);
            setShowDescription(false); 
            setTask();
            await getTasks();
        }
    }


    const onCategoryChange = idCategory => {
        let idCategoryNew = null;
        if (idCategory) {
            idCategoryNew = parseInt(idCategory);
        }
        setIdSelectedCategory(idCategoryNew);
    }

    return (
        <>
            <Modal
                title="Nueva Tarea"
                style={{ width: "100%" }}
                visible
                onOk={() => { setShowDescription(false); setTask() }}
                onCancel={() => { setShowDescription(false); setTask(); }}
                destroyOnClose={true}
                footer={[
                    <Row justify="center" key="buttons">
                        <Button key="back" size={"large"} onClick={() => { setShowDescription(false); setTask(); }}>Cancelar</Button>
                        <Button key="submit" type="primary" size={"large"} onClick={handleSubmit}>{!!task ? "Actualizar": "Agregar"}</Button>
                    {task ?
                                   <Popconfirm
                                   title="Delete the task"
                                   description="Estas seguro de eliminar la tarea?"
                                   onConfirm={deleteTask}
                                   okText="Yes"
                                   cancelText="No"
                                 >
                                   <Button danger key="submit" type="primary" size={"large"}>Eliminar</Button>
                                 </Popconfirm>:null 
                    }    
                    {//en el mobile la tabla es diferente ya que se hace con html en vez de ant design por lo tanto usa task en vez de record
                }</Row>
                ]}
            >
                <Form form={form}       {...formItemLayout}>

                    <Form.Item
                        label="Nombre"
                        name="title"

                        rules={[
                            {
                                required: true,
                                message: "Por favor ingrese un nombre",
                            }
                        ]}
                        initialValue={taskTitle}
                    >
                        <Input
                            onChange={(e) => setTaskTitle(e.target.value)} defaultValue={taskTitle} autoFocus onKeyPress={(e) => { if (e.key === 'Enter') { handleSubmit() } }} />
                    </Form.Item>

                    <Form.Item
                        label="Descripción"
                        name="description"
                        rules={[
                            {
                                required: false,
                                message: "Por favor ingrese una descripcción",
                            }
                        ]}
                        initialValue={description}
                    >
                        <Input.TextArea onChange={(e) => setDescription(e.target.value)} defaultValue={description} />
                    </Form.Item>


                    <Form.Item
                        label="Fecha de inicio"
                        name="opening_date"
                        rules={[
                            {
                                required: true,
                                message: "Por favor ingrese una fecha límite",
                            }
                        ]}
                        initialValue={moment(startDate, `YYYY-MM-DD ${timeFormat}`)}
                    >
                        <ConfigProvider >
                            <DatePicker
                                format={`DD/MM/YYYY ${timeFormat}`}
                                minuteStep={minuteStep}
                                showTime={{ format: timeFormat }}
                                onChange={(dateTime) => setStartDate(dateTime.format(`YYYY-MM-DD ${timeFormat}`))}
                                defaultValue={moment(startDate, `YYYY-MM-DD ${timeFormat}`)}
                            />
                        </ConfigProvider>
                    </Form.Item>


                    <Form.Item
                        label="Usuarios"
                        name="teamUsers"
                        rules={[
                            {
                                required: true,
                                message: "Por favor seleccione los usuarios asignados para la tarea",
                            }
                        ]}
                        initialValue={idSelectedUsers}
                    >
                        <Select mode="tags" placeholder="Seleccione usuarios" defaultValue={idSelectedUsers}
                            allowClear
                            onChange={(value) => setIdSelectedUsers(value)}>
                            {taskParameters.users.map((teamUser) => (
                                <Option value={teamUser.id.toString()} key={teamUser.id}>
                                    {teamUser.first_name} {teamUser.last_name}
                                </Option>
                            ))};
                        </Select>
                    </Form.Item>


                    <Form.Item
                        label="Categorías: "
                        name="categories"
                        rules={[
                            {
                                required: false,
                                message: "Por favor ingrese una categoría",
                            }
                        ]}
                        initialValue={idSelectedCategory}
                    >
                        <Col>
                            <Select
                                //placeholder={}
                                value={idSelectedCategory}
                                //allowClear={idSelectedCategory !== null}
                                onChange={value => onCategoryChange(value)}
                            >
                                {
                                    taskParameters.categories.map(category => category !== undefined ? (

                                        <Option key={category.id} value={category.id}>{category.label}</Option>
                                    ) : null
                                    )
                                }
                            </Select>
                        </Col>
                    </Form.Item>
                    <Form.Item
                        name="time-picker"
                        label="Agregar Duración"
                        rules={[{ required: false, message: 'Por favor ingrese una duración' }]}
                        initialValue={moment().hour(Math.trunc(duration / 60)).minute(duration % 60)}
                    >
                        <Col>
                            <TimePicker
                                bordered={false}
                                placeholder="Horas | minutos"
                                format={timeFormat}
                                minuteStep={minuteStep}
                                showNow={false}
                                style={{ width: 170 }}
                                defaultValue={moment().hour(Math.trunc(duration / 60)).minute(duration % 60)}
                                onChange={async (e, hour) => {
                                    let newValue = convertTimeToMinutes(hour);

                                    setDuration(newValue);
                                }}
                            >
                            </TimePicker>
                        </Col>
                    </Form.Item>

                </Form>
            </Modal>
        </>
    );

}

export default NewTaskM;