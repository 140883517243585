import React, { useState, useEffect } from "react";
import { Button, Input, message, Modal, Col, Row, Tooltip, Space, Popconfirm } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { rootEndpoint } from "../utils/constants";
import axios from "axios";
import useWindowDimensions from "../utils/useWindowDimensions";
import server, { endpoints } from "../utils/server";

const ModifyCategores = ({ idUR, idSelectedCycle, id_team, taskParameters, getTaskParameters, teamName }) => {
    const { width } = useWindowDimensions();
    const SmallScreen = width <= 320;

    const [showDescription, setShowDescription] = useState(false);
    const [categories, setCategories] = useState(taskParameters.categories.slice(1, taskParameters.categories.length));


    useEffect(() => {
        getTaskParameters();
    }, [getTaskParameters]);

    useEffect(() => {
        setCategories(taskParameters.categories.slice(1, taskParameters.categories.length));
    }, [taskParameters]);

    const deleteTaskCategory = async (categoryId, index) => {
        if (categoryId === 0) {
            let categoriesAux = [...categories];
            categoriesAux[index] = { id: null };
            setCategories(categoriesAux);
            return;
        }
        const params = {
            id_task_category: categoryId,
            id_cycle: idSelectedCycle,
            id_team,
        };
        server(endpoints.deleteTaskCategory.route, endpoints.deleteTaskCategory.method, params).then((data) => {
            const { error_message, message: successMessage } = data;
            if (error_message) {
                message.error(error_message);
                return;
            }
            else {
                message.success(successMessage);
            }
        }).finally(() => getTaskParameters());
    }

    const createTaskCategory = async () => {
        const defaultCategory = {
            id: 0,
            label: "Nueva categoría",
        }
        setCategories([...categories, defaultCategory]);
    }


    const EditTaskCategory = async () => {
        const bodyForBack = {
            categories: categories.filter(({ id }) => id !== null),
        }
        const params = {
            id_team: id_team,
            id_cycle: idSelectedCycle,
        };
        setShowDescription(false);
        const data = await server(endpoints.editTaskCategories.route, endpoints.editTaskCategories.method, params, bodyForBack);
        const { error_message } = data;
        const { message: messageSuccess } = data;
        if (error_message) {
            message.error(error_message);
        } else {
            message.success(messageSuccess);
        }
        await getTaskParameters();
        setShowDescription(false);
    }

    const onChangeTaskCategory = (value, index) => {
        let categoriesAux = [...categories];
        categoriesAux[index].label = value
        setCategories(categoriesAux);
    }


    return (
        <>
            {taskParameters.canEdit &&
                <Button
                    size={"large"}
                    onClick={() => setShowDescription(true)}
                    style={{
                        color: "#1890ff",
                        width: "52%",
                        fontSize: SmallScreen ? "12px" : "default",
                        padding: SmallScreen ? 0 : "default",
                        height: "35px",
                    }}
                >Modificar Categorias</Button>
            }
            <Modal
                title={`Modificar categorías de ${teamName}`}
                visible={showDescription}
                onOk={() => { setShowDescription(false) }}
                onCancel={() => { setShowDescription(false) }}
                destroyOnClose={true}
                footer={[
                    <Row justify="center" key="buttons">
                        <Button key="back" size={"large"} onClick={() => { setShowDescription(false); }}>Cancelar</Button>
                        <Button key="submit" type="primary" size={"large"} onClick={EditTaskCategory}> Guardar</Button>
                    </Row>
                ]}
            >
                <Row justify="center">
                    {categories && categories.map((item, index) => item.label !== "Oficina" && item.id !== null &&
                        (<Row justify="center" style={{ width: "100%" }} key={item.id !== 0 ? item.id : index}>
                            <Col style={{ width: 200 }}>
                                <Input defaultValue={item.label} size="small" style={{ width: 200, marginBottom: 10 }}
                                    onChange={(e) => onChangeTaskCategory(e.target.value, index)}
                                />
                            </Col>
                            <Col style={{ width: 10 }}>
                                <Space>
                                    <Popconfirm
                                        title="Estas seguro de eliminar esta categoría?"
                                        onConfirm={() => deleteTaskCategory(item.id, index)}
                                        okText="Eliminar categoria"
                                        cancelText="Cancelar"
                                    >
                                        <Tooltip title="Eliminar Categoria">
                                            <Button shape="circle" type="text" icon={<DeleteOutlined />} size="small" />
                                        </Tooltip>
                                    </Popconfirm>
                                </Space>
                            </Col>
                        </Row>
))}
                    <Tooltip title="Agregar">
                        <Button shape="circle" icon={<PlusOutlined />} onClick={createTaskCategory} />
                    </Tooltip>
                </Row>
            </Modal>
        </>
    );

}

export default ModifyCategores;