import React, { useState, useEffect } from 'react';
import { Row, Button, message,Modal } from "antd";
import axios from "axios";
import { rootEndpoint } from "../utils/constants";
import EvaluationQuestions from "../components/evaluationQuestions";
import server, { endpoints } from '../utils/server';


const Evaluations = ({idUR, idCycle }) => {
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();
  const [selectedEvaluation, setSelectedEvaluation] = useState();

  

  useEffect(()=> {
    getEvaluation();
  }, []);
  
  const getEvaluation = async () => {
    const params = {
      id_cycle: idCycle,
    };
    const res = await server(endpoints.getPendingEvaluations.route, endpoints.getPendingEvaluations.method, params);
    const {error_message, data: teams } = res;
    if (error_message){
      message.error(error_message);
    }else{
      setTeams(teams);
      setSelectedTeam(teams[0]);
      setSelectedEvaluation(teams[0]?.evaluations[0]);
    }
  }

  const onChangeEvaluationsAnswer = (answer, questionIndex) => {
    if (!selectedTeam || !selectedEvaluation) {
      return;
    }
    let teamsAux = [...teams];
    const teamIndex = teamsAux.findIndex(({ id_team }) => selectedTeam?.id_team === id_team,);
    let evaluationsAux = teams[teamIndex]?.evaluations;
    const evaluationIndex = evaluationsAux.findIndex(({ id }) => id === selectedEvaluation?.id);
    let evaluation = evaluationsAux[evaluationIndex];
    evaluation.questions[questionIndex].answer = answer;
    evaluationsAux[evaluationIndex] = evaluation;
    teamsAux[teamIndex].evaluations = evaluationsAux;
    setSelectedEvaluation(evaluation);
    setTeams(teamsAux);
  }

  const onChangeUserReview = (answer, questionIndex, idUserReviewed) => {

    if (!selectedTeam || !selectedEvaluation) {
      return;
    }
    let teamsAux = [...teams];
    const teamIndex = teamsAux.findIndex(({ id_team }) => selectedTeam?.id_team === id_team);
    let evaluationsAux = teams[teamIndex]?.evaluations;
    const evaluationIndex = evaluationsAux.findIndex(({ id }) => id === selectedEvaluation?.id);
    let evaluation = evaluationsAux[evaluationIndex];
    let answersAux = [];
    if (!!evaluation.questions[questionIndex].answer) {
      answersAux = evaluation.questions[questionIndex].answer;
    }
    const answerIndex = answersAux.findIndex(({ id_user }) => id_user === idUserReviewed);
    if (answerIndex >= 0) {
      answersAux[answerIndex].answer = answer;
    } else {
      answersAux.push({
        id_user: idUserReviewed,
        answer,
      });
    }
    evaluation.questions[questionIndex].answer = answersAux;
    evaluationsAux[evaluationIndex] = evaluation;
    teamsAux[teamIndex].evaluations = evaluationsAux;
    setSelectedEvaluation(evaluation);
    setTeams(teamsAux);
  }

  const registerEvaluationAnswers = async () =>{  
    if (!selectedTeam || !selectedEvaluation) {
      return;
    }

    const params = {
      id_evaluation: selectedEvaluation.id,
      id_team: selectedTeam.id_team,
      id_cycle: idCycle,
    }
  
    const body = {
      questions: selectedEvaluation.questions,
    }
    const res = await server(endpoints.registerEvaluationAnswers.route, endpoints.registerEvaluationAnswers.method, params, body);
    const {error_message } = res;
    if (error_message){
      message.error(error_message);
      return;
    }
    else{ 
      message.success("Respuesta registrada");
      getEvaluation();
      window.location.reload(false);
    }
  }

  const evaluationsNameStyle = {
    padding:"15px",
    fontSize: "large ",
    borderTopLeftRadius : "10px",
    borderTopRightRadius : "10px"
  };
  
return(
  <div>
    
        <div style={{width:"100%", background:"#fffff", height:"61px", boxShadow: "0px 4px 8px grey", textAlign:'center', position:'relative'}}>
             <Row style={{position:'relative', justifyContent:'center', textAlign:'center'}}>
                 {teams && teams?.length>0 ? (teams.map((t)=>(
                <div
                onClick={()=> {
                  setSelectedTeam(t);
                  setSelectedEvaluation(t.evaluations[0]);
                }}
                
                key={t.id_team}
                style={{
                  padding:"15px",
                  textAlign:"center",
                  position:'relative',
                  background : selectedTeam?.id_team === t.id_team && "white",
                  color : selectedTeam?.id_team === t.id_team && "#1892ff",
                  fontSize: "large ",
                  borderTopLeftRadius : "10px",
                  borderTopRightRadius : "10px",
                  cursor: "pointer",
                }}
                >
                  {t.team}
                  {selectedTeam?.id_team === t.id_team && 
                  <div style={{height:"2px", width:"100%",backgroundColor :"#1892ff",}}/>
                  }
                </div>
              ))):(
                <div
                style={evaluationsNameStyle}>
                  No tienes equipos
                </div>
              )}</Row>     
             </div>
             <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'2.5%'}}></div>
    <Row 
      style={{
        width: "100%",
        minHeight: "100%",
        marginTop: "3rem",
        paddingBottom: "3rem"
      }}
      justify="center"
      align="middle"
     >
        <div style={{ width: "85%", height: "80%", border: "3px solid #f0f0f0", borderRadius:"10px"}} > 
          <Row 
          style={{background: "#f1f1f2"}}
          justify="space-between"
          >
            <Row>
              {selectedTeam && selectedTeam.evaluations?.length>0 ? (selectedTeam.evaluations.map((t)=>(
                <div
                onClick={()=>setSelectedEvaluation(t)}
                key={`${selectedTeam?.id_team}-${t.id}`}
                style={{
                  padding:"15px",
                  background : selectedEvaluation?.id === t.id && "white",
                  color : selectedEvaluation?.id === t.id && "#1892ff",
                  fontSize: "large ",
                  fontWeight:"bold",
                  borderTopLeftRadius : "10px",
                  borderTopRightRadius : "10px",
                  cursor: "pointer",
                }}
                >
                  {t.name}
                  {selectedEvaluation?.id === t.id && 
                  <div style={{height:"2px", width:"100%",backgroundColor :"#1892ff"}}/>
                  }
                </div>
              ))):(
                <div
                style={evaluationsNameStyle}>
                  No tienes evaluaciones
                </div>
              )}
            </Row>
            
          </Row>

          <div>
            {selectedEvaluation && selectedEvaluation?.questions?.map((t, index)=>(
              <EvaluationQuestions
                key={`${selectedTeam?.id_team}-${selectedEvaluation?.id}-${t.id}`}
                t={t}
                idUR={idUR}
                teamUsers={selectedTeam?.teamUsers}
                onChangeEvaluationsAnswer={(answer) => onChangeEvaluationsAnswer(answer, index)}
                onChangeUserReview={(answer, idUserReviewed) => onChangeUserReview(answer, index, idUserReviewed)}
              />
              ))
            }        
          </div>
          <div style={{justifyContent:'center', position:'relative', alignContent:'center', textAlign:'center'}}>
            {selectedEvaluation &&(
              <Button type="primary"  size='large' onClick={()=>registerEvaluationAnswers()}>
                Enviar
              </Button> 
            )}
          </div>
        </div>
      </Row>
 
  </div>
)
}

export default Evaluations; 