import React, { Component, useState } from "react";
import ProfileView from "../views/vistaPerfil";
import NavBar from "../components/navbar";
import { Route, Switch, Redirect } from "react-router-dom";
import axios from "axios";
import { rootEndpoint } from "../utils/constants";
import { Card,  Input,  Row, Button, message, Popconfirm, Modal, Form } from 'antd';
import { FileTextOutlined, EditOutlined, DeleteOutlined, PlusCircleOutlined, CheckCircleFilled, ZoomInOutlined } from '@ant-design/icons';
import server, { endpoints } from "../utils/server";


const DashboardCard = ({item, idTeam, editDashboardItem, perms, handleDelete, idUR}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [itemLabel, setItemLabel] = useState(item.label);
  const [itemValue, setItemValue] = useState(item.value);

  const handleSubmit = () => {
    editDashboardItem(idTeam.id, itemLabel,itemValue, item.id_dashboard_category, item.id);
    setIsModalOpen(false);
  }

  const deleteItemFromCategorie = async (id_dashboard_item) => {
    const params = {
      id_dashboard_item,
    };
    const res = await server(endpoints.deleteItemFromCategory.route, endpoints.deleteItemFromCategory.method, params);
    const {error_message } = res;
    if (error_message){
      message.error(error_message);
      return;
    }
    message.success(res.message);
    handleDelete();
    setIsModalOpen(false);
  }

  return (
    <>
     <Card 
     style={{ 
       width: 300,
       borderRadius: 20,
       marginBottom: 7,
     }}
     size="small"
     >
       <Row justify="space-between">
         <div 
         style={{maxWidth:250}}
         onClick={() => window.open(item.value, '_blank', 'noreferrer')}
         >
         <FileTextOutlined style={{marginRight: 7}}/>
         {item.label}
         
         </div> 
         <div>
          {idTeam.name==="General" && perms.user_can_edit && <EditOutlined onClick={setIsModalOpen}/>}
          {idTeam.user_can_edit && <EditOutlined onClick={setIsModalOpen}/>}
         </div>
       </Row>
     </Card>
     <Modal 
      title="Actualizar Url" 
      visible={isModalOpen} 
      onOk={() => {setIsModalOpen(false)}} 
      onCancel={() => {setIsModalOpen(false)}}
      width={370}
      footer={[
        <Row justify="space-around" key="buttons">

            <Button 
            key="back" 
            onClick={() => { setIsModalOpen(false);}}
            type="text"
            danger
            size="small"
            >
              Cancelar
            </Button> 
            <Popconfirm
              title="¿Seguro que desea borar el enlace?"
              okText="Si"
              cancelText="No"
              onConfirm={()=>deleteItemFromCategorie(item.id)}
            >

              <Button key="delete" size="small" shape="round" >Borrar</Button>

            </Popconfirm>

              <Button key="submit" type="primary" size="small" shape="round" 
                onClick={handleSubmit}
              >Actualizar</Button>
        </Row>
    ]}>
      <Form
      name="basic"
      style={{}}
      >
         <Form.Item
          label="Titulo"
          name="title"
          rules={[  
            {
              required: true,
              message: "Favor de ingresar un titulo." 
            }
          ]}
          >
            <Input onChange={(e)=>setItemLabel(e.target.value)} placeholder="Manual de marca" defaultValue={item.label} />
          </Form.Item>
          <Form.Item
          label="URL"
          name="url"
          rules={[  
            {
              required: true,
              message: "Favor de ingresar una URL." 
            }
          ]}
          >
            <Input onChange={(e)=>setItemValue(e.target.value)} 
            placeholder="http://manual.pdf"  defaultValue={item.value} />
          </Form.Item>
      </Form>
    </Modal>
    </>
  )
}


export default DashboardCard;