import React from "react";
import useWindowDimensions from "../utils/useWindowDimensions";
import Teamspc from "../components/teamsPc";
import Teamsm from "../components/teamsMobile";
import {widthMobile} from "../utils/constants"

const Teams = (props) => {
    const {width} = useWindowDimensions();
    const IsMobile = width < widthMobile;
    return IsMobile ? <Teamsm {...props} /> : <Teamspc {...props} />;
}
export default Teams;