import React, { Component } from "react";
import axios from "axios";
import { rootEndpoint } from "../utils/constants";
import {
  HistoryOutlined,
} from "@ant-design/icons";
import {
  Select,
  Row,
  Col,
  Switch,
  Checkbox,
  Divider,
  Button,
  Form,
  Input,
  message,
} from "antd";
import EditInformation from "./editUserInformation";
import server, { endpoints } from "../utils/server";


const { Option } = Select;

class AddMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addMembersMode: true,
      newMember: false,
      careers: [],
      roles: [],
      perms: {}, //permisos para front(valores )
      permissions: [], // respuesta de back (nombres)
      info: {
        id_cycle: this.props.idSelectedCycle,
        id_team: this.props.idTeam,
        userCanEdit: this.props.userCanEdit,
        usersToInsert: this.props.usersToInsert,
        id_user: 0,
        first_name: "",
        last_name: "",
        up_email: "",
        email: "",
        career_id: "",
        semester: 0,
        phone_number: "",
        birthday: "",
        sex: "",
        role: "",
        highschool: "",
        total_nominal_hours: 0,
      },
      button: true,
    };
  };

  componentDidMount() {
    this.getCarrers();
    this.getRoles();
    this.getPermissions();
  }

  //opciones para el selector de carreras
  getCarrers = async () => {
    const res = await server(endpoints.getCareerOptions.route, endpoints.getCareerOptions.method);
    const dataCareers = res.data;
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
    } else {
      this.setState({ careers: dataCareers })

    }
  };

  getRoles = async () => {
    const res = await server(endpoints.getRoles.route, endpoints.getRoles.method);
    const dataRoles = res.data;
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
    } else {
      this.setState({ roles: dataRoles })

    }
  };

  getPermissions = async () => {
    const res = await server(endpoints.getTeamPermOptions.route, endpoints.getTeamPermOptions.method);
    const dataPerms = res.data;
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
    } else {
      this.setState({ permissions: dataPerms });
      this.fillPermsState(dataPerms);
    }
  };

  fillPermsState = (data) => {

    for (let i = 0; i < data.length; i++) {
      let name = data[i].value;
      let newData = { ...this.state.info, [name]: false }
      this.setState({ info: newData });
    }
  };


  onInfoChange = (e) => {
    const { id, value } = e.target;
    let newData = { ...this.state.info };
    newData[id] = value;
    this.setState({ info: newData });
    this.handlerButton(newData);
  };


  handleSubmitAddMember = async () => {

    delete this.state.info.userCanEdit
    delete this.state.info.usersToInsert

    const { info, newMember } = this.state;

    if (!newMember) {
      if (info.id_user === 0) {
        message.error("Por favor selecciona un usuario a agregar al equipo");
        return;

      }
    }
    let data = { ...info };
    
    delete data.usersToInsert
    delete data.userCanEdit
    
    let newData = {
      membersPerms: data.membersPerms,
      dashboardPerms: data.dashboardPerms,
      categoriesPerms: data.categoriesPerms,
      role: data.role,
      total_nominal_hours: data.total_nominal_hours,
    }
    
    const params = {
      id_user: data.id_user,
      id_cycle:data.id_cycle,
      id_team: data.id_team,
    }


    data.up_email = this.state.info.up_email.replace(/\s+/g, '');
    data.email = this.state.info.email.replace(/\s+/g, '');
    this.setState({ button: false });
    const res = await server(endpoints.insertUserToTeam.route, endpoints.insertUserToTeam.method, params, newData);
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
      this.setState({ button: true });
      return;
    }
    message.success("Miembro agregado exitosamente");
    this.props.onMemberAdded();
    this.props.onHandleCancel();
  };

  handlerButton = () => {
    const { info } = this.state;
    if (
      ((!info.first_name ||
        !info.last_name ||
        !info.up_email ||
        !info.role) && this.state.newUser)
      || (
        !info.role ||
        !info.id_user ||
        !info.total_nominal_hours
      )
    ) {

      return;

    }
    else {
      this.setState({ button: false });
    }
  };

  //switch para agregar miembro nuevo
  handleOldMember = () => {
    const { newMember } = this.state;
    if (newMember) {
      this.setState({ newMember: false });
      return;
    }
    this.setState({ newMember: true });
  };


  onExistingUserSelect = async (value) => {
    let info = { ...this.state.info };
    info.id_user = value;
    this.handlerButton(info);
    this.setState({ info });
  };


  handlePermision = ({ target, permType }) => {
    const perms = target.checked;
    const { info } = this.state;
    info[permType] = perms;
    this.setState({ perms });
  }


  onChangeSelect = (value, field) => {
    const info = { ...this.state.info };
    let idRole = (this.state.roles.find((role) => role.id === value)).id
    info.role = idRole;
    this.setState({ info });
    this.handlerButton(info);
  }

  render() {
    const { info, button } = this.state;
    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };
    return (

      <div width={"100%"}>
        <Row align="middle" justify="center">
          <Row span={24} justify="center">
            {this.state.newMember ? (
              <h2>Registra al nuevo usuario</h2>
            ) : (
              <h2>Agrega al miembro registrado</h2>
            )}
          </Row>
        </Row>
        <Row align="middle" justify="center">
          <Row span={24}>
            <Switch
              checkedChildren="Usuario Nuevo"
              unCheckedChildren="Usuario Nuevo"
              onChange={this.handleOldMember}
            />
          </Row>
          <Divider />
        </Row>
        {this.state.newMember ?
          <EditInformation
            idUR={info.idUR}
            idTeam={info.id_team}
            idSelectedCycle={info.id_cycle}
            userCanEdit={info.userCanEdit}
            perms={this.state.perms
            }
            originPage={"addMember"}
            onMemberAdded={this.props.onMemberAdded}
            onHandleCancel={this.props.onHandleCancel}
          />
          : (
            <Col align="middle" justify="center" margin={20}>
              <Select
                align="middle"
                justify="center"
                showSearch
                style={{ width: "80%" }}
                placeholder="Busca a un miembro registrado"
                optionFilterProp="children"
                onChange={this.onExistingUserSelect}
              >
                {
                  this.props.usersToInsert.map((user) => (
                    <Option value={user.id} key={user.id}>
                      {user.first_name} {user.last_name}
                    </Option>
                  ))
                }
              </Select>
            </Col>
          )}
        {info.name !== "" && !this.state.newMember
          ?
          (
            <div>
              <Divider>Permisos:</Divider>
              {this.state.permissions &&
                this.state.permissions.map((perm) => (
                  < Col align="middle" span={24} >
                    <Checkbox name={perm.value} onChange={(target) => this.handlePermision(target, perm.value)}>
                      {perm.label}
                    </Checkbox>
                  </Col>

                ))
/*              <Row justify="center">
                <Col span={12} style={{ width: "100%" }}>
                  <Popover content={
                    <p>Crear, editar o eliminar miembros del equipo en el ciclo actual</p>
                  }
                    title="Editar miembros"
                    trigger="hover">
                    <Checkbox onChange={(target) => this.handlePermision(target, membersPerms)} value={membersPerms}>
                      Editar miembros
                    </Checkbox>
                  </Popover>
                </Col>

              </Row>
              <Row justify="center">
                <Col span={12} style={{ width: "100%" }}>
                  <Popover content={
                    <p>Poder editar información en el Dashboard</p>
                  }
                    title="Editar dashboard"
                    trigger="hover">
                    <Checkbox value={dashboardPerms} onChange={(target) => this.handlePermision(target, dashboardPerms)}>
                      Editar Dashboard
                    </Checkbox>
                  </Popover>
                </Col>

                <Col span={12} style={{ width: "10%" }}>
                  <Popover content={
                    <p>Poder editar categorias</p>
                  }
                    title="Editar categorias"
                    trigger="hover">
                    <Checkbox value={categoriesPerms} onChange={(target) => this.handlePermision(target, categoriesPerms)}>
                      Editar categorías
                    </Checkbox>
                  </Popover>
                </Col>
              </Row>*/}
              <Divider> Rol en el equipo y horas:</Divider>
              <Col span={24} align="middle" justify="center" style={{ marginLeft: "30px" }}>
                <Form span={24} {...layout} onFinish={this.handleSubmitAddMember}>
                  <Form.Item
                    name="role"
                    label="Puesto"
                    initialValue=""
                    style={{ width: "100%" }}
                    labelAlign="left"
                    key={12}
                    rules={[
                      {
                        required: true,
                        message: "Por favor llena este campo"
                      },
                    ]}
                  >
                    <Select
                      onChange={(value) => this.onChangeSelect(value, "role")}
                    >

                      {this.state.roles &&
                        this.state.roles.map((rol) => (
                          <Option key={rol.id} value={rol.id}>{rol.name}</Option>
                        ))
                      }

                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="total_nominal_hours"
                    label="Horas:"
                    initialValue="0"
                    style={{ width: "100%" }}
                    labelAlign="left"
                    key={13}
                    rules={[
                      {
                        required: true,
                        message: "Por favor llena este campo"
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      prefix={<HistoryOutlined style={{ fontSize: "20px", color: "#7384E7" }} />}
                      type="number"
                      span={15}
                      onChange={e => this.onInfoChange(e)}
                      min="0"
                    />
                  </Form.Item>
                  <Divider />
                  <Row justify="center">
                    <Col span={6}>
                      <Button onClick={this.props.onHandleCancel} type="secondary">
                        Cancelar
                      </Button>
                    </Col>
                    <Col offset={1} span={6}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ backgroundColor: "#9a171f", borderColor: "#9a171f" }}
                        disabled={button}
                      >
                        Agregar al equipo
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </div>
          )
          :
          (
            <div />
          )
        }
      </div>
    );
  }
}

export default AddMember;