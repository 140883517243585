import React, { useState } from "react";
import { fields } from "../utils/iconosFormulario";
import { List, Row, Button, message } from "antd";
import EditInformation from "./editUserInformation";
import useWindowDimensions from "../utils/useWindowDimensions";
import { widthMobile } from "../utils/constants";
import axios from "axios";
import { rootEndpoint } from "../utils/constants";
import server, { endpoints } from "../utils/server";

function replacer(i, val) {
    if (i === "teams" || i === "profile_photo_url" || i === "image_card_url") {
      // identity operator and index in quotes
      return undefined; // attempt to remove from result
    } else {
      return val; // return unchanged
    }
  }

const ProfileM = ({ info, careers, idUR, setInfo }) => {
    const [editing, setEditing] = useState(false);
    const getCareerName = () => {
        const { career_id } = info;
        for(let i = 0; i < careers.length; i++){
          if(careers[i].id === career_id){
            return careers[i].initials;
          }
        }
    };

    const insertHighschool = async (otraPrepa) => {
        const data = {
          name: otraPrepa
        }
    
    
    
        const res = await server(endpoints.insertHighschool.route, endpoints.insertHighschool.method, {}, data);
        const { error_message } = res;
        if (error_message) {
          message.error(error_message);
          return;
        }
        message.success("Preparatoria agregada exitosamente");
      }
    
    const handleSubmit = async (info, highschool) => {


        if (highschool !== '') {
            await insertHighschool(highschool);
        }

        if (highschool !== '') {
            info.highschool = highschool;
        }
        let infor = JSON.stringify(info, replacer);
        const body = JSON.parse(infor);
        const res = await server(endpoints.updateUser.route, endpoints.updateUser.method, {}, body);
        const { error_message } = res;
        if (error_message) {
            message.error(error_message);
            return;
        }
        message.success(res.message);
        setInfo(info);
        setEditing(false);
    };

    const { width } = useWindowDimensions();
    const isMobile = width < widthMobile;
    return (
        <div>
            {!editing ? (
                <div>
                    {fields.filter(field => field.name !== "Rol en el equipo" && field.name !== "Horas a cumplir").map((field) =>
                      (
                        <div style={{ margin: "1rem "}}>
                            <h4 >{field.name + ": "} </h4>
                            <List.Item key={field.name} align="center">
                            <div style={{ border: '2px solid #E0DFE1', paddingBottom: "2px", width: "100%" }} >
                                <Row style={{ paddingLeft: "10px", paddingTop: "10px" }} >
                                {field.icon}
                                
                                {
                                    field.label === "career_id" ?
                                    
                    
                                    <h4 style={{ marginLeft: "10px" }}>  {getCareerName()}</h4>
                                    :
                                    <h4 style={{ marginLeft: "10px" }}>  {" " + info[field.label]}</h4>
                                }
                                </Row>
                            </div>
                            </List.Item>
                        </div>
                      )
                    )}
                    <Row justify="end" style={{ marginRight: "1rem" }}>
                        <Button onClick={() => setEditing(true)} style={{ backgroundColor: "#53a9e3", borderRadius: "5px" }}>
                            Editar
                        </Button>
                    </Row>
                </div>
                ) : (
                    <EditInformation
                        idUR={idUR}
                        originPage={"perfil"}
                        user={info}
                        handleSubmitPerfil={handleSubmit}
                        handleCancelProfile={() => setEditing(false)}
                        isMobile={isMobile}
                    />
                )}
        </div>
    );
};

export default ProfileM;
