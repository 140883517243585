import React from "react";
import useWindowDimensions from "../utils/useWindowDimensions";
import TeamMemberCardPc from "./teamMemberCardPc";
import TeamMemberCardM from "./teamMemberCardMobile";
import {widthMobile} from "../utils/constants"

const Teams = (props) => {
    const {width} = useWindowDimensions();
    const IsMobile = width < widthMobile;
    return IsMobile ? <TeamMemberCardM {...props} /> : <TeamMemberCardPc {...props} />;
}

export default Teams;