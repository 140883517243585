import React, { Component } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { FileUnknownOutlined } from "@ant-design/icons";
import AycLogoPath from "./../resources/arteycultura.png";
import AyCsiteLogoPath from "./../resources/AyCsite_Logo.png";
import { sha256 } from 'js-sha256';
import ForgotPassword from "./ForgotPassword";
import server, { endpoints } from "../utils/server";

class Login extends Component {
  state = {
    up_email: "",
    password: "",
  };

  handleChange = ({ currentTarget: input }) => {
    let up_email = this.state.up_email;
    let password = this.state.password;
    switch (input.name) {
      case "up_email":
        up_email = input.value;
        break;
      case "password":
        password = input.value;
        break;
      default:
        break;
    }
    this.setState({ up_email, password });
  };

  handleSubmit = async (e) => {

    const body = {
      up_email: this.state.up_email,
      password: sha256(this.state.password),
    };

    if (!this.state.up_email || !this.state.password) {
      return;
    }
    const res = await server(endpoints.login.route, endpoints.login.method, {}, body);
    const { error_message } = res;
    if (error_message) {
      message.error(error_message, 2)
      return;
    }
    this.props.onSuccessfulLogin(res.data);
  };

  render() {
    const tailLayout = {
      wrapperCol: {
        offset: 8,
        span: 16,
      },
    };

    const { up_email, password } = this.state;

    return (
      <Col >
        <Row justify="space-around" gutter={[24, 100]} style={{ padding: 25 }} >
          <Col span={22}>
            <Row justify="start"  >
              <img width={250} src={AycLogoPath} alt={<FileUnknownOutlined />} />
            </Row>
          </Col>
        </Row>

        <Col >
          <Row justify="center" align="middle" gutter={[24, 150]} >
            <img width={350} src={AyCsiteLogoPath} alt={<FileUnknownOutlined />} />
          </Row>

          <Row justify="center" align="middle"  >

            <Form
              initialValues={{ remember: true }}
              onSubmitCapture={this.handleSubmit}
            >
              <Form.Item
                name="up_email"
                style={{ width: 450 }}
                rules={[
                  {
                    required: true,
                    message: "Introduzca su correo institucional",
                  },
                ]}
              >
                <Input
                  value={up_email}
                  name="up_email"
                  placeholder="Correo institucional"
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Introduzca la contraseña" }]}
              >
                <Input.Password
                  name="password"
                  value={password}
                  placeholder="Contraseña"
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Row justify="center" style={{ width: 425 }}>
                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ backgroundColor: "#9a171f", borderColor: "#9a171f" }}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Row>
              <Row align="center">
                <ForgotPassword />
              </Row>
            </Form>
          </Row>
        </Col>
      </Col>
    );
  }
}

export default Login;

