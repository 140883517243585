import React, { useState, useEffect, useCallback } from 'react';
import axios from "axios";
import { rootEndpoint } from "../utils/constants";
import server, { endpoints } from '../utils/server';

import {
  Row,
  Col,
  Divider,
  Button,
  Form,
  Input,
  Select,
  Checkbox,
  message,
} from "antd";



//Variable activeHS

const { Option } = Select;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};


const EditInformationWorkshop = (props) => {

  const {user,  idSelectedCycle, originPage, onHandleCancel, onMemberAdded, idWorkshop } = props;
  const [userInfo, setUserInfo] = useState({ ...user });
  const [button, buttonChange] = useState(true)
  const [careers, setCareers] = useState([])


  

  useEffect(() => {
    getCarrers();
  }, []);

  const getCarrers = async () => {
    const res = await server(endpoints.getCareerOptions.route, endpoints.getCareerOptions.method);
    const dataCareers = res.data;
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
    } else {
      setCareers(dataCareers);
    }
  };


  

  const onInfoChange = e => {
    const { id, value } = e.target;
    setUserInfo({
      ...userInfo,
      [id]: value
    })
    handlerButton()
  };
  
  const onChangeSelect = (value, field) => {
    const changedField = field.label;
    setUserInfo({
      ...userInfo,
      [changedField]: value,
    });
    handlerButton();
  };



  const handleSubmit = async () => {
    delete userInfo.career
    if (originPage === "addMember") {
      handleSubmitAddMember();
    }
  }


  const handleSubmitAddMember = async () => {
    // Realiza las acciones específicas al enviar el formulario en la página "addMember"
    try {
      const data = {
        ...userInfo,
      };
      const params = {
        id_user: 0,
        id_cycle: idSelectedCycle,
        id_workshop: idWorkshop
      };

      const res = await server(endpoints.insertUserPerWorkshopPerCycle.route, endpoints.insertUserPerWorkshopPerCycle.method, params, data);
      const { error_message } = res;
      if (error_message) {
        message.error(error_message);
        return;
      }

      // Muestra un mensaje de éxito
      message.success("Usuario Insertado Correctamente");
      onHandleCancel();
    
    } catch (error) {
      message.error("Error al enviar el formulario.");
    }
  };

  const handlerButton = () => {

    if (
      userInfo.first_name === "" ||
      userInfo.last_name === "" ||
      userInfo.email === ""
    ) {
      if (button === true) {
        return
      }
      buttonChange(true)
      return;
    }
    else {
      buttonChange(false)
    }
  };


  return (
    <React.Fragment>
      <Form {...layout} preserve={false} onFinish={handleSubmit}>
        <Form.Item label="Nombre" name="firstName">
          <Input onChange={(e) => onInfoChange(e)} value={userInfo.firstName} />
        </Form.Item>
        <Form.Item label="Apellidos" name="lastName">
          <Input onChange={(e) => onInfoChange(e)} value={userInfo.lastName} />
        </Form.Item>
        <Form.Item label="Correo Institucional" name="email">
          <Input onChange={(e) => onInfoChange(e)} value={userInfo.email} />
        </Form.Item>
        <Form.Item label="Carrera" name="careerId">
          <Select
            showSearch
            optionFilterProp="children"
            defaultValue={userInfo.careerId}
            onChange={(value) => onChangeSelect(value, { label: "careerId" })}
          >
            {careers &&
              careers.map((career) => (
                <Option key={career.id} value={career.id}>
                  {career.full_name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label="Semestre" name="semester">
          <Input onChange={(e) => onInfoChange(e)} value={userInfo.semester}  type='number'/>
        </Form.Item>
        <Divider />
        <Row justify="center">
          <Col span={6}>
            <Button type="secondary" onClick={onHandleCancel}
            style={{ backgroundColor: "#fff", borderColor: "#000",borderRadius: "14px", color:"#000" }}
            >
              Cancelar
            </Button>
          </Col>
          <Col offset={3} span={6}>
            <Form.Item align="middle">
              <Button
                type="primary"
                htmlType="submit"
                style={{ backgroundColor: "#1890ff", borderColor: "#1890ff",borderRadius: "14px", color:"#fff" }}
                disabled={button}
              >
                Agregar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

    </React.Fragment >
  );
};

export default EditInformationWorkshop;