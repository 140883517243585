import React, {useEffect, useState} from "react";
import {Popconfirm, Divider, Row, Select, Col, Button, message, Modal, Form, Input, ConfigProvider, DatePicker, Tooltip, InputNumber} from "antd";
import {PlusCircleOutlined, EditOutlined, DeleteOutlined, PlusOutlined, LeftOutlined, DownOutlined} from '@ant-design/icons';
import { NavLink, withRouter, Link } from "react-router-dom"
import "./../styles/profile.css";
import { rootEndpoint } from "../utils/constants";
import axios from "axios";
import moment from "moment";
import EvaluationQuestions from "../components/editEvaluationQuestions";
import server, { endpoints} from "../utils/server";


const EditEvaluations = ({idUR, idCycle}) => {  

    const [evaluations, setEvaluations] = useState();
    const [evaluationId, setEvaluationId] = useState();
    const [evaluationStartDate, setEvaluationStartDate] = useState();
    const [evaluationEndDate, setEvaluationEndDate] = useState();
    const [evaluationName, setEvaluationName] = useState();

    const [newEvaluationName, setNewEvaluationName] = useState();
    const [newEvaluationStartDate, setNewEvaluationStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [newEvaluationEndDate, setNewEvaluationEndDate] = useState(moment().format("YYYY-MM-DD"));

    const [info, setInfo] = useState(false);
    const [questions, setQuestions] = useState();
    const [modalEditEvaluation, setModalEditEvaluation] = useState(false);
    const [modalCreateEvaluation, setModalCreateEvaluation] = useState(false);
    const [modalCreateQuestion, setModalCreateQuestion] = useState(false);
    const [modalResetEvaluation, setModalResetEvaluation] = useState(false);
    
    const [questionTypeToCreate, setQuestionTypeToCreate] = useState();
    const [newQuestionName, setNewQuestionName] = useState();
    const [newOptions, setNewOptions] = useState([]);
    const [min, setMin]  =useState();
    const [max, setMax]  =useState();

    useEffect(()=> {
      getEvaluation();
    },[])

    useEffect(()=>{
      if(evaluationId){
        getEvaluationQuestions();
      }
    },[evaluationId])
    
    const getEvaluation = async () => {
      const params = {
        id_cycle: idCycle,
      }
      const res = await server(endpoints.getEvaluation.route, endpoints.getEvaluation.method, params);
      const evaluations = res.data
      const { error_message } = res
      if (error_message){
        message.error(error_message);
        return;
      }else{
        setEvaluations(evaluations)
      }
    }

    const getEvaluationQuestions = async () =>{
      const params = {
        id_evaluation: evaluationId,
      }
      
      const res = await server(endpoints.getEvaluationQuestions.route, endpoints.getEvaluationQuestions.method, params);
      const question = res.data;
      const { error_mesasge } = res;
      if (error_mesasge){
        message.error(error_mesasge);
        return;
      }
      else{
        setQuestions(question);   
      }
    }
    
    const updateEvaluation = async () =>{
      const params = {
        id_evaluation: evaluationId,
        idUR,
      }
      const body ={
        name: evaluationName,
        start_date : evaluationStartDate,
        end_date : evaluationEndDate
      }
      const res = await server(endpoints.updateEvaluation.route, endpoints.updateEvaluation.mehtod, params, body);
      const {error_message, data} = res;
      if (error_message){
        message.error(error_message);
        return;
      }else{
        message.success(res.message);
        getEvaluation();
        setModalEditEvaluation(false);
      }
    }
    const deleteEvaluation = async () => {
      const params = {
        id_evaluation: evaluationId,
      };
      const res = await server(endpoints.deleteEvaluation.route, endpoints.deleteEvaluation.method, params);
      const { error_message } = res;
      if (error_message) {
        message.error(error_message);
        return;
      }else{
        message.success(res.message);
        getEvaluation();
        setModalEditEvaluation(false);
      }
    }
    const insertEvaluation = async () =>{
      const params = {
        id_cycle: idCycle,
      };
      const body ={
        name: newEvaluationName,
        start_date : newEvaluationStartDate,
        end_date : newEvaluationEndDate
      };
      const res = await server(endpoints.insertEvaluation.route, endpoints.insertEvaluation.method, params, body);
      const { error_message } = res;
      if (error_message) {
        message.error(error_message);
        return;
      }else{
        message.success(res.message);  
        getEvaluation();
        setModalCreateEvaluation(false);
      }
    }
    const insertEvaluationQuestion =async () => {
      
      const params = {
        id_evaluation: evaluationId,
      };
      
      const body = {
        id_question_type: questions.questionTypes.find(e=>e.label===questionTypeToCreate).id,
        min: min,
        max: max,
        options: newOptions,
        question: newQuestionName
      }
      const res= await server(endpoints.insertEvaluationQuestion.route, endpoints.insertEvaluationQuestion.mehtod, params, body);
      const {error_message, data } =res;
      if (error_message) {
        message.error(error_message);
        return;
      }else{
        message.success(res.message);
        getEvaluationQuestions();
        handleCloseModalQuestion();
        

      }
    }
    const restartEvaluation = async () => {
      const params ={idUR, id_evaluation:evaluationId}
      const body ={end_date:evaluationEndDate}
      
      const res = await server(endpoints.restartEvaluation.route, endpoints.restartEvaluation.method, params, body)
      if (res.error_message){
        message.error(res.error_message);
        return;
      }else{
        message.success(res.message);
        setModalResetEvaluation(false)

      }
    }





    const renderConditionalModals =(buttonClicked) => {
      switch (buttonClicked) {
        case "editEvaluation":
          if (questions) {
            setModalEditEvaluation(true);
          }else{
            message.error("Tienes que elegir una evaluación")
          }
        break;
        case "createEvaluation":
          setModalCreateEvaluation(true);
        break;
        case "createQuestion" :
          setModalCreateQuestion(true)
        break;
        case "resetEvaluation" :
          setModalResetEvaluation(true)
        break;
      }
    }
    const evaluationInfoChange =(t) =>{
      setEvaluationId(t.id);
      setEvaluationName(t.name);
      setEvaluationStartDate(t.start_date);
      setEvaluationEndDate(t.end_date);
    }
    const evaluationsNameStyle = {
      padding:"15px",
      fontSize: "large ",
      borderTopLeftRadius : "10px",
      borderTopRightRadius : "10px"
    };
    const formItemLayout = {
      labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
      },
      wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 },
      },
    };
    const addNewOption = () => {
      const defaultOption = {
        id: 0,
        label: "Nueva opción",
    }
    setNewOptions([...newOptions, defaultOption]);
    }
    const deleteNewOption = (index) =>{
      let temp = [...newOptions];
      temp.splice(index, 1)
      setNewOptions(temp);
    }
    const onChangeNewOption = (value, index) => {
      let optionsAux = [...newOptions];
      optionsAux[index].label = value;
      setNewOptions(optionsAux);
    }
    const handleCloseModalQuestion = () =>{
      setModalCreateQuestion(false)
      setQuestionTypeToCreate();
      setNewQuestionName();
      setMin();
      setMax();
      setNewOptions([]);

    }


    const questionType = {["range"]: "De rango",["multiple choice"]: "Opción multiple",["user review"]: "Revisón de equipo",["open answer"]: "Pregunta abierta"};
    const { Option } = Select;

    return(
        <div style={{padding:"10px"}}>
          <Row 
            style={{
              width: "100%",
              minHeight: "100%",
              marginTop: "3rem",
              marginBottom: "3rem",
            }}
            justify="center"
            align="middle"
           >
              <div style={{ width: "88%", height: "80%", border: "3px solid #f0f0f0", borderRadius:"10px"}} > 
                <Row 
                style={{background: "#f1f1f2"}}
                justify="space-between"
                >
                <Row>
                  
                    {evaluations && evaluations.length>0 ? (evaluations.map((t)=>(
                      
                      <Row
                      onClick={()=>evaluationInfoChange(t)}
                      key={t.id}
                      style={{
                        padding:"15px",
                        background : evaluationId === t.id && "white",
                        color : evaluationId === t.id && "#1892ff",
                        fontSize: "large ",
                        borderTopLeftRadius : "10px",
                        borderTopRightRadius : "10px"
                      }}
                      >
                        <Row>
                          {t.name}
                          {evaluationId === t.id &&  
                            <div style={{height:"2px", width:"100%",backgroundColor :"#1892ff",}}/>
                          }
                        </Row>
                        { evaluationId === t.id &&
                        <Row>
                          
                          <Tooltip title="Editar nombre de la evaluación">

                            <Button 
                            onClick={()=>{renderConditionalModals("editEvaluation")}} 
                            shape="circle" style={{margin:"0px 0px 0px 10px", boxShadow: "0px 4px 4px grey"}} >
                            <EditOutlined />
                            </Button>
                            </Tooltip>
                        </Row>
                        }
                      </Row>
                    
                    ))):(
                      <div
                      style={evaluationsNameStyle}>
                        No tienes evaluaciones
                      </div>
                    )}
                  </Row>
                  <Row>
                    <Tooltip title="Crear evaluación">
                      <Button 
                      onClick={()=>{renderConditionalModals("createEvaluation")}}
                      
                      shape="circle" size="large" style={{margin:"4px", boxShadow: "0px 4px 8px grey"}} >
                        <PlusCircleOutlined />
                      </Button>
                    </Tooltip>
                  </Row>  
                </Row>
                {questions &&
                <>
                <Row 
                style={{
                  background:"#F6F6F6", 
                  margin:"20px 40px 0px", 
                  padding:"10px", 
                  borderTopLeftRadius:"10px", 
                  borderTopRightRadius:"10px",
                  borderBottomLeftRadius: !info && "10px",
                  borderBottomRightRadius: !info && "10px",

                  }} 
                  justify="space-between" 
                  align="middle"
                  onClick={()=>setInfo(!info)}
                  >
                  <h4>Información</h4>
                  {info?
                  <DownOutlined style={{margin:"10px"}} onClick={()=>setInfo(false)}/>
                  :
                  <LeftOutlined style={{margin:"10px"}} onClick={()=>setInfo(true)}/>
                  }
                </Row>
                {info&&
                  <Row 
                  style={{
                    margin:"0px 40px 20px", 
                    padding:"10px", 
                    border:"3px solid #f0f0f0", 
                    borderBottomLeftRadius:"10px",
                    borderBottomRightRadius:"10px",
                    height:"80px"
                  }}
                  justify="space-around"
                  align="middle"
                  >
                      <Button  ghost  type="primary" ><Link to={`evaluationAnswers/${evaluationId}`}>Ver respuestas</Link></Button>
                      <Button  ghost  type="primary" ><Link to={`evaluationReport/${evaluationId}`}>Reporte de evaluaciones</Link></Button>
                  </Row>
                  }



                <Divider>Editar evaluación</Divider>
                <Row style={{margin:"20px 100px"}} justify="space-between">
                  <Button 
                      onClick={()=>{renderConditionalModals("createQuestion")}} 
                      type="primary"
                      style={{margin:"4px", boxShadow: "0px 4px 8px grey", borderRadius:"10px"}} >
                        Agregar pregunta
                  </Button>
  

                  <Button 
                      onClick={()=>{renderConditionalModals("resetEvaluation")}}
                      type="primary" danger
                      style={{margin:"4px", boxShadow: "0px 4px 8px grey", borderRadius:"10px"}} >
                        Reiniciar evaluación
                  </Button>
        
                </Row>
                </>
                }
                {questions && questions.questions.map((t, index)=>(
                  // <>buenas</>
                  <EvaluationQuestions
                  key={t.id}
                  value={t.id}
                  // questionMap={t}
                  question={questions}
                  questionTypes={questions.questionTypes}
                  index={index}
                  idUR={idUR}
                  getEvaluationQuestions={getEvaluationQuestions}
                  setQuestion={setQuestions}
                  />
                  ))
                }
                
              </div>
            </Row>
            <Modal
            visible={modalEditEvaluation}
            onCancel={()=>setModalEditEvaluation(false)}
            onOk={()=>setModalEditEvaluation(false)}
            width={400}
            title="Editar evaluación"
            footer={[
              <Row justify="space-around" key="buttons">
                <Button key="back" type="text" danger onClick={()=>setModalEditEvaluation(false)}>Cancelar</Button>
                <Popconfirm
                title="¿Seguro que desea borar la evaluación?"
                okText="Si"
                cancelText="No"
                onConfirm={deleteEvaluation}
                >
                  <Button key="delete"  shape="round">Borrar</Button>
                </Popconfirm>

                <Button key="submit" type="primary" onClick={updateEvaluation}>Actualizar</Button>
              </Row>
            ]}
            >
             
              <Form {...formItemLayout}>
                <Form.Item
                label="Nombre"
                name="name"
                >
                  <Input 
                  value={evaluationName}
                  onChange={(e)=>setEvaluationName(e.target.value)}
                  />  
                  <></> 
                </Form.Item>
                <Form.Item
                label="Fecha de inicio"
                name="opening_date"
                >
                  <ConfigProvider>
                    <DatePicker
                    format={`DD/MM/YYYY HH:mm`}
                    value={moment(evaluationStartDate)}
                    onChange={setEvaluationStartDate}
                    minuteStep={15}
                    showTime={{ format: `HH:mm` }}
                    
                    />
                  </ConfigProvider>
                </Form.Item>
                <Form.Item
                label="Fecha de fin"
                name="closing_date"
                >
                  <ConfigProvider>
                    <DatePicker
                    format={`DD/MM/YYYY HH:mm`}
                    value={moment(evaluationEndDate)}
                    onChange={setEvaluationEndDate}
                    minuteStep={15}
                    showTime={{ format: `HH:mm` }}
                    
                    />
                  </ConfigProvider>
                </Form.Item>
              </Form>
            </Modal>    
            <Modal
            visible={modalCreateEvaluation}
            onCancel={() => {setModalCreateEvaluation(false)}}
            onOk={()=>{setModalCreateEvaluation(false)}}
            width={400}
            title="Crear evaluación"
            footer={[
              <Row justify="space-around" key="buttons">
                <Button key="back" type="text" danger onClick={()=>setModalCreateEvaluation(false)}>Cancelar</Button>
                <Button key="submit" type="primary" onClick={insertEvaluation}>Agregar</Button>
              </Row>
            ]}

            >
              <Form {...formItemLayout}>
                <Form.Item
                label="Nombre"
                name="name"
                >
                  <Input 
                  value={newEvaluationName}
                  onChange={(e)=>setNewEvaluationName(e.target.value)}
                  />  
                  <></> 
                </Form.Item>
                <Form.Item
                label="Fecha de inicio"
                name="opening_date"
                >
                  <ConfigProvider>
                    <DatePicker
                    format={`DD/MM/YYYY HH:mm`}
                    value={moment(newEvaluationStartDate)}
                    onChange={setNewEvaluationStartDate}
                    minuteStep={15}
                    showTime={{ format: `HH:mm` }}
                    
                    />
                  </ConfigProvider>
                </Form.Item>
                <Form.Item
                label="Fecha de fin"
                name="closing_date"
                >
                  <ConfigProvider>
                    <DatePicker
                    format={`DD/MM/YYYY HH:mm`}
                    value={moment(newEvaluationEndDate)}
                    onChange={setNewEvaluationEndDate}
                    minuteStep={15}
                    showTime={{ format: `HH:mm` }}
                    
                    />
                  </ConfigProvider>
                </Form.Item>
              </Form>
            </Modal>
            <Modal
            visible={modalCreateQuestion}
            onCancel={handleCloseModalQuestion}
            onOk={handleCloseModalQuestion}
            width={500}
            title="Crear pregunta"
            destroyOnClose={true}
            footer={[
              <Row justify="space-around" key="buttons">
                <Button key="back" type="text" danger onClick={handleCloseModalQuestion}>Cancelar</Button>
                <Button key="submit" type="primary" onClick={insertEvaluationQuestion}>Crear</Button>
              </Row>
            ]}
            >
              <Row justify="center">
                <Select
                placeholder="Tipo de pregunta"
                style={{width: "200px", margin: "10px"}}
                onChange={setQuestionTypeToCreate}
                >
                  {questions && questions.questionTypes.map(({label })=>
                  <Option key={label}>{questionType[label]}</Option>
                  )}

                </Select>
              </Row>
              {/* <Button>{questionTypeToCreate}</Button> */}
              <Divider/>
              {questionTypeToCreate === "user review" || questionTypeToCreate === "range" ?
               <Row justify="center">
                <Col>
                  <Form >
                     <Form.Item
                     label="Nombre de la pregunta"
                     name="question"
                     rules={[
                       {
                         required: true,
                         message: "Por favor llena este campo"
                       },
                     ]}
                     >
                       <Input onChange={(e)=>setNewQuestionName(e.target.value)} defaultValue={newQuestionName}
                       />  
                     </Form.Item>
                   </Form>
                   <Row justify="space-between">
                     <Row style={{margin: "4px"}}>
                        Minimo:
                     </Row>
                        <InputNumber min={0} onChange={setMin}/>
                     <Row style={{margin: "4px"}}>
                        Maximo:
                     </Row>
                        <InputNumber min={0} onChange={setMax}/>
                   </Row>
                 </Col>
               </Row>
               :null
              }
              {questionTypeToCreate === "multiple choice" &&
               <Row justify="center">
                <Col>
                    <Form >
                      <Form.Item
                      label="Nombre de la pregunta"
                      name="question"
                      rules={[
                        {
                          required: true,
                          message: "Por favor llena este campo"
                        },
                      ]}
                      >
                        <Input onChange={(e)=>setNewQuestionName(e.target.value)} defaultValue={newQuestionName}
                        />  
                      </Form.Item>
                    </Form>
                    {newOptions.length>0 && newOptions.map((t, index)=>(
                      <Row justify="center" key={index}>
                        <Col style={{ width: "250px" , margin:"5px"}}>
                          <Input value={t.label} size="small" onChange={(e)=>onChangeNewOption(e.target.value, index)}/>
                        </Col>
                        <Col>
                            <Tooltip title="Eliminar opción">
                                <Button 
                                onClick={()=>{deleteNewOption(index)}}
                                style={{marginTop:"3px"}} shape="circle" type="text" icon={<DeleteOutlined />} size="small" />
                            </Tooltip>
                        </Col>
                      </Row>
                    ))}
                    <Row justify="center">
                      <Tooltip title="Agregar opción">
                        <Button shape="circle" icon={<PlusOutlined />} onClick={addNewOption} />
                      </Tooltip>
                    </Row>
                  </Col>
                </Row>
              }
              {questionTypeToCreate === "open answer" &&
               <Row justify="center">
               <Col>
                   <Form >
                     <Form.Item
                     label="Nombre de la pregunta"
                     name="question"
                     rules={[
                       {
                         required: true,
                         message: "Por favor llena este campo"
                       },
                     ]}
                     >
                       <Input onChange={(e)=>setNewQuestionName(e.target.value)} defaultValue={newQuestionName}
                       />  

                     </Form.Item>
                   </Form>
               </Col>
               </Row>
              }

            </Modal>
            <Modal
            visible={modalResetEvaluation}
            onCancel={()=>setModalResetEvaluation(false)}
            onOk={()=>setModalResetEvaluation(false)}
            width={400}
            title="Reiniciar evaluación"
            footer={[
              <Row justify="space-around" key="buttons">
                <Button key="back" type="text" onClick={()=>setModalResetEvaluation(false)}>Cancelar</Button>
                <Popconfirm
                  title="¿Seguro que desea reiniciar la evaluación?"
                  okText="Si"
                  cancelText="No"
                  onConfirm={restartEvaluation}
                  >
                  <Button key="submit" type="primary" danger >Reiniciar</Button>
                </Popconfirm>
              </Row>
            ]}

            >
              <Row justify="center">

                <h3>Nueva fecha de fin de evaluación</h3>
                <ConfigProvider>
                    <DatePicker
                    format={`DD/MM/YYYY HH:mm`}
                    value={moment(evaluationEndDate)}
                    onChange={setEvaluationEndDate}
                    minuteStep={15}
                    showTime={{ format: `HH:mm` }}
                    
                    />
                  </ConfigProvider>
              </Row>
              <Row justify="center"
              style={{margin:"20px 25px 0px 25px", background:"#F1DC9E"}}
              >
                  Recuerda que todas las respuestas se borraran
              </Row>
            </Modal>
        </div>
    )
};
export default EditEvaluations;