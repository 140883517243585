import React, { useState, useEffect } from "react";
import { Form, DatePicker, Button, Input, Select, message, Modal, Col, TimePicker, ConfigProvider, Row, List } from 'antd';
import { rootEndpoint } from "../utils/constants";
import axios from "axios";
import moment from 'moment';
import useWindowDimensions from "../utils/useWindowDimensions";
import server, { endpoints } from "../utils/server";

const { Option } = Select;

const NewTask = ({ idUR, idSelectedCycle, id_team, taskParameters, getTasks, minuteStep, timeFormat,
    convertTimeToMinutes, refreshAllPendingTaskBadges, getTeams }) => {
    const { width } = useWindowDimensions();
    const SmallScreen = width <= 320;

    const [showDescription, setShowDescription] = useState(false)
    const [idSelectedUsers, setIdSelectedUsers] = useState([idUR.toString()]);
    const [idSelectedCategory, setIdSelectedCategory] = useState(null);
    const [taskTitle, setTaskTitle] = useState("");
    const [description, setDescription] = useState("");
    const [startDate, setStartDate] = useState(moment().format(`YYYY-MM-DD ${timeFormat}`));
    const [duration, setDuration] = useState(0);
    
    const [form] = Form.useForm();
   


    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    };

    useEffect(() => {

    }, []);


    const onReset = () => {
        if (idSelectedUsers.length && startDate && taskTitle) {
            form.resetFields();
            setShowDescription(false);
            setIdSelectedUsers([idUR.toString()]);
            setIdSelectedCategory(taskParameters.categories[0].id);
            setTaskTitle("");
            setDescription("");
            setStartDate(moment().format(`YYYY-MM-DD ${timeFormat}`));
            setDuration(0);

        }
    };


    const handleSubmit = async () => {

        if (startDate && taskTitle && idSelectedUsers.length) {



            const usersIDs = []
            for (let i = 0; i < idSelectedUsers.length; i++) {
                usersIDs.push({
                    id: idSelectedUsers[i]
                });
            }
            const bodyForBack = {
                title: taskTitle,
                description: description,
                opening_date: startDate,
                id_task_category: idSelectedCategory,
                users: usersIDs,
                minutes_duration: duration,
            }

            const params = {
                id_cycle: idSelectedCycle,
                id_team,
                id_task: 0,
            };
            const data = await server(endpoints.registerTask.route, endpoints.registerTask.method, params, bodyForBack);
            const { error_message } = data;
            const { message: messageSuccess } = data;
            if (error_message) {
                message.error(error_message);
            } else {
                message.success(messageSuccess);
                getTasks();
                refreshAllPendingTaskBadges();
                getTeams();
                onReset();
            }
        }
        else {
            message.error("Campos vacíos");
            return;

        }
    }

    const onCategoryChange = idCategory => {
        let idCategoryNew = null;
        if (idCategory) {
            idCategoryNew = parseInt(idCategory);
        }
        setIdSelectedCategory(idCategoryNew);
    }

    return (
        <>
            <Button
                type="primary"
                style={{
                    backgroundColor: "#1890ff",
                    borderColor: "#1890ff",
                    width: "42%",
                    fontSize: SmallScreen ? "12px" : "default",
                    padding: SmallScreen ? 0 : "default",
                }}
                onClick={() => setShowDescription(true)}
            >Agregar tarea</Button>
            <Modal
                title="Nueva Tarea"
                visible={showDescription}
                onOk={() => { setShowDescription(false) }}
                onCancel={() => { setShowDescription(false) }}
                destroyOnClose={true}
                footer={[
                    <Row justify="center" key="buttons">
                        <Button key="back" size={"large"} onClick={() => { setShowDescription(false); }}>Cancelar</Button>
                        <Button key="submit" type="primary" size={"large"} onClick={handleSubmit}> Agregar</Button>
                    </Row>
                ]}
            >

                <Form form={form}       {...formItemLayout}>

                    <Form.Item
                        label="Nombre"
                        name="title"

                        rules={[
                            {
                                required: true,
                                message: "Por favor ingrese un nombre",
                            }
                        ]}
                    >
                        <Input
                            onChange={(e) => setTaskTitle(e.target.value)} autoFocus onKeyPress={(e) => { if (e.key === 'Enter') { handleSubmit() } }} />
                    </Form.Item>

                    <Form.Item
                        label="Descripción"
                        name="description"
                        rules={[
                            {
                                required: false,
                                message: "Por favor ingrese una descripcción",
                            }
                        ]}>
                        <Input.TextArea onChange={(e) => setDescription(e.target.value)} />
                    </Form.Item>


                    <Form.Item
                        label="Fecha de inicio"
                        name="opening_date"
                        rules={[
                            {
                                required: true,
                                message: "Por favor ingrese una fecha límite",
                            }
                        ]}>
                        <ConfigProvider >
                            <DatePicker
                                format={`DD/MM/YYYY ${timeFormat}`}
                                minuteStep={minuteStep}
                                showTime={{ format: timeFormat }}
                                onChange={(dateTime) => setStartDate(dateTime.format(`YYYY-MM-DD ${timeFormat}`))}
                                defaultValue={moment(startDate, `YYYY-MM-DD ${timeFormat}`)}

                            />
                        </ConfigProvider>
                    </Form.Item>


                    <Form.Item
                        label="Usuarios"
                        name="teamUsers"
                        rules={[
                            {
                                required: true,
                                message: "Por favor seleccione los usuarios asignados para la tarea",
                            }
                        ]}>
                        <Select mode="tags" placeholder="Seleccione usuarios" defaultValue={idSelectedUsers}
                            allowClear
                            onChange={(value) => setIdSelectedUsers(value)}>
                            {taskParameters.users.map((teamUser) => (
                                <Option value={teamUser.id.toString()} key={teamUser.id}>
                                    {teamUser.first_name} {teamUser.last_name}
                                </Option>
                            ))};
                        </Select>
                    </Form.Item>


                    <Form.Item
                        label="Categorías: "
                        name="categories"
                        rules={[
                            {
                                required: false,
                                message: "Por favor ingrese una categoría",
                            }
                        ]}>
                        <Col>
                            <Select
                                //placeholder={}
                                value={idSelectedCategory}
                                //allowClear={idSelectedCategory !== null}
                                onChange={value => onCategoryChange(value)}
                            >
                                {
                                    taskParameters.categories.map(category => category !== undefined ? (

                                        <Option key={category.id} value={category.id}>{category.label}</Option>
                                    ) : null
                                    )
                                }
                            </Select>
                        </Col>
                    </Form.Item>
                    <Form.Item
                        name="time-picker"
                        label="Agregar Duración"
                        rules={[{ required: false, message: 'Por favor ingrese una duración' }]}>
                        <Col>
                            <TimePicker
                                bordered={false}
                                placeholder="Horas | minutos"
                                format={timeFormat}
                                minuteStep={minuteStep}
                                showNow={false}
                                style={{ width: 170 }}
                                onChange={async (e, hour) => {

                                    let newValue = convertTimeToMinutes(hour);

                                    setDuration(newValue);
                                }}
                            >
                            </TimePicker>
                        </Col>
                    </Form.Item>

                </Form>
            </Modal>
            
        </>
    );

}

export default NewTask;