import React, { useState, useEffect } from "react";
import { Form, Button, Input, Modal, Row, message } from 'antd';
import { rootEndpoint } from "../utils/constants";
import axios from "axios";
import RetrivePassword from "./retrivePassword";
import server, { endpoints } from "../utils/server";

const ForgotPassword = () => {

    const [showDescriptionForgot, setShowDescriptionForgot] = useState(false)
    const [mail, setMail] = useState('')

    const [form] = Form.useForm();


    useEffect(() => {

    }, []);


    const onReset = () => {
        setMail('');
    };


    const generateCode = async (upmail) => {
        const bodyForBack = { "up_email": upmail }
        const data = await server(endpoints.sendRestorePasswordEmail.route, endpoints.sendRestorePasswordEmail.method, {}, bodyForBack);
        const { error_message } = data;
        const { message: messageSuccess } = data;
        if (error_message) {
            message.error(error_message);
        } else {
            message.success(messageSuccess);
        }
        onReset();
    }



    return (
        <>
            <Button
                type="link"
                onClick={() => setShowDescriptionForgot
                    (true)}
            >¿Olvidaste tu contraseña?</Button>
            <Modal
                title="¿Olvidaste tu contraseña?"
                style={{ width: "100%" }}
                visible={showDescriptionForgot}
                onOk={() => {
                    setShowDescriptionForgot
                        (false)
                }}
                onCancel={() => {
                    setShowDescriptionForgot
                        (false)
                }}
                destroyOnClose={true}
                footer={[
                    <Row justify="center">
                        <RetrivePassword
                            mail={mail}
                            generateCode={generateCode}
                            setShowDescriptionForgot={setShowDescriptionForgot}
                        />
                    </Row>
                ]}
            >

                <Form form={form}  >

                    <Form.Item
                        label="Correo:"
                        name="mail"


                        rules={[
                            {
                                required: true,
                                message: "Por favor ingrese su correo insititucional",
                            }
                        ]}
                    >
                        <Input
                            placeholder="algo@up.edu.mx"
                            onChange={(e) => setMail(e.target.value)}
                            autoFocus='true'
                            onKeyPress={(e) => { if (e.key === 'Enter') { generateCode(mail) } }} />
                    </Form.Item>

                </Form>
            </Modal>
        </>
    );

}

export default ForgotPassword;