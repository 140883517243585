import React, {useEffect, useState} from "react";
import {Button, Row, Select, Col, message, Divider} from "antd"
import {LeftCircleOutlined, CloseCircleOutlined} from "@ant-design/icons"
import {useParams }from "react-router"
import { rootEndpoint } from "../utils/constants";
import axios from "axios";
import { NavLink, withRouter, Link } from "react-router-dom"
import Chart from "react-apexcharts";
import server, { endpoints} from "../utils/server";
import EvaluationChartsBar from "../components/evaluationChartsBar";
import EvaluationChartsDonut from "../components/evaluationChartsDount";


const EvaluationReport = ({idUR, idCycle}) =>{
    const [evaluations, setEvaluations] = useState();
    const [questions, setQuestions] = useState();
    const [evaluationReport, setEvaluationReport] = useState();

    const [selectTeam, setSelectTeam] = useState();
    const [selectQuestion, setSelectQuestion] = useState();
    const [teams, setTeams] = useState();
    
    const params = useParams();
    useEffect(()=> {
        getEvaluation();
        getEvaluationQuestions(params.id);
        getTeamsInCycle();
      },[params])
      
    useEffect(()=>{
      getEvaluationReport(params.id);

    },[selectTeam, selectQuestion])

    const getTeamsInCycle = async() => {
      const params= {id_cycle:idCycle}
      const res = await server(endpoints.getTeamsInCycle.route, endpoints.getTeamsInCycle.method, params)
      const teams = res.data;
      const {error_message, data} = res.data
      if (error_message){
        message.error(error_message);
        return;
      }else{
        setTeams(teams);
      }
    }
    const getEvaluation = async () =>{
      const params = {id_cycle:idCycle}
      const res = await server(endpoints.getEvaluation.route, endpoints.getEvaluation.method, params);
      if ( res.error_mesasge){
        message.error(res.error_mesasge);
      }else{
        setEvaluations(res.data);
      }
    }
    const getEvaluationQuestions = async (id_evaluation) =>{
      const params = {id_evaluation};
      const res = await server(endpoints.getEvaluationQuestions.route, endpoints.getEvaluationQuestions.method, params);
      if (res.error_mesasge){
        message.error(res.error_mesasge);
      }else{
        setQuestions(res.data);
      }
    }
    const getEvaluationReport = async (id_evaluation)=>{
      let params = {idUR, id_evaluation};
      if(selectQuestion){
        params = {... params, id_question:selectQuestion};
      }
      if(selectTeam){
        params = {... params, id_team:selectTeam};
      }
      const res = await server(endpoints.getEvaluationReport.route, endpoints.getEvaluationReport.method, params);
      if (res.error_mesasge){
        message.error(res.error_mesasge);
      }else{
        setEvaluationReport(res.data);
      }
    }

    const {Option} = Select;

    const options = {
      type: "donut",
      labels: [
          "Alumnos",
          "Miembros",

      ],
      dataLabels: {
          enabled: false,
      },
      colors: ["#008ffb", "#00e396", "#125632"],
      plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              labels: {
                  show: false,
              },
              size: "50%",
            },
          },
      },
    };

    return(
        <div>         
           <Row style={{margin:"10px"}}>
              <Button type="text" style={{fontSize:"30px"}} >
                  <Link to="../editEvaluations"><LeftCircleOutlined  /></Link>
              </Button>
              <h1 style={{marginLeft:"50px"}}>Reportes de evaluación</h1>
            </Row>
            <Row >
              <Row justify="space-around" style={{margin:"10px", width:"100%"}}>
                  <Select allowClear style={{width:"200px"}} placeholder="Equipo" 
                  onChange={setSelectTeam}
                  >
                      {teams&&teams.map((t)=>(
                        <Option key={t.id} value={t.id}>{t.name}</Option>
                      ))}
                  </Select>
                  <Select allowClear style={{width:"200px"}} placeholder="Pregunta" onChange={setSelectQuestion}>
                      {questions && questions.questions.map((t)=>(
                          <Option key={t.id} value={t.id}>{t.question}</Option>
                      ))}
                  </Select>
              </Row>
            </Row> 
            <Row justify="center">
              
              <Row
              style={{
                marginTop:"40px",
                width:"85%",
                borderTop:"3px solid #cccccc",
                borderLeft:"2px solid #ececec",
                borderRight:"2px solid #ececec",
              }}
                >
                <h3 style={{margin:"10px 40px"}}>Evaluacion:&nbsp;{evaluations && evaluations.find(e => e.id===parseInt( params.id,10))?.name}</h3>
              </Row>

            </Row>
            <Row justify="center">
              {evaluationReport && evaluationReport.map((t, index)=>(
                <Row
                style={{
                  marginTop:"25px", 
                  width:"85%", 
                  // border:"3px solid #000000"
                  }}>
                  {t.id_question_type === 2 ?
                <EvaluationChartsDonut
                question={t}
                index={index}
                />:
                <EvaluationChartsBar
                question={t}
                index={index}
                />
              }
                </Row>
              ))}
                 

            </Row>

            
            {/* <Row>Reportes</Row>


            <Row>
              buenas
              <Chart
              options={options}
              series={[5,7]}
              type="donut"
              width={300}
              />

            </Row> */}
        </div>
    )
}
export default EvaluationReport;