import React, { useState, useEffect, useCallback } from "react";
import {
  Tag,
  Col,
  Row,
  message,
  Input,
  TimePicker,
  DatePicker,
  Button,
  Table,
  Space,
  Spin,
  Select,
  Progress,
  Popconfirm,
  ConfigProvider,
  Card,
} from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CaretRightOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import { rootEndpoint } from "../utils/constants";
import NewTask from "./newTask";
import ModifyCategores from "./modifyCategories";
import axios from "axios";
import moment from "moment";
import styled from "styled-components";
import "moment/locale/zh-cn";
import locale from "antd/es/date-picker/locale/zh_CN";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "../utils/useWindowDimensions";
import { render } from "@testing-library/react";
import server, { endpoints } from "../utils/server";

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const uncategorizedLabel = "<< Sin categorizar >>";

const firstMondayDate = "";
const firstSundayDate = "";

const minuteStep = 15;
const timeFormat = "HH:mm";
const backDateFormat = "YYYY-MM-DD HH:mm:ss";

const convertMinutesToTime = (minutes) => {
  if (minutes === 0) {
    return null;
  }
  return moment(Math.trunc(minutes / 60) + ":" + (minutes % 60), timeFormat);
};

const convertTimeToMinutes = (timeString) => {
  if (timeString) {
    const numbers = timeString.split(":");
    return parseInt(numbers[0]) * 60 + parseInt(numbers[1]);
  }
  return 0;
};

const filtersDefault = {
  title: "",
  opening_date_initial: firstMondayDate,
  opening_date_final: firstSundayDate,
  id_task_categories: [],
  statuses: [],
};

const CaretDownOutlinedIcon = styled(CaretDownOutlined)`
  &:hover {
    background: #d3d3d3;
    border-color: #d3d3d3;
    transition: all 0.2s ease 0s;
  }
`;
const CaretRightOutlinedIcon = styled(CaretRightOutlined)`
  &:hover {
    background: #d3d3d3;
    border-color: #d3d3d3;
    transition: all 0.2s ease 0s;
  }
`;

const TasksTable = ({
  idSelectedCycle,
  idTeam,
  idUR,
  refreshAllPendingTaskBadges,
  getTeams,
  teamName,
  id_user,
}) => {
  const { width } = useWindowDimensions();
  const IsMobile = width < 1024;
  const SmallScreen = width <= 320;

  const [tasks, setTasks] = useState([]);
  const [tasksReceived, setTasksReceived] = useState(false);
  const [page, setPage] = useState(1);
  const [totalTasks, setTotalTasks] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [totalHours, setTotalHours] = useState(0);
  const [hoursPercentage, setHoursPercentage] = useState(0);
  const [filteredTasksHours, setFilteredTasksHours] = useState(0);

  const [taskParameters, setTaskParameters] = useState({});
  const [parametersReceived, setParametersReceived] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn] = useState("");
  const history = useHistory();

  const [filtersActive, setFiltersActive] = useState(filtersDefault);

  const [taskIdToRender, setTaskIdToRender] = useState(); // Id de la tarea seleccionada para expandir su columna de descripción

  let searchInput = null;
  let dateRangeInput = null;
  let categoryMultiSelect = null;

  const getTasks = useCallback(async () => {
    setTasksReceived(false);
    let params = {
      id_cycle: idSelectedCycle,
      id_team: idTeam,
    };
    let route = endpoints.getUserTeamTasks;
    if (id_user) {
      params = {
        ...params,
        id_user,
      };
      route = endpoints.getOtherUserTeamTasks;
    }
    const body = {
      filters: filtersActive,
      per_page: perPage,
      page,
    };
    const data = await server(route.route, route.method, params, body);
    const { error_message, data: tasksInfo } = data;
    const {
      tasks,
      total_hours: totalHours,
      percentage: hoursPercentage,
      totalTasks,
      filtered_tasks_duration,
    } = tasksInfo;
    if (error_message) {
      message.error(error_message);
    } else {
      //Set the key for each task
      setTasks(tasks);
      setTotalHours(totalHours);
      setHoursPercentage(Math.round(hoursPercentage));
      setFilteredTasksHours(filtered_tasks_duration);
      setTasksReceived(true);
      setTotalTasks(totalTasks);
    }
  }, [filtersActive, page, idUR, idSelectedCycle, idTeam, perPage]);

  const getTaskParameters = useCallback(async () => {
    const params = {
      id_cycle: idSelectedCycle,
      id_team: idTeam,
    };
    const res = await server(
      endpoints.getTaskParameters.route,
      endpoints.getTaskParameters.method,
      params
    );
    const { error_message, data: dataParameters } = res;

    if (error_message) {
      message.error(error_message);
    } else {
      let newCategory = {
        id: null,
        label: uncategorizedLabel,
      };
      let newCategories = [newCategory].concat(dataParameters.categories);

      const taskParameters = {
        users: dataParameters.users,
        status_options: dataParameters.status_options,
        categories: newCategories,
        canEdit: dataParameters.canEdit,
      };

      for (let i = 0; i < taskParameters.status_options.length; i++) {
        taskParameters.status_options[i].text =
          taskParameters.status_options[i].label;
      }

      setTaskParameters(taskParameters);
      setParametersReceived(true);
    }
  }, [idUR, idSelectedCycle, idTeam]);

  useEffect(() => {
    getTasks();
  }, [getTasks]);

  useEffect(() => {
    getTaskParameters();
  }, [getTaskParameters]);

  const getStatusBadge = (statusValue) => {
    let statusLabel = "";
    if (taskParameters.status_options) {
      statusLabel = taskParameters.status_options.find(
        (s) => s.value === statusValue
      ).label;
    }
    switch (statusValue) {
      case "completed":
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            {statusLabel}
          </Tag>
        );
      default:
        return (
          <Tag icon={<ClockCircleOutlined />} color="default">
            {statusLabel}
          </Tag>
        );
    }
  };

  const categoriesColors = [
    "lime",
    "magenta",
    "gold",
    "volcano",
    "orange",
    "green",
    "cyan",
    "blue",
    "geekblue",
    "purple",
  ];

  const updateTaskRecord = async (newValue, propertyName, record) => {
    const idTask = record.id;
    let task = tasks.find((t) => t.id === idTask);
    let prevTask = task;
    const taskIndex = tasks.indexOf(task);

    task[propertyName] = newValue;
    let newTasks = [...tasks];
    //newTasks[taskIndex] = task;
    //setTasks(newTasks);

    let response = await updateTask(task);
    if (response.error_message) {
      newTasks[taskIndex] = prevTask;
      setTasks(newTasks);
    } else {
      newTasks[taskIndex] = response.newTask;
      setTasks(newTasks);
      message.success("Actualización exitosa");
    }
  };

  const getCategoryBadge = (idCategory, record, propertyName) => {
    if (!taskParameters.categories) {
      return "";
    }

    var index;
    var categoryLabel;

    const category = taskParameters.categories.find((c) => c.id === idCategory);
    if (category !== undefined) {
      index = taskParameters.categories.indexOf(category);
      categoryLabel = category.label;
    } else {
      index = null;
      categoryLabel = uncategorizedLabel;
    }

    if (record.edittingCategory) {
      return (
        <Select
          allowClear={idCategory !== 0}
          style={{ width: 188, marginBottom: 8, display: "block" }}
          placeholder="Buscar categoría"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={idCategory}
          onBlur={() => {
            const idTask = record.id;
            let task = tasks.find((t) => t.id === idTask);
            const taskIndex = tasks.indexOf(task);
            task.edittingCategory = false;
            let newTasks = [...tasks];
            newTasks[taskIndex] = task;

            setTasks(newTasks);
          }}
          onChange={async (value) => {
            let newValue = 0;
            if (value) {
              newValue = parseInt(value);
            }

            await updateTaskRecord(newValue, propertyName, record);
          }}
        >
          {taskParameters.categories.map((category) => (
            <Option key={category.id} value={category.id}>
              {category.label}
            </Option>
          ))}
        </Select>
      );
    }

    return (
      <Tag
        color={`${categoriesColors[index]}`}
        style={{ cursor: "pointer" }}
        onClick={() => {
          const idTask = record.id;
          let task = tasks.find((t) => t.id === idTask);
          const taskIndex = tasks.indexOf(task);
          task.edittingCategory = true;
          let newTasks = [...tasks];
          newTasks[taskIndex] = task;

          setTasks(newTasks);
        }}
      >
        {categoryLabel}
      </Tag>
    );
  };

  const getTitleInput = (value, record, propertyName) => {
    return (
      <TextArea
        value={value}
        placeholder="Escribe un título"
        onKeyPress={async (e) => {
          if (e.key === "Enter") {
            let newValue = e.target.value;
            await updateTaskRecord(newValue, propertyName, record);
          }
        }}
        onChange={(e) => {
          let newValue = e.target.value;
          const idTask = record.id;
          let task = tasks.find((t) => t.id === idTask);
          const taskIndex = tasks.indexOf(task);
          task.title = newValue;
          let newTasks = [...tasks];
          newTasks[taskIndex] = task;

          setTasks(newTasks);
        }}
        onBlur={async (e) => {
          let newValue = e.target.value;
          await updateTaskRecord(newValue, propertyName, record);
        }}
        autoSize={{ minRows: 1, maxRows: 6 }}
      />
    );
  };

  const getOpeningDatePicker = (value, record, propertyName) => {
    return (
      <ConfigProvider locale={locale}>
        <DatePicker
          bordered={false}
          format={`DD/MM/YYYY ${timeFormat}`}
          value={moment(value, `YYYY-MM-DD ${timeFormat}`)}
          minuteStep={minuteStep}
          showTime={{ format: timeFormat }}
          onOk={async (e, date) => {
            let newValue = moment(e).format(backDateFormat);

            await updateTaskRecord(newValue, propertyName, record);
          }}
          clearIcon={false}
        />
      </ConfigProvider>
    );
  };

  const getDurationTimePicker = (value, record, propertyName) => {
    return (
      <TimePicker
        bordered={false}
        value={convertMinutesToTime(value)}
        placeholder="Horas | minutos"
        format={timeFormat}
        minuteStep={minuteStep}
        showNow={false}
        style={{ width: 170 }}
        onChange={async (e, hour) => {
          let newValue = convertTimeToMinutes(hour);

          await updateTaskRecord(newValue, propertyName, record);
        }}
        onSelect={async (e) => {
          //let newValue = convertTimeToMinutes(hour);
          //await updateTaskRecord(newValue, propertyName, record);
        }}
      ></TimePicker>
    );
  };

  const getUsersMultiSelect = (value, record, propertyName) => {
    if (!value || !taskParameters.users) {
      return;
    }
    return (
      <Select
        mode="multiple"
        style={{ width: "100%", marginBottom: 8, display: "block" }}
        placeholder="Seleccionar encargados"
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        value={value.map(function (user) {
          return user.id;
        })}
        onChange={async (usersSelected) => {
          if (usersSelected.length === 0) {
            message.error("No se pueden dejar vacíos los encargados");
            return;
          }
          let newValue = usersSelected.map(function (userId) {
            return {
              id: parseInt(userId),
            };
          });
          await updateTaskRecord(newValue, "users", record);
        }}
      >
        {taskParameters.users.map((user) => (
          <Option key={user.id} value={parseInt(user.id)}>
            {user.first_name + " " + user.last_name}
          </Option>
        ))}
      </Select>
    );
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Buscar tareas`}
          value={selectedKeys}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? e.target.value : "")
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnDateRangeFilterProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <RangePicker
          ref={(node) => {
            dateRangeInput = node;
          }}
          value={[
            filtersActive.opening_date_initial
              ? moment(filtersActive.opening_date_initial)
              : "",
            filtersActive.opening_date_final
              ? moment(filtersActive.opening_date_final)
              : "",
          ]}
          onChange={(e, dateStrings) => {
            setSelectedKeys(dateStrings);
            confirm();
            if (dateStrings[0] === "" || dateStrings[1] === "") {
              //Remove the opening date filters from the state
              let filters = { ...filtersActive };
              filters.opening_date_initial = "";
              filters.opening_date_final = "";
              setFiltersActive(filters);

              clearFilters();
            }
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => {
              setSelectedKeys(["", ""]);

              //Remove the opening date filters from the state
              let filters = { ...filtersActive };
              filters.opening_date_initial = "";
              filters.opening_date_final = "";
              setFiltersActive(filters);

              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => (dateRangeInput.value = ["", ""]), 100);
      }
    },
  });
  const getColumnCategoryFilterProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        {taskParameters.categories && parametersReceived && (
          <Select
            mode="multiple"
            allowClear
            ref={(node) => {
              categoryMultiSelect = node;
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
            placeholder="Seleccionar categoría"
            value={filtersActive.id_task_categories.map((f) => {
              if (f === "null") {
                return null;
              } else {
                return f;
              }
            })}
            onChange={(value) => {
              for (let i = 0; i <= value.length - 1; i++) {
                if (value[i] === "null") {
                  value[i] = null;
                }
              }
              setSelectedKeys(value);
              confirm();
            }}
          >
            {taskParameters.categories.map((category) =>
              category !== undefined ? (
                <Option value={category.id} key={category.id}>
                  {category.label}
                </Option>
              ) : null
            )}
          </Select>
        )}
        <Space>
          <Button
            onClick={() => {
              setSelectedKeys([]);
              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => (categoryMultiSelect.value = []), 100);
      }
    },
  });

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const deleteTask = async ({ id: idTask }) => {
    const data = await server(
      endpoints.deleteTask.route,
      endpoints.deleteTask.method,
      { id_task: idTask }
    );
    const { error_message } = data;
    const { message: successMessage } = data;
    if (error_message) {
      message.error(error_message);
      return;
    } else {
      message.success(successMessage);
      await getTasks();
    }
  };

  const showDeleteConfirm = (record) => {
    return (
      <Popconfirm
        title={`¿Seguro que desea borrar la tarea ${record.title}?`}
        okText="Eliminar"
        okType="danger"
        cancelText="Cancelar"
        onConfirm={async (e) => {
          await deleteTask(record);
          refreshAllPendingTaskBadges();
          getTeams();
        }}
      >
        <DeleteOutlined onClick={() => history.push("#")} />
      </Popconfirm>
    );
  };

  function decimalAdjust(type, value, exp) {
    if (typeof exp === "undefined" || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
      return NaN;
    }
    value = value.toString().split("e");
    value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
    value = value.toString().split("e");
    return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
  }

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      editable: false,
      filters: taskParameters.status_options,
      fiters: <Button onReset>Limpiar</Button>,
      render: (value, record) => getStatusBadge(value, record),

      sorter: (a, b) =>
        (taskParameters.status_options.find((c) => c.value === a.status)
          ? taskParameters.status_options.find((c) => c.value === a.status)
              .label
          : ""
        ).localeCompare(
          taskParameters.status_options.find((c) => c.value === b.status)
            ? taskParameters.status_options.find((c) => c.value === b.status)
                .label
            : ""
        ),

      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      width: "10rem",
    },
    {
      title: "Título",
      dataIndex: "title",
      key: "title",
      editable: true,
      ...getColumnSearchProps("title"),
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortDirections: ["descend", "ascend"],
      ellipsis: false,
      render: (value, record) => getTitleInput(value, record, "title"),
      width: "15rem",
    },
    {
      title: "Fecha",
      dataIndex: "opening_date",
      key: "opening_date",
      editable: true,
      ...getColumnDateRangeFilterProps("opening_date"),
      sorter: (a, b) => moment(a.opening_date).diff(moment(b.opening_date)),
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      width: "13rem",
      render: (value, record) =>
        getOpeningDatePicker(value, record, "opening_date"),
    },
    {
      title: "Encargados",
      dataIndex: "users",
      key: "users",
      editable: true,
      ellipsis: true,
      render: getUsersMultiSelect,
      width: "15rem",
    },
    {
      title: "Categoría",
      dataIndex: "id_task_category",
      key: "id_task_category",
      editable: true,
      ...getColumnCategoryFilterProps("id_task_category"),
      render: (value, record) =>
        getCategoryBadge(value, record, "id_task_category"),
      sorter: (a, b) =>
        (taskParameters.categories.find((c) => c.id === a.id_task_category)
          ? taskParameters.categories.find((c) => c.id === a.id_task_category)
              .label
          : ""
        ).localeCompare(
          taskParameters.categories.find((c) => c.id === b.id_task_category)
            ? taskParameters.categories.find((c) => c.id === b.id_task_category)
                .label
            : ""
        ),
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      width: "11rem",
    },
    {
      title:
        "Duración (" +
        decimalAdjust("round", filteredTasksHours, -2) +
        " horas)",
      dataIndex: "minutes_duration",
      key: "minutes_duration",
      editable: true,
      sorter: (a, b) => a.minutes_duration - b.minutes_duration,
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      width: "13rem",
      render: (value, record) =>
        getDurationTimePicker(value, record, "minutes_duration"),
    },
    {
      title: "Acciones ",
      dataIndex: "actions",
      key: "actions",
      editable: false,
      ellipsis: true,
      width: "8rem",
      render: (value, record) => showDeleteConfirm(record),
    },
  ];

  const updateTask = async (task) => {
    const params = {
      id_cycle: idSelectedCycle,
      id_team: idTeam,
      id_task: task.id,
    };
    let taskToInsert = task;
    delete taskToInsert.id;
    const data = await server(
      endpoints.registerTask.route,
      endpoints.registerTask.method,
      params,
      taskToInsert
    );
    const { error_message, data: taskData } = data;
    const {
      updated_task: newTask,
      total_hours: totalHours,
      percentage: hoursPercentage,
    } = taskData;
    if (error_message) {
      message.error(error_message);
      return {
        error_message,
      };
    } else {
      setFilteredTasksHours(filteredTasksHours + newTask.minutes_duration / 60);
      setTotalHours(totalHours);
      setHoursPercentage(hoursPercentage);
      refreshAllPendingTaskBadges();
      getTeams();
      return {
        newTask,
      };
    }
  };

  const onApplyFilter = (page, filtersSelected) => {
    const keys = Object.keys(filtersSelected);
    let filters = { ...filtersActive };
    let filtersChanged = false;
    for (let i = 0; i < keys.length; i++) {
      let value = filtersSelected[keys[i]];
      switch (keys[i]) {
        case "title":
          if (value === null) {
            value = "";
          }
          if (filters.title !== value) {
            filtersChanged = true;
            filters.title = value;
          }
          break;
        case "status":
          if (value === null) {
            value = [];
          }
          if (filters.statuses !== value) {
            filtersChanged = true;
            filters.statuses = value;
          }
          break;
        case "id_task_category":
          if (value === null) {
            value = [];
          }
          if (filters.id_task_categories !== value) {
            filtersChanged = true;
            filters.id_task_categories = value;
          }
          break;
        case "opening_date":
          if (value === null) {
            value = ["", ""];
          }
          if (
            filters.opening_date_initial !== value[0] ||
            filters.opening_date_final !== value[1]
          ) {
            filtersChanged = true;
            filters.opening_date_initial = value[0];
            filters.opening_date_final = value[1];
          }
          break;
        default:
          return null;
      }
    }

    if (filtersChanged) {
      setFiltersActive(filters);
    }
  };

  return (
    <Col>
      <Row
        justify="space-between"
        align="middle"
        style={{
          marginLeft: IsMobile ? "5px" : "2rem",
          marginRight: IsMobile ? "10px" : "2rem",
        }}
      >
        <Row
          align="middle"
          style={{ width: IsMobile ? "70%" : SmallScreen ? "60%" : "25%" }}
          justify="space-between"
        >
          {parametersReceived && (
            <NewTask
              idUR={idUR}
              idSelectedCycle={idSelectedCycle}
              id_team={idTeam}
              refreshAllPendingTaskBadges={refreshAllPendingTaskBadges}
              getTeams={getTeams}
              getTasks={getTasks}
              taskParameters={taskParameters}
              timeFormat={timeFormat}
              minuteStep={minuteStep}
              convertTimeToMinutes={convertTimeToMinutes}
              convertMinutesToTime={convertMinutesToTime}
            />
          )}
          {taskParameters.canEdit === true && (
            <ModifyCategores
              idUR={idUR}
              idSelectedCycle={idSelectedCycle}
              id_team={idTeam}
              getTaskParameters={getTaskParameters}
              taskParameters={taskParameters}
              teamName={teamName}
            />
          )}
        </Row>
        {!tasksReceived && <Spin />}
        <Col>
          <div style={{ textAlign: "center" }}>{totalHours + " horas"}</div>
          <div style={{ textAlign: "center" }}>
            <Progress
              type="circle"
              strokeColor={{
                "0%": "#108ee9",
                "100%": "#87d068",
              }}
              percent={Math.round(hoursPercentage)}
              width={!IsMobile ? 50 : 40}
            />
          </div>
        </Col>
      </Row>

      {/*<Row justify="center" align="middle">
                <Col span={3} style={{ textAlign: "start" }}>
                    {parametersReceived &&
                        <NewTask idUR={idUR} idSelectedCycle={idSelectedCycle} id_team={idTeam}
                            refreshAllPendingTaskBadges={refreshAllPendingTaskBadges} getTeams={getTeams}
                            getTasks={getTasks} taskParameters={taskParameters} timeFormat={timeFormat} minuteStep={minuteStep}
                            convertTimeToMinutes={convertTimeToMinutes}
                            convertMinutesToTime={convertMinutesToTime}
                        />
                    }
                </Col>
                <Col span={3} style={{ textAlign: "start" }}>
                    <ModifyCategores idUR={idUR} idSelectedCycle={idSelectedCycle} id_team={idTeam}
                        getTaskParameters={getTaskParameters} taskParameters={taskParameters} teamName={teamName}
                    />
                </Col>
                <Col span={10} style={{ textAlign: "center" }}>
                    {!tasksReceived && <Spin />}
                </Col>
                <Col span={6} style={{ textAlign: "center" }}>
                    <Col>
                        <div style={{ textAlign: "center" }}>
                            {totalHours + " horas"}
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <Progress
                                type="circle"
                                strokeColor={{
                                    '0%': '#108ee9',
                                    '100%': '#87d068',
                                }}
                                percent={Math.round(hoursPercentage)}
                                width={50}
                            />
                        </div>
                    </Col>
                </Col>
                            </Row>*/}
      <Row justify="space-around" style={{ padding: "10px" }}>
        {
          <Table
            dataSource={tasks}
            columns={columns}
            bordered={true}
            scroll={{ y: 700, x: 2000 }}
            showSorterTooltip={false}
            expandable={{
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded && record.id === taskIdToRender ? ( //Ve si la fila ya esta expandida y si el id que ya habia sido seleccionado es igual a el que el usuario acaba de seleccionar
                  <CaretDownOutlinedIcon
                    onClick={(e) => {
                      onExpand(record, e);
                    }}
                  />
                ) : (
                  <CaretRightOutlinedIcon
                    onClick={(e) => {
                      if (!expanded) {
                        //Checa si expanded es false. Si lo es, actualizara su valor a true para poder expandir una fila cuando ya hay otra seleccionada, a la vez que cierra la ultima
                        onExpand(record, e);
                      }
                      setTaskIdToRender(record.id);
                    }}
                  />
                ),
              expandedRowRender: (record) => (
                <div>
                  <Row style={{ margin: "10px" }}>
                    <Col xs={21} xl={21}>
                      <Row>
                        <p>Descripción</p>
                      </Row>
                      <Row>
                        <TextArea
                          style={{ margin: 0 }}
                          value={record.description}
                          autoSize={{ minRows: 2, maxRows: 10 }}
                          placeholder="Escribe una descripción"
                          onKeyPress={async (e) => {
                            if (e.key === "Enter") {
                              let newValue = e.target.value;
                              await updateTaskRecord(
                                newValue,
                                "description",
                                record
                              );
                            }
                          }}
                          onChange={(e) => {
                            let newValue = e.target.value;
                            const idTask = record.id;
                            let task = tasks.find((t) => t.id === idTask);
                            const taskIndex = tasks.indexOf(task);
                            task.description = newValue;
                            let newTasks = [...tasks];
                            newTasks[taskIndex] = task;

                            setTasks(newTasks);
                          }}
                          onBlur={async (e) => {
                            let newValue = e.target.value;
                            await updateTaskRecord(
                              newValue,
                              "description",
                              record
                            );
                          }}
                        ></TextArea>
                      </Row>
                    </Col>
                    <Col xs={3} xl={3}>
                      <p style={{ margin: 0, textAlign: "right" }}>
                        {record.author_label}
                      </p>
                    </Col>
                  </Row>
                </div>
              ),
              rowExpandable: (record) => record.id === taskIdToRender,
            }}
            pagination={{
              onChange: (page) => setPage(page),
              total: totalTasks,
              pageSize: perPage,
              onShowSizeChange: (current, size) => setPerPage(size),
            }}
            onChange={onApplyFilter}
          />
        }
      </Row>
    </Col>
  );
};

export default TasksTable;
