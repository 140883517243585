import React, { Component } from 'react';
import { Select, Divider, Input, message, Row, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons'
const { Option } = Select;

class SelectWritable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: props.items,
      writtenName: "",
      undoEnabled: false
    };
  }

  onNameChange = event => {
    this.setState({
      writtenName: event.target.value,
    });
  };

  addItem = () => {
    const { writtenName } = this.state;
    if (writtenName === '') {
      message.error("Proporciona un nombre");
      return
    }

    const writtenItem = { id: 0 };
    writtenItem[this.props.labelProp] = writtenName;

    this.setState({
      items: [writtenItem],
      writtenName: '',
      undoEnabled: true
    });
  };

  onItemsReset = () => {
    const { items } = this.props;
    this.setState({
      items,
      undoEnabled: false
    });
  }

  onSelectItem = (e) => {
    const { items } = this.state;
    const { valueProp } = this.props;
    const itemSelected = items.filter(item => item[valueProp] === e)[0];
    this.props.onItemSelect(itemSelected);
  }

  render() {
    const { items, writtenName, undoEnabled } = this.state;
    const { canWriteNewItem, placeholder, valueProp, labelProp } = this.props
    return (
      <Select
        style={{ width: 400 }}
        placeholder={this.props.label}
        onChange={this.onSelectItem}
        allowClear={true}
        dropdownRender={menu => (
          <div>
            {menu}
            {
              canWriteNewItem
              &&
              <div>
                <Divider style={{ margin: '4px 0' }} />
                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                  <Input style={{ flex: 'auto' }} value={writtenName} onChange={this.onNameChange} placeholder={placeholder} />
                  <div
                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                    onClick={this.addItem}
                  >
                    <PlusOutlined /> Agregar equipo
                </div>
                </div>
              </div>
            }
            {
              undoEnabled &&
              <Row justify="center">
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: "#9a171f", borderColor: "#9a171f" }}
                  onClick={this.onItemsReset}
                >
                  Deshacer los cambios
                </Button>
              </Row>
            }
          </div>
        )}
      >
        {items.map(item => (
          <Option value={item[valueProp]} key={item[valueProp]}>{item[labelProp]}</Option>
        ))}
      </Select>
    );
  }
}

export default SelectWritable;