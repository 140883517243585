import React from "react";
import useWindowDimensions from "../utils/useWindowDimensions";
import TeamTablepc from "../components/teamTablePc";
import TeamTablem from "../components/teamTableMobile";
import {widthMobile} from "../utils/constants"

const TeamTable = (props) => {
    const {width} = useWindowDimensions();
    const IsMobile = width < widthMobile;
    return IsMobile ? <TeamTablem {...props} /> : <TeamTablepc {...props} />;
}
export default TeamTable;
