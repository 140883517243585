import React, { useState } from "react";
import {
  Col,
  Row,
  Slider,
  Typography,
  Input,
  Popconfirm,
  message,
  InputNumber,
  Button,
} from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  CloseOutlined,
  CheckCircleTwoTone,
  CheckCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { rootEndpoint } from "../utils/constants";
import server, { endpoints } from "../utils/server";

const EvaluationQuestions = ({
  question,
  index,
  questionTypes,
  getEvaluationQuestions,
  idUR,
  setQuestion,
}) => {
  // const [name, setName] = useState(questionMap.question);
  // const[question, setQuestion] = useState(questionMap);
  const [isUpdating, setIsUpdating] = useState();

  const [otro, setOtro] = useState([]);
  const { TextArea } = Input;

  const deleteEvaluationQuestion = async () => {
    const params = {
      id_question: question.questions[index].id,
    };
    const res = await server(
      endpoints.deleteEvaluationQuestion.route,
      endpoints.deleteEvaluationQuestion.method,
      params
    );
    const { error_message, data } = res;
    if (error_message) {
      message.error(error_message);
      return;
    } else {
      getEvaluationQuestions();
    }
  };
  const deleteOptionQuestion = async (id, indexJ) => {
    let que = question;
    let auxOp = [...que.questions[index].options];
    auxOp.splice(indexJ, 1);
    que.questions[index].options = auxOp;
    setQuestion(que);
    setOtro([...otro]);
    if (id != 0) {
      const params = {
        idUR,
        id,
      };
      const res = await server(
        endpoints.deleteOptionQueston.route,
        endpoints.deleteOptionQueston.method,
        params
      );
      const { error_message, data } = res;
      if (error_message) {
        message.error(error_message);
        return;
      } else {
        message.success(res.message);
        // getEvaluationQuestions();
      }
    }
  };
  const updateEvaluationQuestion = async () => {
    const params = {
      id_question: question.questions[index].id,
    };
    const body = {
      question: question.questions[index].question,
      id_question_type: question.questions[index].id_question_type,
      min: question.questions[index].min,
      max: question.questions[index].max,
      options: question.questions[index].options,
    };
    const res = await server(
      endpoints.updateEvaluationQuestion.route,
      endpoints.updateEvaluationQuestion.method,
      params,
      body
    );

    if (res.error_message) {
      message.error(res.error_message);
    } else {
      message.success(res.message);
      setIsUpdating(false);
      if (res.data.options) {
        let que = question;
        let optionsAux = [...que.questions[index].options];
        {
          optionsAux.map((t, index2) => {
            t.id = res.data.options[index2].id;
            t.label = res.data.options[index2].label;
          });
        }
        setQuestion(que);
      }
      // getEvaluationQuestions()
    }
  };
  const addNewOption = () => {
    const defaultOption = {
      id: 0,
      label: "Nueva opción",
    };
    let que = question;
    let optionsAux = [...que.questions[index].options];
    optionsAux.push(defaultOption);
    que.questions[index].options = optionsAux;
    setQuestion(que);
    setIsUpdating(true);
    setOtro([...otro]);
  };
  const onChangeOption = (value, indexJ) => {
    let que = question;
    let optionsAux = [...que.questions[index].options];
    optionsAux[indexJ].label = value;
    que.questions[index].options = optionsAux;
    setQuestion(que);
    setIsUpdating(true);
    setOtro([...otro]);
  };
  const onChangeName = (value) => {
    let que = question;
    que.questions[index].question = value;
    setQuestion(que);
    setIsUpdating(true);
    setOtro([...otro]);
  };
  const onChangeMin = (value) => {
    let que = question;
    if (value) {
      que.questions[index].min = value;
    } else {
      que.questions[index].min = 0;
    }
    setQuestion(que);
    setIsUpdating(true);
    setOtro([...otro]);
  };
  const onChangeMax = (value) => {
    let que = question;
    if (value) {
      que.questions[index].max = value;
    } else {
      que.questions[index].max = 0;
    }
    setQuestion(que);
    setIsUpdating(true);
    setOtro([...otro]);
  };
  const questionType = {
    range: "range",
    multiple_choice: "multiple choice",
    user_review: "user review",
    open: "open answer",
  };
  const { Paragraph } = Typography;
  return (
    <Row
      style={{
        background: "#f1f1f2",
        margin: "15px 50px",
      }}
      justify="space-between"
    >
      <div
        style={{
          background: "#1892ff",
          width: "8px",
        }}
      ></div>
      <Col
        style={{
          width: "90%",
          padding: "10px 0px",
        }}
      >
        <Row style={{ margin: "0px 0px 0px 20px" }}>
          <b>Pregunta{index + 1}: &nbsp;</b>
          <Paragraph editable={{ onChange: onChangeName }}>
            {question.questions[index].question}
          </Paragraph>
        </Row>
        

        {questionTypes.find(
          (element) => element.id === question.questions[index].id_question_type
        )?.label === questionType.range && (
          <Row style={{ marginLeft: "70px", maxWidth: "80%" }}>
            <>
              <p>Minimo: &nbsp;</p>
              <InputNumber
                style={{ width: "60px", height: "30px", margin: "0px 10px" }}
                value={question.questions[index].min}
                min={0}
                onChange={(e) => onChangeMin(e)}
              ></InputNumber>
            </>
            <Slider
              disabled
              max={question.questions[index].max}
              min={question.questions[index].min}
              style={{ width: "20%", marginLeft: "15px", marginRight: "15px" }}
            />
            <>
              <p>Maximo: &nbsp;</p>
              <InputNumber
                style={{ width: "60px", height: "30px", margin: "0px 10px" }}
                value={question.questions[index].max}
                min={0}
                onChange={(e) => onChangeMax(e)}
              ></InputNumber>
            </>
          </Row>
        )}
        {questionTypes.find(
          (element) => element.id === question.questions[index].id_question_type
        )?.label === questionType.multiple_choice && (
          <>
            <Col>
              {question.questions[index].options.map((i, index) => (
                <Row key={index} value={i.id}>
                  <Input
                    size="small"
                    style={{ margin: "5px 60px", width: "40%" }}
                    value={i.label}
                    onChange={(e) => onChangeOption(e.target.value, index)}
                  />
                  <Popconfirm
                    title="¿Seguro que desea borar la opción?"
                    okText="Si"
                    cancelText="No"
                    onConfirm={() => deleteOptionQuestion(i.id, index)}
                  >
                    <CloseOutlined style={{ marginTop: "8px" }} />
                  </Popconfirm>
                </Row>
              ))}
            </Col>
          </>
        )}
        {questionTypes.find(
          (element) => element.id === question.questions[index].id_question_type
        )?.label === questionType.user_review && (
          <Row style={{ marginLeft: "70px", maxWidth: "80%" }}>
            <b>Integrantes: &nbsp;</b>
            <br></br>
            <p>Minimo: &nbsp;</p>
            {/* <InputNumber style={{width:"60px", height:"30px", margin:"0px 10px"}} defaultValue={question.questions[index].min} min={0} onChange={(e)=>onChangeMin(e)}></InputNumber> */}
            <InputNumber
              style={{ width: "50px", height: "30px", margin: "0px 10px" }}
              value={question.questions[index].min}
              min={0}
              onChange={(e) => onChangeMin(e)}
            ></InputNumber>
            <Slider
              disabled
              max={question.questions[index].max}
              min={question.questions[index].min}
              style={{ width: "5%", marginLeft: "15px", marginRight: "15px" }}
            />
            <p>Maximo: &nbsp;</p>
            <InputNumber
              style={{ width: "50px", height: "30px", margin: "0px 10px" }}
              value={question.questions[index].max}
              min={0}
              onChange={(e) => onChangeMax(e)}
            ></InputNumber>
          </Row>
        )}
        {questionTypes.find(
          (element) => element.id === question.questions[index].id_question_type
        )?.label === questionType.open && (
          <>
            <Col>
              
                <Row key={index} >
                  <TextArea
                    showCount
                    maxLength={2000}
                    style={{ height: 10, marginBottom: 12 }}
                    //onChange={onChange}
                    disabled
                    placeholder="Aqui el usuario va a contestar"
                  />
                </Row>
              
            </Col>
          </>
        )}
      </Col>
      <Col
        style={{
          background: "#d9d9d9",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Row>
          {isUpdating ? (
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              onClick={updateEvaluationQuestion}
              style={{ margin: "10px" }}
            />
          ) : (
            <CheckCircleOutlined style={{ margin: "10px" }} />
          )}
        </Row>

        <div>
          {questionTypes.find(
            (element) =>
              element.id === question.questions[index].id_question_type
          )?.label === questionType.multiple_choice && (
            <Row>
              <PlusCircleOutlined
                onClick={addNewOption}
                style={{ margin: "10px" }}
              />
            </Row>
          )}
          <Row>
            <Popconfirm
              title="¿Seguro que desea borar la pregunta?"
              okText="Si"
              cancelText="No"
              onConfirm={deleteEvaluationQuestion}
            >
              <DeleteOutlined style={{ margin: "10px" }} />
            </Popconfirm>
          </Row>
        </div>
      </Col>
      {isUpdating && (
        <Row justify="center" style={{ background: "#F1DC9E", width: "100%" }}>
          Recuerda que al editar la pregunta se borrara las respuestas si la
          evaluación esta activa
        </Row>
      )}
    </Row>
  );
};
export default EvaluationQuestions;
