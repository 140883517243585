import React, { useState, useEffect } from "react";
import { Form, Button, Input, Modal, Row, Col } from 'antd';
import NewPassword from "./newPassword";

const RetrivePassword = ({ mail, generateCode, setShowDescriptionForgot }) => {

    const [showDescription, setShowDescription] = useState(false)
    const [code, setCode] = useState('')
    const [UPmail, setUPmail] = useState('')

    const [form] = Form.useForm();


    useEffect(() => {
    }, [mail]);

    const submitCode = () => {
        setUPmail(mail);
        generateCode(mail);
        setShowDescription(true);

    };

    const colapseModal = () => {
        setShowDescriptionForgot(false);
        setShowDescription(false)
    }



    return (
        <>
            <Button key="submit" type="primary" size={"large"} onClick={() => submitCode()}> Generar código</Button>
            <Modal
                title="¿Olvidaste tu contraseña?"
                style={{ width: "100%" }}
                visible={showDescription}
                onOk={() => { colapseModal() }}
                onCancel={() => { colapseModal() }}
                destroyOnClose={true}
                footer={null}
            >

                <Form form={form}  >

                    <Row justify="center">
                        {UPmail}
                    </Row>
                    <Row justify="space-around">

                        <Form.Item
                            name="code"
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor ingrese el código",
                                }
                            ]}
                        >
                            <Input
                                placeholder="Insertar código"
                                onChange={(e) => setCode(e.target.value)}
                                autoFocus='true'
                                value={code}
                            />
                        </Form.Item>

                        <Form.Item >
                            <Button key="submit" type="primary" size={"large"} onClick={() => { generateCode(UPmail) }}> Reenviar código </Button>
                        </Form.Item>
                    </Row>
                    <Col style={{ width: "100%" }}>
                        <NewPassword
                            UPmail={UPmail}
                            code={code}
                            colapseModal={colapseModal}
                        />
                    </Col>



                </Form>
            </Modal>
        </>
    );

}

export default RetrivePassword;