import React, { Component } from "react";
import axios from "axios";
import { rootEndpoint } from "./../utils/constants";
import FotoPerfil from '../components/fotoPerfil';
import EditInformation from "../components/editUserInformation";
import { EditOutlined } from "@ant-design/icons";
import {
  Card,
  Row,
  Col,
  Button,
  List,
  message
} from "antd";
import { fields } from '../utils/iconosFormulario';
import server, { endpoints } from "../utils/server";

function replacer(i, val) {
  if (i === "teams" || i === "profile_photo_url" || i === "image_card_url") {
    // identity operator and index in quotes
    return undefined; // attempt to remove from result
  } else {
    return val; // return unchanged
  }
}

class Forma extends Component {
  constructor(props) {
    super(props);
    let info = { ...props.data };
    this.state = {
      info: info,
      editionMode: false,
      careers: [],
      fields: [...fields]
    };
  }
  componentDidMount() {
    this.getCarrers();
  }

  getCarrers = async () => {
    const res = await server(endpoints.getCareerOptions.route, endpoints.getCareerOptions.method);
    const dataCareers = res.data;
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
    } else {
      this.setState({ careers: dataCareers })
    }
  };

  onInfoChange = (e) => {
    const { id, value } = e.target;
    let newData = { ...this.state.info };
    newData[id] = value;

    this.setState({ info: newData });
  };

  onChangeSelect = (value, field) => {
    var changedField = field.label;
    const info = { ...this.state.info };
    info[changedField] = value;
    const Index = this.state.fields.indexOf(field);
    field.value = value;
    let fields = [...this.state.fields];
    fields[Index] = field;
    this.setState({ info, fields });
  };

  //Agrega preparatoria
  insertHighschool = async (otraPrepa) => {
    const data = {
      name: otraPrepa
    }



    const res = await server(endpoints.insertHighschool.route, endpoints.insertHighschool.method, {}, data);
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
      return;
    }
    message.success("Preparatoria agregada exitosamente");
  }

  handleSubmit = async (info, highschool) => {
    const idUR = this.props.idUR


    if (highschool !== '') {
      await this.insertHighschool(highschool);
    }

    if (highschool !== '') {
      info.highschool = highschool;
    }

    let infor = JSON.stringify(info, replacer);
    const body = JSON.parse(infor);
    const res = await server(endpoints.updateUser.route, endpoints.updateUser.method, {}, body);
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
      return;
    }
    message.success(res.message);
    this.props.getPorfileInfo()
    this.editionModeHandel();
  };

  //cambio de forma editable o no
  editionModeHandel = () => {
    const { editionMode } = this.state
    if (editionMode) {
      this.setState({ editionMode: false });
      return;
    }
    this.setState({ editionMode: true });
  };

  //Obtiene el nombre de la carrera a partir del Id
  getCareerName = () => {
    var { info, careers } = this.state
    const { career_id } = info;
    for(let i = 0; i < careers.length; i++){
      if(careers[i].id === career_id){
        return careers[i].initials;
      }
    }
  }

  changeProfilePhoto = fileUrl => {
    const info = this.state.info;
    this.setState({ info: { ...info, profile_photo_url: fileUrl }});
    this.props.onProfilePhotoChanged(fileUrl)
  }

  render() {
    var { fields, info } = this.state
    const { first_name, last_name, career_id, profile_photo_url } = info;

    return (
      <Col span={24}>
        <Card style={{ borderColor: "white" }}>
          <Col span={24}>
            {this.state.editionMode
              ?
              <EditInformation
                idUR={this.props.idUR}
                originPage={"perfil"}
                user={this.state.info}
                handleSubmitPerfil={this.handleSubmit}
                handleCancelProfile={this.editionModeHandel}
              />
              :
              <Col span={24} justify="center">
                <List span={24}>
                  <List.Item >
                    <Row justify="center" align="bottom" style={{ width: "100%" }} >
                      <FotoPerfil
                        nombre={first_name}
                        apellidos={last_name}
                        carrera={career_id}
                        imagen={profile_photo_url}
                        idUR={this.props.idUR}
                        onProfilePhotoChanged={this.changeProfilePhoto}
                      />
                      <Button
                        icon={<EditOutlined />}
                        onClick={this.editionModeHandel}
                        shape="circle"
                        type="primary"
                        style={{ backgroundColor: "#9a171f", borderColor: "#9a171f", marginLeft: 50, marginBottom: 20 }}
                      ></Button>
                    </Row>
                  </List.Item>
                  {fields.filter(field => field.name !== "Rol en el equipo" && field.name !== "Horas a cumplir").map((field) =>
                  (
                    <List.Item key={field.name} align="center">
                      <Col span={4}>
                        <h4 >{field.name + ": "} </h4>
                      </Col>
                      <Col span={14} style={{ border: '2px solid #E0DFE1', paddingBottom: "2px" }} align="center">
                        <Row style={{ paddingLeft: "10px", paddingTop: "10px" }} >
                          {field.icon}
                          
                          {
                            field.label === "career_id" ?
                            
            
                              <h4 style={{ marginLeft: "10px" }}>  {this.getCareerName()}</h4>
                              :
                              <h4 style={{ marginLeft: "10px" }}>  {" " + info[field.label]}</h4>
                          }
                        </Row>
                      </Col>
                    </List.Item>
                  )
                  )}
                </List>
              </Col>
            }
          </Col>
        </Card>
      </Col>
    );
  }
}
export default Forma;