import React, { Component } from "react";
import AyCsiteLogoPath from "./../resources/AyCsite_Logo.png";
import AycLogoPath from "./../resources/AyCVU.png";
import { UserOutlined, FileImageOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Menu,
  Select,
  Row,
  Col,
  Button,
  Tooltip,
  message,
  Affix,
  Typography,
} from "antd";
import { NavLink, withRouter, Link } from "react-router-dom";
import { rootEndpoint } from "./../utils/constants";
import axios from "axios";
import server, { endpoints } from "../utils/server";
const { SubMenu } = Menu;
const { Text } = Typography;

class NavBar extends Component {
  constructor(props) {
    super(props);
    if (this.props.location.pathname.split("/")[1] === "") {
      this.props.history.push("/events");
      this.state = { current: "events" };
    } else {
      this.state = {
        current: this.props.location.pathname.split("/")[1],
      };
    }
  }


  handleClick = (e) => {
    this.setState({ current: e.key });
    if (e.key === "logout") this.props.logOut();
  };

  changeCurrentCycle = async (c) => {
    const idCycle = c.id;
    const params = {
      id_cycle: idCycle,
    };
    const data = await server(
      endpoints.changeCurrentCycle.route,
      endpoints.changeCurrentCycle.method,
      params
    );
    const { error_message } = data;
    const { message: success } = data;
    if (error_message) {
      message.error(error_message);
      return;
    }
    this.props.currentCycleChanged(idCycle);
    message.success(success);
  };
  

  render() {
    const { session, cycles, idSelectedCycle, onCycleChange } = this.props;
    const { current } = this.state;
    const selectedCycleTitle = cycles.filter((c) => c.id === idSelectedCycle)[0]
      .title;

    return (
      <Affix>
        <Col
          style={{
            background:
              "linear-gradient(to top, #f0f0f0, #ffffff,#ffffff,#ffffff)",
            width: "100%",
          }}
        >
          <Menu
            onClick={this.handleClick}
            selectedKeys={[current]}
            inlineIndent={"24"}
            mode="horizontal"
            triggerSubMenuAction={"click"}
            style={{
              background:
                "linear-gradient(to top, #f0f0f0, #ffffff,#ffffff,#ffffff)",
              width: "100%",
            }}
          >
            <Menu.Item key="events">
              <img
                src={AyCsiteLogoPath}
                alt={<FileImageOutlined />}
                width={75}
              />
              <NavLink to="/events"></NavLink>
            </Menu.Item>

            <Menu.Item
              key="myTasks"
              style={{ width: "8%", textAlign: "center", marginLeft: "2%" }}
            >
              <NavLink to="/myTasks">
                <Badge size="small" count={session.pendingTasks}>
                  Mis tareas{session.pendingTasks !== 0 ? "*" : null}
                </Badge>
              </NavLink>
            </Menu.Item>

            <Menu.Item
              key="teams"
              style={{ width: "12%", textAlign: "center" }}
            >
              <NavLink to="/teams"> Equipos y bitácoras </NavLink>
            </Menu.Item>

            <Menu.Item
              key="dashboard"
              style={{ width: "8%", textAlign: "center" }}
            >
              <NavLink to="/dashboard"> Dashboard</NavLink>
            </Menu.Item>

            <Menu.Item
              key="reports"
              style={{ width: "8%", textAlign: "center" }}
            >
              <NavLink to="/reports"> Reportes</NavLink>
            </Menu.Item>
            {session.pendingEvaluations > 0 && (
              <Menu.Item
                key="evaluaciones"
                style={{ width: "8%", textAlign: "center" }}
              >
                <NavLink to="/evaluations">
                  <Badge size="small" count={session.pendingEvaluations}>
                    Evaluaciones
                  </Badge>
                </NavLink>
              </Menu.Item>
            )}

            {session.workshopPerms ? 
            <Menu.Item
              key="workshops"
              style={{ width: "8%", textAlign: "center" }}
            >
              <NavLink to="/workshops">Talleres</NavLink>
            </Menu.Item>
            : null}

            <Menu.Item
              key="cicles"
              style={{ width: "10%", textAlign: "center" }}
              disabled={!session.teamsAndCyclesPerms}
            >
              <Select
                value={selectedCycleTitle}
                size={"small"}
                style={{ width: "90%" }}
                bordered={false}
                justify={"center"}
                disabled={!session.teamsAndCyclesPerms}
                onFocus={{ color: "cyan" }}
                dropdownRender={() => (
                  <Row
                    style={{ overflow: "auto", width: "auto", margin: "10px" }}
                  >
                    <Link
                      to="/create-cycle"
                      onClick={() => this.handleClick({ key: "create-cycle" })}
                    >
                      <Row justify={"space-between"}>Nuevo ciclo</Row>
                    </Link>
                    {session.cycles.map((c) => (
                      <Row
                        justify={"space-between"}
                        align="middle"
                        style={{ marginBottom: "5px" }}
                        key={c.id}
                      >
                        <Col
                          style={{ display: "block", width: "80px" }}
                          onClick={() => onCycleChange(c.id)}
                        >
                          <Text>{c.title}</Text>
                        </Col>
                        {session.teamsAndCyclesPerms ? (
                          <Col>
                            {c.is_current ? (
                              <Button
                                type={"primary"}
                                size={"small"}
                                shape="circle"
                                style={{
                                  backgroundColor: "#9a171f",
                                  borderColor: "#9a171f",
                                  fontSize: "5px",
                                  marginLeft: "5px",
                                }}
                              >
                                {" "}
                              </Button>
                            ) : (
                              <Tooltip
                                placement="right"
                                title={"Convertir en ciclo actual"}
                              >
                                <Button
                                  type={"primary"}
                                  size={"small"}
                                  shape="circle"
                                  style={{
                                    backgroundColor: "#ffffff",
                                    borderColor: "#9a171f",
                                    fontSize: "5px",
                                    marginLeft: "5px",
                                  }}
                                  onClick={() => this.changeCurrentCycle(c)}
                                  //onBlur={}
                                  //onFocus={}
                                >
                                  {" "}
                                </Button>
                              </Tooltip>
                            )}
                          </Col>
                        ) : null}
                      </Row>
                    ))}
                  </Row>
                )}
              />
            </Menu.Item>

            <SubMenu
              style={{ marginRight: "3%" }}
              icon={
                <Avatar
                  size={38}
                  src={session.profile_photo_url}
                  alt={UserOutlined}
                />
              }
              // title={session.full_name}
            >
              <Menu.ItemGroup title={session.full_name}>
                <Menu.Divider />
                {this.props.session.adminPerms === true ? (
                  <Menu.Item key="adminUsers">
                    <NavLink to="/adminUsers"> Permisos de usuarios </NavLink>
                  </Menu.Item>
                ) : null}

                {this.props.session.dataBasePerms === true ? (
                  <Menu.Item key="databaseTH">
                    <NavLink to="/databaseTH">Base de datos</NavLink>
                  </Menu.Item>
                ) : null}
                {this.props.session.evaluationPerms === true ? (
                  <Menu.Item key="editEvaluations">
                    <NavLink to="/editEvaluations">Editar evaluaciones</NavLink>
                  </Menu.Item>
                ) : null}
                <Menu.Divider />
                <Menu.Item key="profileConfig">
                  <NavLink to="/profileView">Mi perfil</NavLink>
                </Menu.Item>
                <Menu.Item key="changePassword">
                  <NavLink to="/changePassword">Cambiar contraseña</NavLink>
                </Menu.Item>
                <Menu.Item key="help">
                  <NavLink to="/help">Obtener ayuda</NavLink>
                </Menu.Item>
              </Menu.ItemGroup>
              <Menu.Divider />
              <Menu.Item key="logout">
                <NavLink to="/">Cerrar Sesión</NavLink>
              </Menu.Item>
            </SubMenu>
            <Menu.Item
              key="home"
              disabled="true"
              style={{ textAlign: "right" }}
            >
              <img src={AycLogoPath} alt={<FileImageOutlined />} width={130} />
            </Menu.Item>
          </Menu>
        </Col>
      </Affix>
    );
  }
}

export default withRouter(NavBar);
