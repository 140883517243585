import React , {useContext, useState, useEffect} from "react";
import './EditCategoriesModal.css';
import server, {endpoints} from "../../../utils/server";

import { DashboardContext } from '../../../views/dashboardP';
import { CheckCircleTwoTone, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { message , Popconfirm, Tooltip} from "antd";

const EditCategories = ({props}) => {
  const {show, idUR, idCycle} = props;

  const [categories, setCategories] = useState()

  const { methods } = useContext(DashboardContext);
  const { toggleEditCategorie, updateDasboardCategories} = methods;

  useEffect(()=>{
    getDasboardInfo()
  },[])
  
  
  const getDasboardInfo =async () => {
    const params = {id_cycle: idCycle}
    const res = await server(endpoints.getDasboardInfo.route, endpoints.getDasboardInfo.method, params)
    const {error_message, data} = res
    if(error_message){
      message.error(error_message)
      return;
    }
    setCategories(data.general_info.categories)
  }
  
  if (!show) {
    return <></>;
  }

  const deleteDashboardCategory = async (id, index) =>{
    if(id===0){
      let aux= [...categories];
      aux.splice(index,1);
      setCategories(aux)
      return
    }
    
    
    const params = {id_dashboard_category: id};
    const res = await server(endpoints.deleteDashboardCategory.route, endpoints.deleteDashboardCategory.method, params);
    const {error_message, data} = res;
    if (error_message !== '') {
      message.error(error_message)
      return
    }
    message.success(res.message)

    let aux= [...categories];
    aux.splice(index,1);
    setCategories(aux)
   
    updateDasboardCategories()
  }

  const editDashboardCategory = async (item, index) =>{

    const body = {id: item.id, label: item.label};
    const res = await server(endpoints.editDashboardCategory.route, endpoints.editDashboardCategory.method, {}, body);

    const { error_message, data } = res;

    if (error_message !== '') {
        message.error(error_message)
        return
    }
    message.success(res.message)

    let aux = JSON.parse(JSON.stringify(categories));
    if(item.id===0){
      aux.push(item)
    }
    aux[index].id = data.id; 
    aux[index].label = data.label; 
    setCategories(aux)
    updateDasboardCategories()
  }







  const changeLabel = (val, index) =>{
    let aux = JSON.parse(JSON.stringify(categories));
    aux[index].label = val; 
    setCategories(aux)
  };
  const createCategory = () => {
    const defaultCategory = {
        id: 0,
        label: "Nueva categoría",
    }
    editDashboardCategory(defaultCategory, categories.length)
}

  return(
    <div className="EditCategoriesModal">
        <article className="EditCategoriesModal-container">
          <header>
              <h3>Editar Categorias</h3>
          </header>
          <div className="EditCategoriesModal-categories">
            {categories?.map((t, index) => {
              return (
                <div key={index} className="EditCategoriesModal-categorie">
                  <input
                    type="text"
                    placeholder="Nombre de categoria"
                    value= {t.label}
                    onChange={(e)=>changeLabel(e.target.value, index)}
                    onBlur={()=>editDashboardCategory(t, index)}
                    />
                    <Popconfirm
                    title="¿Seguro que desea borar la categoria?"
                    okText="Si"
                    cancelText="No"
                    onConfirm={()=>deleteDashboardCategory(t.id, index)}
                    >
                      <DeleteOutlined/>
                    </Popconfirm>
                </div>
              )
            })}


          </div>
          <div className="EditCategoriesModal-addicon">
            <Tooltip title='Agregar categoria'>

              <PlusCircleOutlined className="add-icon" onClick={createCategory}/>
            </Tooltip>
          </div>

          <footer className="EditCategories-buttons">
            <button className="EditCategories-save" onClick={toggleEditCategorie}>
                Listo
            </button>

          </footer>
      </article>
    </div>
  )
}
export default EditCategories