import React from "react";
import useWindowDimensions from "../utils/useWindowDimensions";
import { widthMobile } from "../utils/constants";
import Events from "./events";

const EventsF = (props) => {
    const { width } = useWindowDimensions();
    const isMobile = width < widthMobile;
    return (
        <Events isMobile={isMobile} screenWidth={width} {...props} />
    );
};

export default EventsF;