import React, { useState, useEffect, useCallback } from "react";
import { Row, message, Badge } from 'antd';
import { Tabs, Select } from 'antd';
import TasksTableConditional from './../components/tasksTablef';
import useWindowDimensions from "../utils/useWindowDimensions";
import { widthMobile } from "../utils/constants";
import TasksTableMovile from "../components/tasksTableM";
import TasksTable from "../components/tasksTable";
import server, { endpoints } from "../utils/server";

const { Option } = Select;

const { TabPane } = Tabs;

const MyTasks = ({ idSelectedCycle, idUR, refreshAllPendingTaskBadges, session }) => {

  const [teamsByUser, setTeamsByUser] = useState([]);
  const [renderTasks, setRenderTasks] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState();
  const { width } = useWindowDimensions();
  const isMobile = width < widthMobile;

  const getTeams = useCallback(async () => {
    const res = await server(endpoints.getUserTeams.route, endpoints.getUserTeams.method, { id_cycle: idSelectedCycle });
    const dataTasks = res.data;
    const { error_message } = res;
    if (error_message) {
      message.error(error_message);
    } else {
      setTeamsByUser(dataTasks);
      setRenderTasks(true);
    }
  }, [idSelectedCycle, idUR]);

  useEffect(() => {
    server(endpoints.getUserTeams.route, endpoints.getUserTeams.method, { id_cycle: idSelectedCycle }).then((res) => {
      const dataTasks = res.data;
      const { error_message } = res;
      if (error_message) {
        message.error(error_message);
      } else {
        setTeamsByUser(dataTasks);
        setRenderTasks(true);
        setSelectedTeam(dataTasks[0]?.id_team);
      }
    })
    refreshAllPendingTaskBadges();
  }, []);

  return (
    <Row justify="center">
      {renderTasks && (isMobile ? (
        <>
          <Select onChange={(val) => setSelectedTeam(parseInt(val, 10))} value={selectedTeam} style={{ width: "10rem"}}>
            {teamsByUser.map(teamByUser => (
              <Option key={teamByUser.id_team} value={teamByUser.id_team}>
                <Badge size="small" count={teamByUser.pending_tasks} style={{ marginTop: "3px" }}>
                  {teamByUser.name_team}{teamByUser.pending_tasks ? "*" : null}
                </Badge>
              </Option>
            ))}
          </Select>
          {selectedTeam && (
            <TasksTableMovile idTeam={selectedTeam} idUR={idUR} idSelectedCycle={idSelectedCycle}
            refreshAllPendingTaskBadges={refreshAllPendingTaskBadges} getTeams={getTeams} teamName={teamsByUser.find(team => team.id_team === selectedTeam)?.name_team} />
          )}
        </>
      ) : (
        <Tabs defaultActiveKey="1" centered style={{ width: "100%" }}>
          {
            teamsByUser.map(teamByUser => (
              <TabPane key={teamByUser.id_team}
                tab={
                  <Badge size="small" count={teamByUser.pending_tasks}>
                    {teamByUser.name_team}{teamByUser.pending_tasks ? "*" : null}
                  </Badge>
                }
              >
                <TasksTable idTeam={teamByUser.id_team} idUR={idUR} idSelectedCycle={idSelectedCycle}
                  refreshAllPendingTaskBadges={refreshAllPendingTaskBadges} getTeams={getTeams} teamName={teamByUser.name_team} />
              </TabPane>
            ))
          }
        </Tabs>
      ))
      }
    </Row>
  );
}

export default MyTasks;
